import './baseInfo.scss';
import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';
import axios from 'axios';
import { AccountContext } from '../../../App';
import { useTranslation } from 'react-i18next';
import useAxios from '../../../hooks/useAxios';
import useAuth from '../../../hooks/useAuth';

/// 個人資料
/// Author : YL
function BaseInfo() {
    const { operation } = useAxios();
    const { auth, logout } = useAuth();

    const { t } = useTranslation(['baseInfo']); // i18n

    const [accountInfo, setAccountInfo] = useState(null);
    // 取得基本資料
    // const emailReg = /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/
    // const [data, setData] = useState({
    //     addrCity: '',
    //     addrCountry: '',
    //     addrProvince: '',
    //     addrStreet: '',
    //     addrZip: '',
    //     birthday: '',
    //     countryList: '',
    //     email1: '',
    //     firstLearningYear: '',
    //     firstname: '',
    //     fullname: '',
    //     gender: '',
    //     lastname: '',
    //     phone1: '',
    //     phone1CountryCode: '',
    //     photoLink: '',
    //     provinceList: '',
    //     provincialCityList: '',
    //     status: '',
    //     userID: '',
    // });
    const [userNickName, setUserNickName] = useState('');
    const [userUserId, setUserUserId] = useState('');
    const [userFamilyName, setUserFamilyName] = useState('');
    const [userFirstName, setUserFirstName] = useState('');
    const [userGender, setUserGender] = useState('');
    const [userBirthday, setUserBirthday] = useState('');
    const [userFirstLearningYear, setUserFirstLearningYear] = useState('');
    const [userPhone1CountryCode, setUserPhone1CountryCode] = useState('');
    const [userPhone1, setUserPhone1] = useState('');
    const [userEmail1, setUserEmail1] = useState('');
    const [userAddrCountryCode, setUserAddrCountryCode] = useState('');
    const [userAddrCountry, setUserAddrCountry] = useState('');
    const [userAddrProvince, setUserAddrProvince] = useState('');
    const [userAddrCity, setUserAddrCity] = useState('');
    const [userAddrStreet, setUserAddrStreet] = useState('');
    const [userAddrZip, setUserAddrZip] = useState('');
    const [avatarImg, setAvatarImg] = useState('./images/user-default.png');
    const [avatarData, setAvatarData] = useState('');
    const refAccountType = useRef('');
    const refEmailValid = useRef(false);
    const isUserIdValidRef = useRef(false);

    const [countryList, setCountryList] = useState(null);
    const [provinceList, setProvinceList] = useState(null);
    const [provincialCityList, setProvincialCityList] = useState(null);
    const navigate = useNavigate();

    const [seconds, setSeconds] = useState('Click me plz');
    const [rerun, setRerun] = useState(false);

    // Modal初始化設定
    const [modalShow, setModalShow] = useState(false);
    const [modalType, setModalType] = useState('initial');
    const [modalTitle, setModalTitle] = useState('');
    const [modalId, setModalId] = useState('');

    const [newEmail, setNewEmail] = useState('');
    const [newCountryCode, setNewCountryCode] = useState('');
    const [newPhone, setNewPhone] = useState('');
    const [veriCode, setVeriCode] = useState('');

    const [loadingUploadImage, setLoadingUploadImage] = useState(false);

    const accountContext = useContext(AccountContext);
    const { homePageIndex, setHomePageIndex, lang } = accountContext;

    // before update
    const switchFirstLearningYear = (data, year) => {
        let learningYear;
        if (year) {
            const nowYear = new Date().getYear();
            if (year < 100) {
                learningYear = nowYear - year + 1900;
                data.firstLearningYear = learningYear;
            } else if (year > 1000) {
                learningYear = nowYear + 1900 - year;
                return learningYear;
            }
        }
    };

    // userId unique check
    const checkUserId = async () => {
        const userIdReg = /^[A-Za-z0-9]{6,16}$/;
        let uniqueCheckObj = {
            accountType: refAccountType.current,
            userID: userUserId,
        };
        if (!(refAccountType.current && refAccountType.current === '2')) return;
        // console.log(refAccountType.current, refAccountType.current);
        if (!userIdReg.test(userUserId)) {
            if (userUserId === '') {
                document.getElementById('userID-info').textContent = '';
            } else {
                document.getElementById('userID-info').textContent = t('id_length_and_format');
            }
        } else {
            const { success, response, error } = await operation(
                {
                    url: '/user/checkuseraccountunique',
                    method: 'POST',
                    data: uniqueCheckObj,
                },
                'checkUserId'
            );
            if (success) {
                // console.log(response.data);
                if (response.data.status === '1') {
                    document.getElementById('userID-info').textContent = t('user_id_already_used');
                } else {
                    document.getElementById('userID-info').textContent = '';
                    isUserIdValidRef.current = true;
                }
            } else if (error) {
                console.log(error);
            }
        }
        if (document.getElementById('userID-info').textContent === '') {
            isUserIdValidRef.current = true;
        }
    };

    const checkBirthday = () => {
        const today = new Date();
        const userBday = new Date(userBirthday);
        if (userBday > today) {
            document.getElementById('birthday-info').textContent = '生日錯誤';
            return false;
        }
        document.getElementById('birthday-info').textContent = '';
        return true;
    };

    const checkFullName = (firstname, lastname) => {
        if (!firstname && !lastname) {
            document.getElementById('lastname-info').textContent = t('enter_display_name');
            return false;
        } else {
            document.getElementById('lastname-info').textContent = '';
            return true;
        }
    };

    const invalidInputAlert = () => {
        Swal.fire({
            title: t('basic_information_save_failed'),
            text: t('please_enter_correct_basic_information'),
            icon: 'warning',
        });
    };

    const getAccountInfo = async () => {
        try {
            const { success, response, error } = await operation(
                {
                    url: '/user?languageCode=ZF',
                },
                'getAffiliatedList'
            );

            if (success) {
                console.log(response);
                const data = response.data;

                if (!data?.photoLink) {
                    data.photoLink = './images/user-default.png';
                }

                setAccountInfo(data);
            } else {
                console.log('Failed to fetch affiliated list');
            }
        } catch (error) {
            console.log(error);
        }
    };

    const updateUserData = async (newUserData) => {
        try {
            const { success, response, error } = await operation(
                {
                    url: '/user',
                    method: 'PUT',
                    data: newUserData,
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Accept: '*/*',
                    },
                    contentType: false, //required
                    processData: false, // required
                    mimeType: 'multipart/form-data',
                },
                'updateUserData'
            );

            if (success) {
                if (response.data.status === '1') {
                    errorAlert();
                } else if (response.data.status === '0') {
                    updateUserInfoSuccessAlert().then(() => {
                        // 重新加載頁面
                        window.location.reload();
                    });
                }
            } else {
                console.log('Failed to fetch affiliated list');
            }
        } catch (error) {
            console.log(error);
        }
    };

    const errorAlert = () => {
        Swal.fire({
            title: t('update_failed'),
            text: t('update_failed_content'),
            icon: 'error',
        });
    };

    const updateUserInfoSuccessAlert = () => {
        return Swal.fire({
            title: t('update_success'),
            text: t('update_success_content'),
            icon: 'success',
        });
    };

    function clsx(...str) {
        return str.filter(Boolean).join(' ');
    }

    async function handleSubmit(e) {
        e.preventDefault();
        setLoadingUploadImage(true);

        if (!refAccountType.current) {
            isUserIdValidRef.current = true;
        } else {
            checkUserId();
        }

        if (!userNickName) {
            document.getElementById('userNickname-info').textContent = t('enter_display_name');
        } else document.getElementById('userNickname-info').textContent = '';

        if (!userNickName || isUserIdValidRef.current === false) return setLoadingUploadImage(false);

        //透過FormData拿值，並轉為Object
        const form = new FormData(e.target);
        // console.log('form', form);
        const data = Object.fromEntries(form.entries());

        // 每一列的 KEY 和 VALUE
        for (const [key, value] of form.entries()) {
            data[key] = value;
            // if (!value) {
            // 	delete data[key];
            // }
        }
        data['photo'] = avatarData;
        // console.log('data', data);
        switchFirstLearningYear(data, data.firstLearningYear);
        if (!checkBirthday() || !checkFullName(data.firstName, data.lastname)) {
            return invalidInputAlert();
        }

        const file = document.querySelector('#file').files[0];
        if (file) {
            const fileName = file.name.split('.');
            const photoType = fileName[fileName.length - 1].toUpperCase();
            // console.log(photoType);
            if (!isAcceptedFileType(photoType)) {
                document.getElementById('avatar-info').innerText = t('file_restrictions');
                return invalidInputAlert();
            }
        }

        const formData = new FormData();
        Object.keys(data).forEach((key) => {
            // console.log(key, data[key]);
            if (!data[key]) data[key] = '';
            formData.append(key, data[key]);
        });
        let locale = navigator.language;
        switch (locale) {
            case 'zh-TW': {
                locale = 'ZF';
                break;
            }
            case 'en': {
                locale = 'EN';
                break;
            }
            default: {
                locale = 'ZH';
                break;
            }
        }
        formData.append('languageCode', locale);

        updateUserData(formData);
        setLoadingUploadImage(false);
    }

    function showModifyModal(type, id) {
        setModalType(type);
        setModalTitle(type);
        setModalId(id);
        setModalShow(true);

        // console.log(modalType);
    }

    // 修改email帳號成功，登出倒數視窗
    let timerInterval;
    // const verifyEmailSendSuccess = () => {
    //     const swalFireSuccess = () => {
    //         Swal.fire({
    //             title: '驗證信已寄出123',
    //             html: `<h4>請到您新的email信箱收取驗證信<br/>以啟用新帳號。<br/><br/>您將在 <min></min> 分 <sec></sec> 秒後自動登出<br/>並回到登入頁面</h4><br/><br/>`,
    //             showCancelButton: true,
    //             cancelButtonText: '取消更新',
    //             icon: 'success',
    //             timer: 300500,
    //             showConfirmButton: false,
    //             allowOutsideClick: false,
    //             allowEscapeKey: false,
    //             timerProgressBar: true,
    //             didOpen: () => {
    //                 const sec = Swal.getHtmlContainer().querySelector('sec');
    //                 const min = Swal.getHtmlContainer().querySelector('min');
    //                 timerInterval = setInterval(() => {
    //                     sec.textContent = parseInt((Swal.getTimerLeft() / 1000) % 60);
    //                     min.textContent = parseInt(Swal.getTimerLeft() / 1000 / 60);
    //                 }, 100);
    //             },
    //             willClose: () => {
    //                 clearInterval(timerInterval);
    //             },
    //         }).then((result) => {
    //             if (result.dismiss === Swal.DismissReason.timer) {
    //                 logout();
    //             } else if (result.dismiss === 'cancel') {
    //                 clearInterval(timerInterval);
    //                 cancelAlert();
    //             }
    //         });
    //     };
    //     // let timeInterval2;

    //     // TODO: what is this ?
    //     // const updateAccountWaiting = async () => {
    //     //     // const API_URL = `/web/user/getupdateaccountstatus`;
    //     //     // axios.get(API_URL).then((res) => {
    //     //     //     if (res.data.status === '0') {
    //     //     //         successAlert();
    //     //     //         clearInterval(timeInterval2);
    //     //     //     }
    //     //     // });
    //     //     const { success, response, error } = await operation(
    //     //         {
    //     //             url: '/user/getupdateaccountstatus',
    //     //         },
    //     //         'updateAccountWaiting'
    //     //     );
    //     //     if (success) {
    //     //         if (response.data.status === '0') {
    //     //             successAlert();
    //     //             clearInterval(timeInterval2);
    //     //         }
    //     //     } else if (error) {
    //     //         console.log(error);
    //     //     }
    //     // };

    //     // const successAlert = () => {
    //     //     Swal.fire({
    //     //         title: '設置成功 ，請您重新登入',
    //     //         html: '<b></b>' + ' 秒後自動跳至登入頁面',
    //     //         icon: 'success',
    //     //         timer: 530000,
    //     //         timerProgressBar: true,
    //     //         didOpen: () => {
    //     //             Swal.showLoading();
    //     //             const b = Swal.getHtmlContainer().querySelector('b');
    //     //             timerInterval = setInterval(() => {
    //     //                 b.textContent = parseInt(Swal.getTimerLeft() / 1000);
    //     //             }, 100);
    //     //         },
    //     //         willClose: () => {
    //     //             clearInterval(timerInterval);
    //     //         },
    //     //     }).then((result) => {
    //     //         navigate('/login');

    //     //         if (result.dismiss === Swal.DismissReason.timer) {
    //     //             navigate('/login');
    //     //         }
    //     //     });
    //     // };

    //     const cancelAlert = () => {
    //         Swal.fire({
    //             title: '已取消更新email帳號，請您重新登入',
    //             html: '<b></b>' + ' 秒後自動跳至登入頁面',
    //             icon: 'info',
    //             timer: 5300,
    //             timerProgressBar: true,
    //             didOpen: () => {
    //                 Swal.showLoading();
    //                 const b = Swal.getHtmlContainer().querySelector('b');
    //                 timerInterval = setInterval(() => {
    //                     b.textContent = parseInt(Swal.getTimerLeft() / 1000);
    //                 }, 100);
    //             },
    //             willClose: () => {
    //                 clearInterval(timerInterval);
    //             },
    //         }).then((result) => {
    //             navigate('/login');

    //             if (result.dismiss === Swal.DismissReason.timer) {
    //                 navigate('/login');
    //             }
    //         });
    //     };
    //     //TODO:What is this?
    //     if (refEmailValid.current === true) {
    //         console.log(refEmailValid.current);
    //         setModalShow(false);
    //         // const API_URL = '/web/user/sendverificationcode';
    //         // axios
    //         //     .post(
    //         //         API_URL,
    //         //         {},
    //         //         {
    //         //             headers: {
    //         //                 'Accept-Language': lang,
    //         //             },
    //         //         }
    //         //     )
    //         //     .then((res) => {
    //         //         swalFireSuccess();
    //         //         timeInterval2 = window.setInterval(() => {
    //         //             updateAccountWaiting();
    //         //         }, 5300);
    //         //     })
    //         //     .catch((err) => console.log(err));
    //         const sendverificationcode = async () => {
    //             const { success, response, error } = await operation(
    //                 {
    //                     url: '/user/sendverificationcode',
    //                     method: 'POST',
    //                     data: {},
    //                     headers: {
    //                         'Accept-Language': lang,
    //                     },
    //                 },
    //                 'sendverificationcode'
    //             );
    //             if (success) {
    //                 swalFireSuccess();
    //                 console.log('123');
    //                 // timeInterval2 = window.setInterval(() => {
    //                 //     // updateAccountWaiting();
    //                 // }, 5300);
    //             } else if (error) {
    //                 console.log(error);
    //             }
    //         };
    //         sendverificationcode();
    //     }
    // };

    const updateSuccessAlert = () => {
        Swal.fire({
            title: t('setting_success'),
            html: t('auto_redirect_login_page', {
                timeout: '<b></b>',
            }),
            icon: 'success',
            timer: 5300,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading();
                const b = Swal.getHtmlContainer().querySelector('b');
                timerInterval = setInterval(() => {
                    b.textContent = parseInt(Swal.getTimerLeft() / 1000);
                }, 100);
            },
            willClose: () => {
                clearInterval(timerInterval);
            },
        }).then((result) => {
            logout();

            if (result.dismiss === Swal.DismissReason.timer) {
                logout();
            }
        });
    };

    // checking new phone info when updating phone account
    const checkNewPhone = async (accountType, countryCode, phoneCode) => {
        // console.log('accountType', accountType);
        // console.log('countryCode', countryCode);
        // console.log('phoneCode', phoneCode);
        const phoneCodeReg = /^\d{0,20}$/;
        let uniqueCheckObj = {
            accountType,
            countryCode,
            phoneCode,
            locale: navigator.language,
        };
        // console.log(uniqueCheckObj);
        // Phone check
        if (refAccountType.current === '1') {
            if (!countryCode || !phoneCode) {
                return (document.getElementById('phone1-info').textContent = t('enter_phone'));
            }

            if (countryCode && phoneCode) {
                if (!phoneCodeReg.test(phoneCode)) {
                    document.getElementById('phone1-info').textContent = t('enter_phone');
                } else {
                    const { success, response, error } = await operation(
                        {
                            url: '/user/sendverificationcode',
                            method: 'POST',
                            headers: {
                                'Accept-Language': lang,
                            },
                            data: uniqueCheckObj,
                            timeout: 60000,
                        },
                        'checkNewPhone',
                        1
                    );
                    if (success) {
                        if (response.data.status === '3') {
                            document.getElementById('phone1-info').textContent = t('phone_number_in_use');
                        } else if (response.data.status === '2') {
                            document.getElementById('phone1-info').textContent = t('phone_number_in_use');
                        } else {
                            document.getElementById('phone1-info').textContent = '';
                            setModalType('verification');
                        }
                    } else if (error) {
                        console.log(error);
                    }
                }
            }
        }
    };

    // checking new email when updating email account
    const checkNewEmail = async (accountType, email) => {
        // console.log('accountType', accountType);
        // console.log('email', email);
        const emailReg = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/;
        let uniqueCheckObj = {
            accountType,
            email,
            locale: navigator.language,
        };
        // console.log(uniqueCheckObj);
        if (!email) {
            document.getElementById('email-info').textContent = t('enter_email');
        } else if (!emailReg.test(email)) {
            document.getElementById('email-info').textContent = t('format_error_please_retry');
        } else if (email.length >= 40) {
            document.getElementById('email-info').textContent = t('max_character_limit_exceeded_email');
            return;
        } else {
            const { success, response, error } = await operation(
                {
                    url: '/user/sendverificationcode',
                    method: 'POST',
                    headers: {
                        'Accept-Language': lang,
                    },
                    data: uniqueCheckObj,
                    timeout: 60000,
                },
                'checkNewEmail',
                1
            );
            if (success) {
                console.log(response.data);
                if (response.data.status === '3') {
                    document.getElementById('email-info').textContent = t('email_in_use');
                } else if (response.data.status === '2') {
                    document.getElementById('email-info').textContent = t('email_in_use');
                } else {
                    // setRerun(true);
                    // setSeconds(10);
                    document.getElementById('email-info').textContent = '';
                    setModalType('verification');
                }
            } else if (error) {
                console.log(error);
            }
        }
    };

    //信箱驗證
    const handleSendVeriCode = () => {
        const obj = {
            accountType: refAccountType.current,
            countryCode: newCountryCode,
            phoneCode: newPhone,
            locale: navigator.language,
        };
        if (newEmail) obj.email = newEmail;

        try {
            const { success, response, error } = operation(
                {
                    url: `/user/sendverificationcode`,
                    method: 'POST',
                    headers: {
                        'Accept-Language': lang,
                    },
                    data: obj,
                    timeout: 60000,
                },
                'handleSendVeriCode',
                1
            );

            // 成功
            if (success) {
                console.log(response.data);
            }
            if (error) {
                console.log(error);
            }
        } catch (error) {
            console.log(error);
        }
    };

    //更新手機號碼
    const handleUpdateAccount = async (veriCode) => {
        const obj = {
            accountType: refAccountType.current,
            countryCode: newCountryCode,
            phoneCode: newPhone,
            verificationCode: veriCode,
        };
        if (newEmail) obj.email = newEmail;
        if (!veriCode) return (document.querySelector('#code-info').innerText = t('enter_verification_code'));
        const { success, response, error } = await operation(
            {
                url: '/user/account',
                method: 'PUT',
                data: obj,
            },
            'handleUpdateAccount'
        );
        if (success) {
            // console.log(response);
            if (response.data.status === '1') {
                document.querySelector('#code-info').innerText = t('verification_code_error');
            } else if (response.data.status === '0') {
                updateSuccessAlert();
            }
        } else if (error) {
            console.log(error);
        }
    };

    // Province
    const getProvinceList = async (input) => {
        if (!input) return;
        let locale = navigator.language;
        switch (locale) {
            case 'zh-TW': {
                locale = 'ZF';
                break;
            }
            case 'en': {
                locale = 'EN';
                break;
            }
            default: {
                locale = 'ZH';
                break;
            }
        }

        const { success, response, error } = await operation(
            {
                url: `/sys/provinceList/?countryCode=${input}`,
            },
            'getProvinceList'
        );
        if (success) {
            // Province
            setProvinceList(response.data.provinceList);
        } else if (error) {
            console.log(error);
        }
    };

    // City
    const getCityList = async (input) => {
        let locale = navigator.language;
        switch (locale) {
            case 'zh-TW': {
                locale = 'ZF';
                break;
            }
            case 'en': {
                locale = 'EN';
                break;
            }
            default: {
                locale = 'ZH';
                break;
            }
        }
        const { success, response, error } = await operation(
            {
                url: `/sys/provincialCityList?countryCode=${userAddrCountry}&addrProvince=${input}`,
            },
            'getCityList'
        );
        if (success) {
            // City
            console.log(response.data.provincialCityList);
            setProvincialCityList(response.data.provincialCityList);
        } else if (error) {
            console.log(error);
        }
    };

    const handleAvatar = (file) => {
        if (file) {
            // console.log(file);
            const blobURL = URL.createObjectURL(file);
            setAvatarImg(blobURL);
        }
    };

    const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
    const UPLOAD_MAX_SIZE = 2 * 1024 * 1024; // 2MB
    const ACCEPTED_FILE_TYPES = ['png', 'jpg', 'jpeg', 'gif', 'bmp', 'raw', 'tif', 'tiff', 'heic', 'heif'];

    // 檢查檔案類型是否被接受
    const isAcceptedFileType = (fileType) => ACCEPTED_FILE_TYPES.includes(fileType.toLowerCase());

    // 壓縮並轉換圖像
    const compressAndConvertImage = (file) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            const blobURL = URL.createObjectURL(file);
            img.src = blobURL;

            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                let quality = 0.7; // 初始壓縮品質

                // 設置畫布尺寸
                canvas.width = img.width;
                canvas.height = img.height;

                // 繪製圖像到畫布
                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

                // 壓縮圖像直到小於 2MB
                let mimeType = 'image/jpeg'; // 強制使用 JPEG 格式
                let compressedDataUrl = canvas.toDataURL(mimeType, quality);
                while (compressedDataUrl.length * 0.75 > UPLOAD_MAX_SIZE && quality > 0.1) {
                    quality -= 0.1;
                    compressedDataUrl = canvas.toDataURL(mimeType, quality);
                }

                // 轉換 Data URL 到 Blob
                const byteString = atob(compressedDataUrl.split(',')[1]);
                const ab = new ArrayBuffer(byteString.length);
                const ia = new Uint8Array(ab);
                for (let i = 0; i < byteString.length; i++) {
                    ia[i] = byteString.charCodeAt(i);
                }
                const compressedBlob = new Blob([ab], { type: mimeType });

                // 創建新的 File 對象
                const compressedFile = new File([compressedBlob], file.name.replace(/\.[^/.]+$/, '.jpg'), {
                    type: mimeType,
                    lastModified: file.lastModified,
                });

                // 清理 Blob URL
                URL.revokeObjectURL(blobURL);

                resolve(compressedFile);
            };

            img.onerror = (error) => {
                URL.revokeObjectURL(blobURL);
                console.error('Error loading image:', error);
                reject(error);
            };
        });
    };

    // 處理圖像載入
    const handleFileLoad = async (file, e) => {
        try {
            setLoadingUploadImage(true); // 開始上傳
            const compressedFile = await compressAndConvertImage(file);
            console.log(compressedFile);

            // 不顯示圖片，僅處理圖片
            setAvatarData(compressedFile);
            handleAvatar(compressedFile);
        } catch (error) {
            console.error('Error loading image:', error);
            alert('Error loading image: ' + error.message);
        } finally {
            setLoadingUploadImage(false); // 壓縮結束
        }
    };

    // 處理文件上傳
    const handleFileUpload = (e) => {
        const file = e.target.files.item(0);
        if (!file) return;

        if (file.size > MAX_FILE_SIZE) {
            alert('File size exceeds 5MB limit.');
            return;
        }

        const fileType = file.type.split('/')[1];
        if (!isAcceptedFileType(fileType)) {
            alert(t('file_restrictions'));
            return;
        }

        const fileReader = new FileReader();
        fileReader.addEventListener('load', (e) => handleFileLoad(file, e));
        fileReader.readAsDataURL(file);
    };

    // Phone unique info check
    useEffect(() => {
        const phoneCodeReg = /^\d{0,20}$/;

        if (!refAccountType.current) return;

        let uniqueCheckObj = {
            accountType: refAccountType.current,
            phoneCode: userPhone1,
            countryCode: userPhone1CountryCode,
        };

        // Phone check
        if (refAccountType.current === '1') {
            if (userPhone1 && userPhone1CountryCode) {
                if (!phoneCodeReg.test(userPhone1)) {
                    document.getElementById('phone1-info').textContent = t('enter_phone');
                } else {
                    try {
                        const { success, response, error } = operation(
                            {
                                url: '/user/checkuseraccountunique',
                                method: 'POST',
                                uniqueCheckObj,
                            },
                            'checkPhone'
                        );
                        if (success) {
                            if (response.data.status === '1') {
                                document.getElementById('phone1-info').textContent = t('phone_number_in_use');
                            } else {
                                document.getElementById('phone1-info').textContent = '';
                            }
                        } else if (error) {
                            console.log(error);
                        }
                    } catch (error) {
                        console.log(error);
                    }
                }
            }
        }
    }, [userPhone1, userPhone1CountryCode]);

    useEffect(() => {
        if (seconds > 0 && rerun) {
            setTimeout(() => setSeconds(seconds - 1), 1000);
        } else {
            setRerun(false);
            setSeconds(t('resend_verification_code'));
        }
    }, [rerun, seconds]);

    useEffect(() => {
        let locale = navigator.language;
        switch (locale) {
            case 'zh-TW': {
                locale = 'ZF';
                break;
            }
            case 'en': {
                locale = 'EN';
                break;
            }
            default: {
                locale = 'ZH';
                break;
            }
        }

        setAvatarImg(accountInfo?.photoLink);
        setUserNickName(accountInfo?.fullname);
        setUserUserId(accountInfo?.userID);
        setUserFamilyName(accountInfo?.lastname);
        setUserFirstName(accountInfo?.firstname);
        setUserGender(accountInfo?.gender);
        setUserBirthday(accountInfo?.birthday);
        setUserFirstLearningYear(switchFirstLearningYear(accountInfo, accountInfo?.firstLearningYear));
        setUserPhone1CountryCode(accountInfo?.phone1CountryCode);
        setUserPhone1(accountInfo?.phone1);
        setUserEmail1(accountInfo?.email1);
        setUserAddrCountryCode(accountInfo?.addrCountryCode);
        setUserAddrCountry(accountInfo?.addrCountry);
        setUserAddrProvince(accountInfo?.addrProvince);
        setUserAddrCity(accountInfo?.addrCity);
        setUserAddrStreet(accountInfo?.addrStreet);
        setUserAddrZip(accountInfo?.addrZip);
        setCountryList(accountInfo?.countryList);
        accountInfo?.provinceList && setProvinceList(accountInfo?.provinceList);
        accountInfo?.provincialCityList && setProvincialCityList(accountInfo?.provincialCityList);
    }, [accountInfo, navigator.language]);

    useEffect(() => {
        getAccountInfo();
        return () => {};
    }, []);

    return (
        <article id='container-baseInfo'>
            <section>
                <article>
                    {accountInfo && (
                        <form autoComplete='off' onSubmit={handleSubmit} className='row g-3' noValidate>
                            <section id='upper-info-section' className='articleBoxWithSideBox'>
                                <div className='containTitle'>{t('basic_information')}</div>
                                <div className='row row-re'>
                                    <div className='photo-wrapper col-md-5'>
                                        {/*<!-- 圖片 -->*/}
                                        <div
                                            className='photoInfo'
                                            style={{
                                                padding: '2rem',
                                            }}
                                        >
                                            <div
                                                className='outer_container'
                                                style={{
                                                    width: '180px',
                                                    height: '180px',
                                                    borderRadius: '50%',
                                                    overflow: 'hidden',
                                                    marginBottom: '2rem',
                                                }}
                                            >
                                                <div
                                                    className='inner_container'
                                                    style={{
                                                        margin: '0 auto',
                                                        width: '100%',
                                                        height: '100%',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    <img
                                                        style={{
                                                            height: '100%',
                                                            width: '100%',
                                                            objectFit: 'cover',
                                                        }}
                                                        src={avatarImg || ''}
                                                        id='imagePho'
                                                        alt=''
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <label
                                                    id='avatar-btn'
                                                    htmlFor='file'
                                                    className='btn btn-color-main rounded-pill p-0'
                                                >
                                                    {loadingUploadImage ? (
                                                        <span
                                                            className='spinner-border spinner-border-sm'
                                                            role='status'
                                                            aria-hidden='true'
                                                        ></span>
                                                    ) : (
                                                        <span>{t('avatar')}</span>
                                                    )}
                                                </label>
                                                <input
                                                    type='file'
                                                    id='file'
                                                    name='photo'
                                                    onChange={(e) => {
                                                        handleFileUpload(e);
                                                    }}
                                                    disabled={loadingUploadImage}
                                                    accept='.PNG, .JPG, .JPEG, .GIF, .BMP, .RAW, .TIF, .TIFF, .HEIC, .HEIF'
                                                />
                                                <p>
                                                    {t('file_size_maximum')}
                                                    {/* <br />
                                                    {t('file_restrictions')} */}
                                                </p>
                                                <div className='invalid-feedback' id='avatar-info'></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='left-side-wrapper col-md-7'>
                                        {/*<!-- 待改 -->*/}
                                        <input type='hidden' id='updateAble' value='0' />
                                        {/*<!-- 顯示名稱 -->*/}
                                        <div className='form-group'>
                                            <label className='form-label' htmlFor='fullname'>
                                                {t('user_nickname')}
                                            </label>
                                            <input
                                                className='form-control form-control-lg '
                                                id='fullname'
                                                name='fullname'
                                                placeholder=''
                                                value={userNickName || ''}
                                                onChange={(e) => {
                                                    setUserNickName(e.target.value);
                                                }}
                                            />
                                            <div className='invalid-feedback' id='userNickname-info'></div>
                                        </div>
                                        {/*<!-- 使用者ID -->*/}
                                        <div className='form-group'>
                                            <label htmlFor='userID' className='form-label'>
                                                {t('user_id')}
                                            </label>
                                            <input
                                                className='form-control form-control-lg'
                                                id='userID'
                                                name='userID'
                                                placeholder={t('specify_member_name')}
                                                value={userUserId || ''}
                                                onChange={(e) => {
                                                    setUserUserId(e.target.value);
                                                    refAccountType.current = '2';
                                                }}
                                                onBlur={checkUserId}
                                            />
                                            <div className='id-info'>{t('enter_member_name')}</div>
                                            <div className='invalid-feedback' id='userID-info'></div>
                                        </div>
                                        {/*<!-- 姓 -->*/}
                                        <div className='form-group'>
                                            <label htmlFor='lastname' className='form-label'>
                                                {t('lastname')}
                                            </label>
                                            <input
                                                className='form-control form-control-lg'
                                                id='lastname'
                                                name='lastname'
                                                placeholder=''
                                                value={userFamilyName || ''}
                                                onChange={(e) => {
                                                    document.getElementById('lastname-info').textContent = '';
                                                    setUserFamilyName(e.target.value);
                                                }}
                                            />
                                            <div className='invalid-feedback' id='lastname-info'></div>
                                        </div>
                                        {/*<!-- 名 -->*/}
                                        <div className='form-group'>
                                            <label htmlFor='firstName' className='form-label'>
                                                {t('firstname')}
                                            </label>
                                            <input
                                                className='form-control form-control-lg'
                                                id='firstName'
                                                name='firstName'
                                                placeholder=''
                                                value={userFirstName || ''}
                                                onChange={(e) => {
                                                    document.getElementById('lastname-info').textContent = '';
                                                    setUserFirstName(e.target.value);
                                                }}
                                            />
                                            <div className='invalid-feedback' id='firstname-info'></div>
                                        </div>
                                        {/*<!-- 性別 -->*/}
                                        <div className='form-group'>
                                            <label htmlFor='gender' className='form-label'>
                                                {t('gender')}
                                            </label>
                                            <select
                                                className='form-control form-control-lg'
                                                id='gender'
                                                name='gender'
                                                value={userGender || ''}
                                                onChange={(e) => setUserGender(e.target.value)}
                                            >
                                                <option value='1'>{t('male')}</option>
                                                <option value='2'>{t('female')}</option>
                                                <option value='3'>{t('not_public')}</option>
                                            </select>
                                            <div className='invalid-feedback' id='gender-info'></div>
                                        </div>
                                        {/*<!-- 生日 -->*/}
                                        <div className='form-group'>
                                            <label htmlFor='birthday' className='form-label'>
                                                {t('birthday')}
                                            </label>
                                            <input
                                                value={(userBirthday && userBirthday.split('T')[0]) || ''}
                                                type='date'
                                                className='form-control form-control-lg'
                                                id='birthday'
                                                name='birthday'
                                                placeholder=''
                                                onChange={(e) => {
                                                    document.getElementById('birthday-info').textContent = '';
                                                    setUserBirthday(e.target.value || '');
                                                }}
                                            />
                                            <div className='invalid-feedback' id='birthday-info'></div>
                                        </div>
                                        {/*<!-- 球齡 -->*/}
                                        <div className='form-group'>
                                            <label htmlFor='firstLearningYear' className='form-label'>
                                                {t('years_of_playing')}
                                            </label>
                                            <input
                                                value={userFirstLearningYear || ''}
                                                onChange={(e) => setUserFirstLearningYear(e.target.value)}
                                                type='number'
                                                className='form-control form-control-lg'
                                                id='firstLearningYear'
                                                name='firstLearningYear'
                                                placeholder=''
                                                min='0'
                                                max='150'
                                                step='1'
                                            />
                                            <div className='invalid-feedback' id='firstLearningYear-info'></div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className='articleBoxWithSideBox'>
                                <div className='row'>
                                    <div className='col-md-7 left-side-wrapper'>
                                        {/*<!-- 手機號 -->*/}
                                        <div className='row align-items-end form-group g-2 g-md-3'>
                                            <div className='col-md-4' id='countryCodeBox'>
                                                <label htmlFor='phone1CountryCode' className='form-label'>
                                                    {t('country_code')}
                                                </label>
                                                <select
                                                    className='form-control form-select-lg'
                                                    id='phone1CountryCode'
                                                    name='phone1CountryCode'
                                                    value={userPhone1CountryCode || ''}
                                                    onChange={(e) => {
                                                        newCountryCode.current = e.target.value;
                                                        refAccountType.current = '1';
                                                    }}
                                                    disabled
                                                >
                                                    <option value=''>{t('select_option')}</option>
                                                    <option value='886'>+886</option>
                                                    <option value='86'>+86</option>
                                                    <option value='852'>+852</option>
                                                </select>
                                            </div>
                                            <div className='col '>
                                                <label htmlFor='userPhone1' className='form-label'>
                                                    {t('phone_number')}
                                                </label>

                                                <input
                                                    value={userPhone1 || ''}
                                                    type='text'
                                                    className='form-control form-control-lg'
                                                    id='userPhone1'
                                                    name='userPhone1'
                                                    placeholder=''
                                                    disabled
                                                />
                                            </div>
                                            <div className='btnSBox col-3 col-xs-3 col-sm-3 col-md-3'>
                                                <button
                                                    type='button'
                                                    className='email-btn btn btn-size-s btn-color-auxiliary set-Modify form-control-lg'
                                                    data-toggle='modal'
                                                    data-target='#exampleModal'
                                                    onClick={() => {
                                                        refAccountType.current = '1';
                                                        showModifyModal('phone', 1);
                                                    }}
                                                >
                                                    {userPhone1 ? t('edit') : t('create')}
                                                </button>
                                            </div>
                                        </div>
                                        {/*<!-- 信箱 -->*/}
                                        <div className='row align-items-end form-group g-2 g-md-3'>
                                            <div className='col' id='emailBox'>
                                                <label htmlFor='userEmail1' className='form-label'>
                                                    {t('email_address')}
                                                </label>
                                                <input
                                                    value={userEmail1 || ''}
                                                    type='text'
                                                    className='form-control form-control-lg'
                                                    id='email1'
                                                    name='email1'
                                                    placeholder=''
                                                    disabled
                                                />
                                            </div>
                                            <div className='btnSBox col-3 col-xs-3 col-sm-3 col-md-3'>
                                                <button
                                                    type='button'
                                                    className='email-btn btn btn-size-s btn-color-auxiliary set-Modify form-control-lg'
                                                    data-toggle='modal'
                                                    data-target='#exampleModal'
                                                    onClick={() => {
                                                        refAccountType.current = '0';
                                                        showModifyModal('email', 0);
                                                    }}
                                                >
                                                    {userEmail1 ? t('edit') : t('create')}
                                                </button>
                                            </div>
                                            <div
                                                className={`invalid-feedback ${modalShow === true && 'd-none'}`}
                                                id='userEmail1-info'
                                            ></div>
                                        </div>
                                    </div>
                                    <div className='col-md-5'></div>
                                    {/*Modal*/}
                                    <Modal
                                        show={modalShow}
                                        onHide={() => setModalShow(false)}
                                        aria-labelledby='contained-modal-title-vcenter'
                                        centered
                                        className='modal__email'
                                    >
                                        <Modal.Header closeButton>
                                            <Modal.Title id='contained-modal-title-vcenter' className='form-label'>
                                                {modalTitle === 'phone'
                                                    ? t('set_phone_number')
                                                    : t('set_email_address')}
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className='limitBox-modal'>
                                                <form autoComplete='off' id='modal-input-form'>
                                                    {/*<!-- 資料類型 -->*/}
                                                    <input type='hidden' id='accountType' value={modalType} />
                                                    {/*<!-- 資料編號 -->*/}
                                                    <input type='hidden' id='accountId' value={modalId} />
                                                    {/*<!-- 信箱 -->*/}
                                                    {modalType === 'email' && (
                                                        <div className={clsx('form-row formPage')} id='emailForm'>
                                                            <div className='email-input-wrapper'>
                                                                <label htmlFor='email' className='form-label'>
                                                                    {t('email_address')}
                                                                </label>
                                                                <input
                                                                    type='email'
                                                                    className='form-control form-control-lg'
                                                                    id='new-email1'
                                                                    value={newEmail || ''}
                                                                    placeholder={t('enter_email')}
                                                                    onChange={(e) => {
                                                                        setNewEmail(e.target.value);
                                                                    }}
                                                                />
                                                                <div
                                                                    className={`invalid-feedback ${
                                                                        modalShow === true && 'd-2none'
                                                                    }`}
                                                                    id='email-info'
                                                                ></div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {/*<!-- 電話 -->*/}
                                                    {modalType === 'phone' && (
                                                        <div
                                                            className={`row gx-2 ${
                                                                modalType === 'phone' ? '' : 'd-none'
                                                            } phone-input-wrapper`}
                                                            id='phoneForm'
                                                            style={{
                                                                display: modalType === 'phone' && 'flex',
                                                            }}
                                                        >
                                                            <div className='col-4'>
                                                                <label
                                                                    htmlFor='phone1CountryCode'
                                                                    className='form-label'
                                                                >
                                                                    {t('phone_number')}
                                                                </label>
                                                                <select
                                                                    className='form-select'
                                                                    id='phone1CountryCode'
                                                                    value={newCountryCode || ''}
                                                                    onChange={(e) => {
                                                                        setNewCountryCode(e.target.value);
                                                                    }}
                                                                >
                                                                    <option value=''>{t('select_option')}</option>
                                                                    <option value='886'>+886</option>
                                                                    <option value='86'>+86</option>
                                                                    <option value='852'>+852</option>
                                                                </select>
                                                            </div>
                                                            <div className='col-8'>
                                                                {/* <label
                                                                    htmlFor="phone1"
                                                                    className="form-label"
                                                                >
                                                                    手機號
                                                                </label> */}

                                                                <input
                                                                    type='text'
                                                                    className='form-control form-control-lg'
                                                                    id='phone1'
                                                                    placeholder={t('phone_number')}
                                                                    value={newPhone || ''}
                                                                    onChange={(e) => {
                                                                        setNewPhone(e.target.value);
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className={`invalid-feedback `} id='phone1-info'></div>
                                                        </div>
                                                    )}
                                                    {/*<!-- 帳號驗證碼 -->*/}
                                                    {modalType === 'verification' && (
                                                        <div className={`formPage`} id='codeForm'>
                                                            <div className='form-group'>
                                                                <div className='row gx-2'>
                                                                    <div className='col-7'>
                                                                        <label htmlFor='code' className='form-label'>
                                                                            {t('verification_code')}
                                                                        </label>
                                                                        <input
                                                                            className='form-control form-control-lg'
                                                                            type='text'
                                                                            id='code'
                                                                            placeholder={t('enter_verification_code')}
                                                                            value={veriCode}
                                                                            onChange={(e) => {
                                                                                setVeriCode(e.target.value);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className='col-5 align-self-end'>
                                                                        <button
                                                                            type='button'
                                                                            disabled={rerun}
                                                                            className='btn btn-size-lg btn-color-auxiliary'
                                                                            id='resendVerifyBtn'
                                                                            onClick={(e) => {
                                                                                setRerun(true);
                                                                                setSeconds(10);
                                                                                handleSendVeriCode();
                                                                            }}
                                                                        >
                                                                            {seconds}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className={`invalid-feedback ${
                                                                        modalShow === true && 'd-n1one'
                                                                    }`}
                                                                    id='code-info'
                                                                ></div>

                                                                {/*<!-- 提示訊息 -->*/}
                                                                <div id='promptMessageBox'>
                                                                    <div className='waiting__div'>
                                                                        {refAccountType.current === '0' &&
                                                                            t('verification_code_sent_to_email')}
                                                                        {refAccountType.current === '1' &&
                                                                            t('verification_code_sent_to_phone')}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {/*<!-- 修改成功畫面 -->*/}
                                                    {modalType === 'finish' && (
                                                        <div className={`formPage text-center`} id='successForm'>
                                                            <div className='my-3'>
                                                                <i className='fas fa-check-circle'></i>
                                                                <p className='mt-4'>{t('update_success')}</p>
                                                            </div>
                                                        </div>
                                                    )}
                                                </form>
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button
                                                className={`btn btn-light`}
                                                id='cancelBtn'
                                                onClick={() => {
                                                    setNewEmail('');
                                                    setNewCountryCode('');
                                                    setNewPhone('');
                                                    setVeriCode('');
                                                    setModalShow(false);
                                                }}
                                            >
                                                {t('cancel')}
                                            </Button>
                                            {modalType === 'email' && (
                                                <Button
                                                    className={`btn btn-dark `}
                                                    id='verifyAccountBtn'
                                                    onClick={() => {
                                                        refAccountType.current = '0';

                                                        refAccountType.current === '0' &&
                                                            checkNewEmail(refAccountType.current, newEmail);

                                                        refAccountType.current === '1' &&
                                                            checkNewPhone(refAccountType.current, newCountryCode);

                                                        // verifyEmailSendSuccess();
                                                    }}
                                                >
                                                    {t('ok')}
                                                </Button>
                                            )}

                                            {modalType === 'phone' && (
                                                <Button
                                                    className={`btn btn-dark `}
                                                    id='verifyAccountBtn'
                                                    onClick={() => {
                                                        refAccountType.current = '1';

                                                        refAccountType.current === '0' &&
                                                            checkNewEmail(refAccountType.current, newEmail);

                                                        refAccountType.current === '1' &&
                                                            checkNewPhone(
                                                                refAccountType.current,
                                                                newCountryCode,
                                                                newPhone
                                                            );

                                                        // verifyEmailSendSuccess();
                                                    }}
                                                >
                                                    {t('ok')}
                                                </Button>
                                            )}
                                            {modalType === 'verification' && (
                                                <Button
                                                    className={`btn btn-dark`}
                                                    id='updateAccountBtn'
                                                    onClick={() => {
                                                        handleUpdateAccount(veriCode);
                                                    }}
                                                >
                                                    {t('ok')}
                                                </Button>
                                            )}
                                        </Modal.Footer>
                                    </Modal>
                                </div>
                            </section>
                            <section className='articleBoxWithSideBox'>
                                <div className='row'>
                                    <div className='col-md-7 left-side-wrapper'>
                                        {/*<!-- Country -->*/}
                                        <div className='form-group'>
                                            <label className='form-label' htmlFor='addrCountry'>
                                                {t('country_or_region')}
                                            </label>
                                            <select
                                                className='form-control form-control-lg'
                                                id='addrCountry'
                                                name='addrCountry'
                                                value={userAddrCountry || ''}
                                                onChange={(e) => {
                                                    setUserAddrCountry(e.target.value);
                                                    getProvinceList(e.target.value);
                                                    setUserAddrProvince('');
                                                    setUserAddrCity('');
                                                }}
                                            >
                                                <option value=''>{t('select_option')}</option>
                                                {countryList &&
                                                    countryList.map((country) => {
                                                        return (
                                                            <option key={country.code} value={country.code}>
                                                                {country.name}
                                                            </option>
                                                        );
                                                    })}
                                            </select>
                                            <div className='invalid-feedback' id='country-info'></div>
                                        </div>
                                        {/*<!-- Province -->*/}
                                        <div
                                            className={`form-group ${userAddrCountry === 'CN' ? '' : 'd-none'}`}
                                            id='provinceBox'
                                        >
                                            <label htmlFor='province' className='form-label'>
                                                {t('province')}
                                            </label>
                                            <input
                                                id='addrProvince'
                                                name='addrProvince'
                                                value={userAddrProvince || ''}
                                                list='province'
                                                className='form-control form-control-lg'
                                                onChange={(e) => {
                                                    setUserAddrProvince(e.target.value);
                                                    getCityList(e.target.value);
                                                }}
                                            />
                                            <datalist
                                                id='province'
                                                name='addrProvince'
                                                disabled={userAddrCountry !== 'CN'}
                                            >
                                                <option value=''>{t('select_option')}</option>
                                                {provinceList &&
                                                    provinceList.map((province) => {
                                                        return (
                                                            <option key={province} value={province}>
                                                                {province}
                                                            </option>
                                                        );
                                                    })}
                                            </datalist>
                                            <div className='invalid-feedback' id='province-info'></div>
                                        </div>
                                        {/*<!-- City -->*/}
                                        <div
                                            className={`form-group ${userAddrCountry === 'CN' ? '' : 'd-none'}`}
                                            id='provincialCityBox'
                                        >
                                            <label htmlFor='provincialCity' className='form-label'>
                                                {t('city')}
                                            </label>
                                            <input
                                                id='addrCity'
                                                name='addrCity'
                                                value={userAddrCity || ''}
                                                list='provincialCity'
                                                className='form-control form-control-lg'
                                                onChange={(e) => setUserAddrCity(e.target.value)}
                                            />
                                            <datalist
                                                id='provincialCity'
                                                name='addrCity'
                                                disabled={userAddrCountry !== 'CN'}
                                            >
                                                <option value=''>{t('select_option')}</option>
                                                {provincialCityList &&
                                                    provincialCityList.map((provincialCity) => {
                                                        return (
                                                            <option key={provincialCity} value={provincialCity}>
                                                                {provincialCity}
                                                            </option>
                                                        );
                                                    })}
                                            </datalist>
                                            <div className='invalid-feedback' id='provincialCity-info'></div>
                                        </div>
                                        {/*<!-- 街道 -->*/}
                                        <div className='form-group'>
                                            <label htmlFor='street' className='form-label'>
                                                {t('address')}
                                            </label>
                                            <input
                                                type='text'
                                                value={userAddrStreet || ''}
                                                onChange={(e) => setUserAddrStreet(e.target.value)}
                                                className='form-control form-control-lg'
                                                id='addrStreet'
                                                name='addrStreet'
                                                placeholder=''
                                            />
                                            <div className='invalid-feedback' id='addrStreet'></div>
                                        </div>
                                        {/*<!-- 郵遞區號 -->*/}
                                        <div className={`form-group ${userAddrCountry !== 'HK' ? '' : 'd-none'}`}>
                                            <label htmlFor='zip' className='form-label'>
                                                {t('postal_code')}
                                            </label>
                                            <input
                                                type='number'
                                                className='form-control form-control-lg'
                                                id='zip'
                                                name='addrZip'
                                                placeholder=''
                                                value={userAddrZip || ''}
                                                onChange={(e) => setUserAddrZip(e.target.value)}
                                            />
                                            <div className='invalid-feedback' id='zip-info'></div>
                                        </div>
                                    </div>
                                    <div className='col-md-5'></div>
                                </div>
                                <section className='section-footer d-flex align-items-center justify-content-center mt-5'>
                                    <button
                                        type='submit'
                                        className='btn btn-size-m btn-color-main rounded-pill'
                                        disabled={loadingUploadImage}
                                    >
                                        {loadingUploadImage ? (
                                            <span
                                                className='spinner-border spinner-border-sm'
                                                role='status'
                                                aria-hidden='true'
                                            ></span>
                                        ) : (
                                            <span>{t('ok')}</span>
                                        )}
                                    </button>
                                    {/*onClick={UpdateInfoEvent}*/}
                                </section>
                            </section>
                        </form>
                    )}
                </article>
            </section>
        </article>
    );
}

export default BaseInfo;
