import './presetDrill.scss';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useAxios from '../../../hooks/useAxios';
import DraggableTR from './DraggableTR';

const PresetDrill = () => {
    const navigate = useNavigate();
    const [drillCategoryList, setDrillCategoryList] = useState([]);
    const [drillCategoryItemList, setDrillCategoryItemList] = useState([]);
    const [presetDrillList, setPresetDrillList] = useState([]);
    const [categoryGuid, setCategoryGuid] = useState('');
    const [categoryItemGuid, setCategoryItemGuid] = useState('');
    const [presetDrillGuid, setPresetDrillGuid] = useState('');
    const dragStartPosition = useRef();
    const dragOverPosition = useRef();
    const location = useLocation();
    const { operation } = useAxios();

    let isMounted = useRef(true);

    location.state && console.log(location.state);
    //#region function

    //#region drag & drop

    // 記錄拖拉開始時的位置
    const dragStart = (position) => {
        dragStartPosition.current = position;
    };

    // 在拖拉過程中, 不斷更新位置
    const dragEnter = (position) => {
        dragOverPosition.current = position;
    };

    /* 
    拖拉結束

    variables 
    - newList: 預設球譜列表的 copy
    - content: 被拖拉的 item
    - originalIndexList: 被改變位置的 item 原本的 index
    - newDisplayOrderList: 被改變位置的 item 的新 displayOrder
    - rowStampList: 被改變位置的 item 的 rowStamp
    - guid: 球譜子型態 guid

    steps
    1. 找出 content, 並將其從 newList 中轉移位置
    2. 找出 newList 中位置已經改變的 item, 記錄其新舊位置在 originalIndexList & newDisplayOrderList 中
    3. call function: handlePresetDrillDrag
    */
    const drop = () => {
        console.log(dragStartPosition, dragOverPosition);
        console.log(presetDrillList);
        const newList = [...presetDrillList];
        const content = newList[dragStartPosition.current];
        newList.splice(dragStartPosition.current, 1);
        newList.splice(dragOverPosition.current, 0, content);

        const originalIndexList = [];
        const newDisplayOrderList = [];
        const rowStampList = [];
        const guid = newList[0].drillCategoryItemGuid;

        newList.forEach((el) => {
            // 如果新舊位置不同
            if (el.ui_order !== newList.indexOf(el) + 1) {
                // 記錄其新舊位置在 originalIndexList & newDisplayOrderList 中
                originalIndexList.push(el.displayOrder);
                newDisplayOrderList.push(newList.indexOf(el) + 1);
                // 被改變位置的 item 的 rowStamp
                rowStampList.push(el.rowStamp);
            }
            el.displayOrder = newList.indexOf(el) + 1;
        });

        // 重置 dragOverPosition
        dragOverPosition.current = null;

        // 把資料傳入 handlePresetDrillDrag
        handlePresetDrillDrag(guid, originalIndexList, newDisplayOrderList, rowStampList, newList);
    };

    /* 
    1. 把 originalIndexList, newDisplayOrderList, rowStampList 傳到後端
    2. 回傳 200 => 重新向後端取得新的預設球譜列表
    3. 以球譜型態列表的第一筆的 guid 向後端取得球譜子型態列表
    */
    const handlePresetDrillDrag = async (
        drillCategoryItemGuid,
        originalIndexList,
        newDisplayOrderList,
        rowStampList
    ) => {
        const { success, response, error } = await operation(
            {
                url: `/sysDrill/presetDrillDrag/${drillCategoryItemGuid}`,
                method: 'PUT',
                data: {
                    originalIndexList,
                    newDisplayOrderList,
                    rowStampList,
                },
            },
            'handlePresetDrillDrag'
        );

        if (success) {
            response.status === 200 && getPresetDrillList(drillCategoryItemGuid);
        }

        if (error) {
            console.log(error);
        }
    };

    //#endregion

    /* 
    1. 向後端取得球譜型態列表
    2. 預設球譜型態列表的第一筆為 active
    3. 以球譜型態列表的第一筆的 guid 向後端取得球譜子型態列表
    */
    const getCategoryList = useCallback(() => {
        const gettingCategoryList = async () => {
            const { success, response, error } = await operation(
                {
                    url: '/sysDrill/drillCategory',
                },
                'gettingCategoryList'
            );
            if (success) {
                // console.log(response.data.result);
                // 球譜型態列表
                setDrillCategoryList(response.data.result);
                // 預設球譜型態列表的第一筆為 active
                // 如果從 "修改球谱" 回來, 就會有前一次查詢的 guid, 那就是此 guid 為主
                let activeGuid;
                if (location?.state) {
                    activeGuid = location?.state?.categoryGuid;
                } else {
                    activeGuid = response.data.result[0].categoryGuid;
                }
                setCategoryGuid(activeGuid);
                // 以設球譜型態列表的第一筆之 guid 向後端取得 球譜子型態列表
                getDrillCategoryItemList(activeGuid);
            } else if (error) {
                console.log(error);
            }
        };

        gettingCategoryList();
    }, []);

    /* 
    1. 向後端取得球譜子型態列表
    2. 預設球譜子型態列表的第一筆為 active
    3. 以球譜子型態列表的第一筆的 guid 向後端取得球譜子型態列表
    */
    const getDrillCategoryItemList = async (categoryGuid) => {
        try {
            const { success, response, error } = await operation(
                {
                    url: `/sysDrill/drillCategoryItem?guid=${categoryGuid}`,
                },
                'getDrillCategoryItemList'
            );
            if (success) {
                const data = response.data.result;
                // console.log(data);
                if (isMounted.current) {
                    if (data.length) {
                        // 球譜子型態列表
                        setDrillCategoryItemList(data);
                        // 預設球譜子型態列表的第一筆為 active
                        // 如果從 "修改球谱" 回來, 就會有前一次查詢的 guid, 那就是此 guid 為主
                        let activeGuid;
                        if (location?.state) {
                            activeGuid = location?.state?.categoryItemGuid;
                        } else {
                            activeGuid = response.data.result[0].categoryItemGuid;
                        }
                        setCategoryItemGuid(activeGuid);
                        // 以球譜子型態列表的第一筆的 guid 向後端取得預設球譜主列表
                        getPresetDrillList(activeGuid);
                    } else {
                        setDrillCategoryItemList([]);
                        setPresetDrillList([]);
                    }
                }
            } else if (error) {
                console.log(error);
            }
        } catch (err) {
            console.log(err);
        }
    };

    /* 
    1. 確定有球譜子型態
    2. 向後端取得球譜子型態列表
    */
    const getPresetDrillList = async (categoryItemGuid) => {
        // 如果球譜子型態列表為空 => categoryItemGuid 就會是 empty string
        // 直接回傳預設球譜列表為空, 不用 call API
        if (!categoryItemGuid) return setPresetDrillList([]);
        const { success, response, error } = await operation(
            {
                url: `/sysDrill/presetDrill?guid=${categoryItemGuid}`,
            },
            'getPresetDrillList'
        );
        if (success) {
            const data = response.data.result;
            // console.log(data);

            data.forEach((el, index) => {
                el.ui_order = ++index;
            });
            if (isMounted.current) {
                if (data.length) {
                    setPresetDrillList(data);
                    // 預設球譜列表的第一筆為 active
                    // 如果從 "修改球谱" 回來, 就會有前一次查詢的 guid, 那就是此 guid 為主
                    let activeGuid;
                    if (location?.state) {
                        activeGuid = location?.state?.presetDrillGuid;
                    } else {
                        activeGuid = response.data.result[0].presetDrillGuid;
                    }
                    setPresetDrillGuid(activeGuid);
                } else {
                    setPresetDrillList([]);
                }
            }
        } else if (error) {
            console.log(error);
        }
    };

    //#endregion

    useEffect(() => {
        getCategoryList();
        // 使用 navigate 函数清除当前路由的 state 属性
        navigate({ state: null, replace: true });

        return () => (isMounted.current = false);
    }, [getCategoryList]);

    return (
        <section id='section-main'>
            <div id='container-presetDrill' className='container'>
                <h5 className='fw-bold mb-4'>宾乐达球谱</h5>
                <div className='presetDrillWrapper row'>
                    <div className='presetDrillLeftWrapper col-3 px-2'>
                        <div className='bg-white py-3 rounded-4'>
                            <div className='titleWrapper px-3 pb-2'>
                                <div className='d-flex align-items-center'>
                                    <h6 className='title fw-bold mb-0'>球谱型态</h6>
                                    <div className='btnWrapper ms-auto'>
                                        <button
                                            className='btn btn-primary p-0'
                                            onClick={() => navigate('/presetDrill/drillCategory/create')}
                                        >
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                className='mb-1'
                                                width='12'
                                                height='12'
                                                viewBox='0 0 12 12'
                                            >
                                                <path
                                                    id='_Color'
                                                    data-name=' ↳Color'
                                                    d='M12,6.857H6.857V12H5.143V6.857H0V5.143H5.143V0H6.857V5.143H12Z'
                                                    fill='#fff'
                                                />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {drillCategoryList?.length ? (
                                drillCategoryList?.map((el) => {
                                    return (
                                        <div
                                            key={el.categoryGuid}
                                            className={`presetDrillGroupWrapper ps-3 pe-2 py-2 ${
                                                el.categoryGuid === categoryGuid && 'active'
                                            }`}
                                            onClick={() => {
                                                setCategoryGuid(el.categoryGuid);
                                                getDrillCategoryItemList(el.categoryGuid);
                                            }}
                                        >
                                            <div className='d-flex align-items-center'>
                                                <p className='groupName mb-0'>{el.name_ZH}</p>
                                                <div className='presetDrillBtnGroupWrapper ms-auto'>
                                                    <button
                                                        className='editBtn text-primary fontSize75Rem border-0 bg-transparent'
                                                        onClick={() =>
                                                            navigate(
                                                                `/presetDrill/drillCategory/edit/${el.categoryGuid}`
                                                            )
                                                        }
                                                    >
                                                        修改
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            ) : (
                                <div>
                                    <div className='text-center my-3'>无资料</div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='presetDrillLeftWrapper col-3 px-2'>
                        <div className='bg-white py-3 rounded-4'>
                            <div className='titleWrapper px-3 pb-2'>
                                <div className='d-flex align-items-center'>
                                    <h6 className='title fw-bold mb-0'>子型态</h6>
                                    <div className='btnWrapper ms-auto'>
                                        <button
                                            className='btn btn-primary p-0'
                                            onClick={() =>
                                                navigate('/presetDrill/drillCategoryItem/create', {
                                                    state: {
                                                        categoryGuid,
                                                    },
                                                })
                                            }
                                        >
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                className='mb-1'
                                                width='12'
                                                height='12'
                                                viewBox='0 0 12 12'
                                            >
                                                <path
                                                    id='_Color'
                                                    data-name=' ↳Color'
                                                    d='M12,6.857H6.857V12H5.143V6.857H0V5.143H5.143V0H6.857V5.143H12Z'
                                                    fill='#fff'
                                                />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {drillCategoryItemList?.length ? (
                                drillCategoryItemList.map((el) => {
                                    return (
                                        <div
                                            key={el.categoryItemGuid}
                                            className={`presetDrillGroupWrapper ps-3 pe-2 py-2 ${
                                                el.categoryItemGuid === categoryItemGuid && 'active'
                                            }`}
                                            onClick={() => {
                                                setCategoryItemGuid(el.categoryItemGuid);
                                                getPresetDrillList(el.categoryItemGuid);
                                            }}
                                        >
                                            <div className='d-flex align-items-center'>
                                                <p className='groupName mb-0'>{el.name_ZH}</p>
                                                <div className='presetDrillBtnGroupWrapper ms-auto'>
                                                    <button
                                                        className='editBtn text-primary fontSize75Rem border-0 bg-transparent'
                                                        onClick={() =>
                                                            navigate(
                                                                `/presetDrill/drillCategoryItem/edit/${el.categoryItemGuid}`,
                                                                {
                                                                    state: {
                                                                        categoryGuid,
                                                                    },
                                                                }
                                                            )
                                                        }
                                                    >
                                                        修改
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            ) : (
                                <div>
                                    <div className='text-center my-3'>无资料</div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='presetDrillRightWrapper col-6 px-2'>
                        <div className='bg-white pb-3 rounded-4'>
                            <div className='tabContentWrapper_member row'>
                                <div className='col pt-3 px-5'>
                                    <table className='table table-hover' id='table-memberList'>
                                        <thead>
                                            <tr className='bg-background'>
                                                <th scope='col' width='48' className='text-grey4 border-0'>
                                                    #<span></span>
                                                </th>
                                                <th scope='col' className='text-grey4 border-0'>
                                                    球谱名称
                                                    <span></span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className='border-top-0'>
                                            {presetDrillList.length !== 0 ? (
                                                presetDrillList.map((el, index) => {
                                                    return (
                                                        <DraggableTR
                                                            activeClass={
                                                                el.presetDrillGuid === presetDrillGuid && 'active'
                                                            }
                                                            key={el.presetDrillGuid}
                                                            presetDrillGuid={el.presetDrillGuid}
                                                            categoryGuid={categoryGuid}
                                                            categoryItemGuid={categoryItemGuid}
                                                            data={el}
                                                            index={index}
                                                            dragStart={dragStart}
                                                            dragEnter={dragEnter}
                                                            drop={drop}
                                                        />
                                                    );
                                                })
                                            ) : (
                                                <tr>
                                                    <td colSpan='4'>无资料</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PresetDrill;
