import axios from 'axios';
import useAuth from './useAuth';

export default function useRefreshToken() {
    const { auth, setAuth } = useAuth();

    const refresh = async () => {
        try {
            const response = await axios.post(
                '/web/user/refreshToken',
                {},
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true,
                }
            );

            setAuth({
                accessToken: response.headers.accesstoken,
                isTriedGetAccessToken: true,
                ...response.data,
            });

            return response.data;
        } catch (error) {
            console.error('Error refreshing token:', error);

            // 轉為登出狀態
            setAuth({
                accessToken: null,
                isTriedGetAccessToken: true,
            });

            // throw error;
        }
    };

    return refresh;
}
