import { createContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const AuthContext = createContext();

export function AuthProvider({ children }) {
    const [auth, setAuth] = useState(null);
    const navigate = useNavigate();

    const login = async (loginData) => {
        // 手動設置 refreshToken cookie 過期
        document.cookie = 'refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

        const { success, response } = await axios
            .post('/web/user/ValidateLogin', loginData)
            .then((response) => {
                return { success: true, response: response };
            })
            .catch((error) => {
                return { success: false, response: error.response };
            });

        return { success, response };
    };

    const logout = (location = null) => {
        // 清除 auth 狀態
        setAuth(null);

        // 手動設置 accessToken 和 refreshToken cookie 過期
        document.cookie = 'accessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        document.cookie = 'refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

        // 清除 localStorage
        localStorage.clear();

        // 重定向到 /login 頁面
        location && navigate(`/login?${location}`);
        return navigate('/login');
    };

    return <AuthContext.Provider value={{ auth, setAuth, login, logout }}>{children}</AuthContext.Provider>;
}

export default AuthContext;
