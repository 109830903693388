import './OTAFirmwareUpdateDetail.scss';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { checkAndSetFile } from '../checkAndSetFile';
import Swal from 'sweetalert2';
import { LoadingAnimate } from '../loadingAnimate/LoadingAnimate';
import dayjs from 'dayjs';
import useAxios from '../../../hooks/useAxios';

const OTAFirmwareUpdateDetail = () => {
    const { responses, loadings, errors, operation, isAnyLoading, hasAnyError } = useAxios();
    const navigate = useNavigate();

    const [firmwareVersion, setFirmwareVersion] = useState('');
    const [audioGuid, setAudioGuid] = useState('');
    const [espFile, setEspFile] = useState(null);
    const [nxpFile, setNxpFile] = useState(null);
    const [nanoFile, setNanoFile] = useState(null);
    const [releaseNote, setReleaseNote] = useState('');
    const [developNote, setDevelopNote] = useState('');
    const [releaseTime, setReleaseTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [updateTime, setUpdateTime] = useState('');
    const [demand, setDemand] = useState('');
    const [hyperLinkESP, setHyperLinkESP] = useState('');
    const [hyperLinkNXP, setHyperLinkNXP] = useState('');
    const [hyperLinkNano, setHyperLinkNano] = useState('');
    const [model, setModel] = useState('');
    const [rowStamp, setRowStamp] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [progress, setProgress] = useState(0);

    const [otaGuid, setOtaGuid] = useState('');
    const [otaType, setOtaType] = useState('');
    const [machineName, setMachineName] = useState('');
    const [type, setType] = useState('');
    const [otaCode, setOtaCode] = useState('');
    const [firmwareGuid, setFirmwareGuid] = useState('');
    const [audioList, setAudioList] = useState('');
    const [firmwarePrerequisite, setFirmwarePrerequisite] = useState('');
    const [appPrerequisite_iOS, setAppPrerequisite_iOS] = useState('');
    const [appPrerequisite_Android, setAppPrerequisite_Android] = useState('');
    const [appPrerequisite_HarmonyOS, setAppPrerequisite_HarmonyOS] = useState('');
    const [allowCountryCode, setAllowCountryCode] = useState('');
    const [countryList, setCountryList] = useState('');
    const [firmwareVerList, setFirmwareVerList] = useState('');
    const [iosVerList, setIosVerList] = useState('');
    const [androidVerList, setAndroidVerList] = useState('');
    const [harmonyOSVerList, setHarmonyOSVerList] = useState('');

    useEffect(() => {
        setOtaGuid(window.location.search.split('?')[1].split('&')[0].split('=')[1]);
        setOtaType(window.location.search.split('?')[1].split('&')[1].split('=')[1]);
        setMachineName(decodeURI(window.location.search.split('?')[1].split('&')[2].split('=')[1]));
        setType(window.location.search.split('?')[1].split('&')[3].split('=')[1]);

        setFirmwareGuid(window.location.search.split('?')[1]?.split('&')[4].split('=')[1]);
    }, [window.location.search]);

    useEffect(() => {
        const getOtaCode = async () => {
            const { success, response, error } = await operation(
                {
                    url: `/sys/ota/${otaGuid}`,
                },
                'getOtaCode'
            );

            if (success) {
                setOtaCode(response.data.result.otaCode);
            }

            if (error) {
                console.log(error);
            }
        };

        getOtaCode();
    }, [otaGuid]);
    // console.log(otaCode);

    useEffect(() => {
        const getAudioList = async () => {
            const { success, response, error } = await operation(
                {
                    url: `/sys/audio?otaguid=${otaGuid}`,
                },
                'getOtaCode'
            );

            if (success) {
                setAudioList(response.data.result);
            }

            if (error) {
                console.log(error);
            }
        };

        otaGuid && getAudioList();
    }, [otaGuid]);
    // console.log(audioList);

    useEffect(() => {
        const getCountryList = async () => {
            const { success, response, error } = await operation(
                {
                    url: `/sys/countryList?LanguageCode=ZF`,
                },
                'getCountryList'
            );

            if (success) {
                setCountryList(response.data.countryList);
            }

            if (error) {
                console.log(error);
            }
        };

        getCountryList();
    }, []);
    // console.log(countryList);

    // FirmwareVerList
    useEffect(() => {
        const getFirmwareVerList = async () => {
            const { success, response, error } = await operation(
                {
                    url: `/sys/firmware?otaguid=${otaGuid}`,
                },
                'getFirmwareVerList'
            );

            if (success) {
                let firmwareList = [];
                response.data.result.forEach((data) => {
                    firmwareList.push(data.firmwareVersion);
                });

                setFirmwareVerList(firmwareList);
            }

            if (error) {
                console.log(error);
            }
        };

        otaGuid && getFirmwareVerList();
    }, [otaGuid]);

    // iosVerList
    useEffect(() => {
        const getIosVerList = async (osCode) => {
            const { success, response, error } = await operation(
                {
                    url: `/sys/appRepos?AppGuid=${response.data.result.appGuid}&OSCode=${osCode}`,
                },
                'getIosVerList'
            );

            if (success) {
                let appList = [];
                response.data.result.forEach((data) => {
                    appList.push(data.version);
                });

                osCode === 0 && setIosVerList(appList);
                osCode === 1 && setAndroidVerList(appList);
                osCode === 2 && setHarmonyOSVerList(appList);
            }

            if (error) {
                console.log(error);
            }
        };

        otaGuid && getIosVerList(0);
        otaGuid && getIosVerList(1);
        otaGuid && getIosVerList(2);
    }, [otaGuid]);

    let isChecked = false;
    const checkFirmwareVersionValid = async (otaGuid, version) => {
        const { success, response, error } = await operation(
            {
                url: `/sys/checkFirmwareVersionValid?OtaGuid=${otaGuid}&FirmwareVersion=${version}`,
            },
            'checkFirmwareVersionValid'
        );

        if (success) {
            if (response.data === false && version !== '') {
                Swal.fire({
                    icon: 'error',
                    title: `${version} 版本號重複，請重新輸入`,
                });
                setFirmwareVersion('');

                isChecked = false;
            } else {
                isChecked = true;
            }
        }

        if (error) {
            console.log(error);
        }
    };

    //edit getInfo
    useEffect(() => {
        if (type === 'edit')
            (async () => {
                const { success, response, error } = await operation(
                    {
                        url: `/sys/firmware/${firmwareGuid}`,
                    },
                    'getInfo'
                );

                if (success) {
                    const sysFirmware = response.data.result;
                    console.log(sysFirmware);

                    const releaseTimeFormat =
                        sysFirmware.releaseTime.toLocaleString().split(':')[0] +
                        ':' +
                        sysFirmware.releaseTime.toLocaleString().split(':')[1];
                    const endTimeFormat =
                        sysFirmware.endTime.toLocaleString().split(':')[0] +
                        ':' +
                        sysFirmware.endTime.toLocaleString().split(':')[1];

                    setFirmwareVersion(sysFirmware.firmwareVersion);
                    setAudioGuid(sysFirmware.audioGuid);
                    setAllowCountryCode(sysFirmware.allowCountryCode);
                    setHyperLinkESP(sysFirmware.hyperLink_ESP);
                    setHyperLinkNXP(sysFirmware.hyperLink_NXP);
                    setHyperLinkNano(sysFirmware.hyperLink_Nano);
                    setModel(sysFirmware.model);
                    setReleaseNote(sysFirmware.releaseNote);
                    setDevelopNote(sysFirmware.developNote);
                    setFirmwarePrerequisite(sysFirmware.firmwarePrerequisite);
                    setAppPrerequisite_iOS(sysFirmware.appPrerequisite_iOS);
                    setAppPrerequisite_Android(sysFirmware.appPrerequisite_Android);
                    setAppPrerequisite_HarmonyOS(sysFirmware.appPrerequisite_HarmonyOS);
                    setDemand(sysFirmware.demand);
                    setReleaseTime(releaseTimeFormat);
                    setEndTime(endTimeFormat);
                    setUpdateTime(sysFirmware.updateTime);
                    setRowStamp(sysFirmware.rowStamp);
                }

                if (error) {
                    console.log(error);
                }
            })();
    }, [firmwareGuid]);

    // delete
    const handleDeleteConfirmation = async (e) => {
        e.preventDefault();

        await Swal.fire({
            html: `<h4>確定要刪除?</h4>`,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: '取消',
            showConfirmButton: true,
            confirmButtonText: '確定',
            allowOutsideClick: false,
            allowEscapeKey: false,
        }).then((res) => {
            if (res.dismiss === 'cancel') {
                console.log('cancelled');
                return;
            } else {
                handleDelete();
            }
        });
    };

    const handleDelete = async () => {
        const { success, response, error } = await operation(
            {
                url: `/sys/firmware/${firmwareGuid}`,
                method: 'DELETE',
            },
            'getAppMainList'
        );

        if (success) {
            navigate(`/otafirmwareupdate?otaGuid=${otaGuid}&otatype=firmware&machineName=${machineName}`);
        }

        if (error) {
            console.log(error);
        }
    };

    const handleSubmitEdit = async (form) => {
        const { success, response, error } = await operation(
            {
                url: `/sys/firmware/${firmwareGuid}`,
                method: 'PUT',
                data: {
                    ...form,
                    otaGuid: otaGuid,
                    firmwareGuid: firmwareGuid,
                },
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                // 追蹤上傳進度
                onUploadProgress: (progressEvent) => {
                    if (progressEvent.lengthComputable) {
                        const percent = (progressEvent.loaded / progressEvent.total) * 100;
                        setProgress(Math.round(percent));
                    }
                },
            },
            'handleSubmitEdit'
        );

        if (success) {
            navigate(`/otafirmwareupdate?otaGuid=${otaGuid}&otatype=firmware&machineName=${machineName}`);
        }

        if (error) {
            setIsLoading(false);
            Swal.fire({
                icon: 'error',
                title: '網路不穩，儲存失敗',
                text: '請再次儲存。',
            });
        }
    };

    const handleSubmitCreate = async (form) => {
        const { success, response, error } = await operation(
            {
                url: `/sys/firmware`,
                method: 'POST',
                data: {
                    ...form,
                    otaGuid: otaGuid,
                    firmwareGuid: firmwareGuid,
                },
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                // 追蹤上傳進度
                onUploadProgress: (progressEvent) => {
                    if (progressEvent.lengthComputable) {
                        const percent = (progressEvent.loaded / progressEvent.total) * 100;
                        setProgress(Math.round(percent));
                    }
                },
            },
            'handleSubmitCreate'
        );

        if (success) {
            setIsLoading(false);
            navigate(`/otafirmwareupdate?otaGuid=${otaGuid}&otatype=firmware&machineName=${machineName}`);
        }

        if (error) {
            setIsLoading(false);
            Swal.fire({
                icon: 'error',
                title: '網路不穩，儲存失敗',
                text: '請再次儲存。',
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const form = new FormData(e.target);
        form.append('otaGuid', otaGuid);

        if (type === 'edit') {
            //edit
            setEspFile(null);
            setNxpFile(null);

            handleSubmitEdit(form);
        } else if (type === 'create') {
            //create
            await checkFirmwareVersionValid(otaGuid, firmwareVersion);

            if (isChecked === false) {
                // appVersion是否重複
            } else {
                setIsLoading(true);
                handleSubmitCreate(form);
            }
        }
    };

    return (
        <>
            <LoadingAnimate isLoading={isLoading} progress={progress} />

            <section id='section-main'>
                <form id='container-firmwareUpdateDetail' onSubmit={handleSubmit}>
                    <div className='d-flex'>
                        <h5 className='fw-bold mb-4'>Firmware</h5>
                        <button
                            className={`btnDelete btn btn-outline-danger px-4 ms-auto me-2 ${
                                type === 'create' && 'd-none'
                            }`}
                            onClick={(e) => {
                                handleDeleteConfirmation(e);
                            }}
                        >
                            刪除
                        </button>
                        <button
                            className={`btnCancel btn btn-outline-primary px-4 me-2 ${type === 'create' && 'ms-auto'}`}
                            onClick={(e) => {
                                e.preventDefault();
                                navigate(
                                    `/otafirmwareupdate?otaGuid=${otaGuid}&otatype=firmware&machineName=${machineName}`
                                );
                            }}
                        >
                            取消
                        </button>
                        <button className='btn btn-primary text-white px-4'>儲存</button>
                    </div>
                    <div className='manageFileWrapper bg-white p-4 rounded-4 mb-3'>
                        <input id='rowStamp' name='rowStamp' className='d-none' defaultValue={rowStamp} />
                        <h6 className='fw-bold mb-3'>{machineName}</h6>
                        <div className='row'>
                            <div className='col-10'>
                                <label htmlFor='firmwareVersion' className='fontSize75Rem mb-2'>
                                    Firmware版本号：[主版本號].[次版本號].[修訂版本號]
                                </label>
                                <input
                                    id='firmwareVersion'
                                    name='firmwareVersion'
                                    className='form-control mb-3'
                                    value={firmwareVersion || ''}
                                    onChange={(e) => setFirmwareVersion(e.target.value)}
                                    // onBlur={(e) =>
                                    // 	checkFirmwareVersionValid(e.target.value)
                                    // }
                                    required
                                    disabled={type === 'edit'}
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-5'>
                                <label htmlFor='audioGuid' className='fontSize75Rem mb-2'>
                                    Audio版本號
                                </label>
                                <select
                                    id='audioGuid'
                                    name='audioGuid'
                                    className='form-control mb-3'
                                    onChange={(e) => setAudioGuid(e.target.value)}
                                    value={audioGuid || ''}
                                >
                                    <option value=''>-- 請選擇 --</option>
                                    {audioList &&
                                        audioList.map((el) => {
                                            return (
                                                <option key={el.audioGuid} value={el.audioGuid}>
                                                    {el.audioVersion}
                                                </option>
                                            );
                                        })}
                                </select>
                            </div>
                            <div className='col-5'>
                                <label htmlFor='allowCountryCode' className='fontSize75Rem mb-2'>
                                    允许的国家/地区
                                </label>
                                <select
                                    id='allowCountryCode'
                                    name='allowCountryCode'
                                    className='form-control mb-3 '
                                    onChange={(e) => setAllowCountryCode(e.target.value)}
                                    value={allowCountryCode || ''}
                                >
                                    <option value=''>-- 請選擇 --</option>
                                    {countryList &&
                                        countryList.map((el) => {
                                            return (
                                                <option key={el.countryCode} value={el.countryCode}>
                                                    {el.name}
                                                </option>
                                            );
                                        })}
                                </select>
                            </div>
                        </div>

                        <hr className='bg-grey3 mt-2' />

                        {/* esp */}
                        <div className='row'>
                            <h6 className='fontSize75Rem text-grey4 mb-2'>ESP下载路径(URL)</h6>
                            <div className='fileBox d-flex align-items-center mb-3'>
                                <div
                                    className={`fileView d-flex bg-grey1 text-primary px-2 py-1 ms-0 rounded-3`}
                                    style={{ width: '72%' }}
                                >
                                    <span
                                        className={`fontSize75Rem ${espFile === null && type === 'create' && 'd-none'}`}
                                    >
                                        {hyperLinkESP}
                                    </span>
                                </div>
                                <label htmlFor='espFile'>
                                    <div
                                        className={`btn btn-primary text-white px-2 py-1 fontSize875Rem`}
                                        style={{
                                            width: '94px',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <svg
                                            id='icn_Create'
                                            className='me-2 mb-1'
                                            viewBox='0 0 12 12'
                                            width='12'
                                            height='12'
                                            fill='#fff'
                                        >
                                            <defs> </defs>
                                            <path
                                                className='cls-1'
                                                id='_Color'
                                                d='M 12 6.857 H 6.857 V 12 H 5.143 V 6.857 H 0 V 5.143 H 5.143 V 0 H 6.857 V 5.143 H 12 Z'
                                                data-name=' ↳Color'
                                            ></path>
                                        </svg>
                                        <span>上傳檔案</span>
                                    </div>
                                    <input
                                        type='file'
                                        className='form-control d-none'
                                        id='espFile'
                                        name='espFile'
                                        onChange={(e) => {
                                            if (checkAndSetFile(e.target, ['.bin'])) {
                                                setEspFile(e.target.files[0]);
                                                setHyperLinkESP(
                                                    `${window.location.protocol + '\\\\' + window.location.host}${
                                                        window.location.hostname === 'localhost' && '\\storage'
                                                    }\\download\\ota\\${otaCode}\\firmware\\${
                                                        firmwareVersion || '版本號'
                                                    }\\${e.target.files[0]?.name}`
                                                );
                                            }
                                        }}
                                    />
                                </label>
                            </div>
                        </div>
                        {/* nxp */}
                        <div className='row'>
                            <h6 className='fontSize75Rem text-grey4 mb-2'>NXP下载路径(URL)</h6>
                            <div className='fileBox d-flex align-items-center mb-3'>
                                <div
                                    className={`fileView d-flex bg-grey1 text-primary px-2 py-1 ms-0 rounded-3`}
                                    style={{ width: '72%' }}
                                >
                                    <span
                                        className={`fontSize75Rem ${nxpFile === null && type === 'create' && 'd-none'}`}
                                    >
                                        {hyperLinkNXP}
                                    </span>
                                </div>
                                <label htmlFor='nxpFile'>
                                    <div
                                        className={`btn btn-primary text-white px-2 py-1 fontSize875Rem`}
                                        style={{
                                            width: '94px',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <svg
                                            id='icn_Create'
                                            className='me-2 mb-1'
                                            viewBox='0 0 12 12'
                                            width='12'
                                            height='12'
                                            fill='#fff'
                                        >
                                            <defs> </defs>
                                            <path
                                                className='cls-1'
                                                id='_Color'
                                                d='M 12 6.857 H 6.857 V 12 H 5.143 V 6.857 H 0 V 5.143 H 5.143 V 0 H 6.857 V 5.143 H 12 Z'
                                                data-name=' ↳Color'
                                            ></path>
                                        </svg>
                                        <span>上傳檔案</span>
                                    </div>
                                    <input
                                        type='file'
                                        className='form-control d-none'
                                        id='nxpFile'
                                        name='nxpFile'
                                        onChange={(e) => {
                                            if (checkAndSetFile(e.target, ['.bin'])) {
                                                setNxpFile(e.target.files[0]);
                                                setHyperLinkNXP(
                                                    `${window.location.protocol + '\\\\' + window.location.host}${
                                                        window.location.hostname === 'localhost' && '\\storage'
                                                    }\\download\\ota\\${otaCode}\\firmware\\${
                                                        firmwareVersion || '版本號'
                                                    }\\${e.target.files[0]?.name}`
                                                );
                                            }
                                        }}
                                    />
                                </label>
                            </div>
                        </div>
                        {/* nano */}
                        <div className='row'>
                            <h6 className='fontSize75Rem text-grey4 mb-2'>Nano下载路径(URL)</h6>
                            <div className='fileBox d-flex align-items-center mb-3'>
                                <div
                                    className={`fileView d-flex bg-grey1 text-primary px-2 py-1 ms-0 rounded-3`}
                                    style={{ width: '72%' }}
                                >
                                    <span
                                        className={`fontSize75Rem ${
                                            nanoFile === null && type === 'create' && 'd-none'
                                        }`}
                                    >
                                        {hyperLinkNano}
                                    </span>
                                </div>
                                <label htmlFor='nanoFile'>
                                    <div
                                        className={`btn btn-primary text-white px-2 py-1 fontSize875Rem`}
                                        style={{
                                            width: '94px',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <svg
                                            id='icn_Create'
                                            className='me-2 mb-1'
                                            viewBox='0 0 12 12'
                                            width='12'
                                            height='12'
                                            fill='#fff'
                                        >
                                            <defs> </defs>
                                            <path
                                                className='cls-1'
                                                id='_Color'
                                                d='M 12 6.857 H 6.857 V 12 H 5.143 V 6.857 H 0 V 5.143 H 5.143 V 0 H 6.857 V 5.143 H 12 Z'
                                                data-name=' ↳Color'
                                            ></path>
                                        </svg>
                                        <span>上傳檔案</span>
                                    </div>
                                    <input
                                        type='file'
                                        className='form-control d-none'
                                        id='nanoFile'
                                        name='nanoFile'
                                        onChange={(e) => {
                                            if (checkAndSetFile(e.target, ['.bin'])) {
                                                setNanoFile(e.target.files[0]);
                                                setHyperLinkNano(
                                                    `${window.location.protocol + '\\\\' + window.location.host}${
                                                        window.location.hostname === 'localhost' && '\\storage'
                                                    }\\download\\ota\\${otaCode}\\firmware\\${
                                                        firmwareVersion || '版本號'
                                                    }\\${e.target.files[0]?.name}`
                                                );
                                            }
                                        }}
                                    />
                                </label>
                            </div>
                        </div>

                        <div className='row mb-4'>
                            <div className='col-10'>
                                <label htmlFor='model' className='fontSize75Rem mb-2'>
                                    適用機型(逗號分開)
                                </label>
                                <textarea
                                    type='text'
                                    id='model'
                                    name='model'
                                    className='form-control'
                                    value={model || ''}
                                    onChange={(e) => setModel(e.target.value)}
                                    required
                                />
                            </div>
                        </div>

                        <hr className='bg-grey3 mt-2' />

                        <div className='row'>
                            <div className='col-5'>
                                <label htmlFor='releaseNote' className='fontSize75Rem text-grey4 mb-2'>
                                    版本註記
                                </label>
                                <textarea
                                    type='text'
                                    id='releaseNote'
                                    name='releaseNote'
                                    className='form-control mb-1'
                                    rows='3'
                                    value={releaseNote || ''}
                                    onChange={(e) => setReleaseNote(e.target.value)}
                                />
                            </div>
                            <div className='col-5'>
                                <label htmlFor='developNote' className='fontSize75Rem text-grey4 mb-2'>
                                    開發人員註記
                                </label>
                                <textarea
                                    type='text'
                                    id='developNote'
                                    name='developNote'
                                    className='form-control'
                                    rows='3'
                                    value={developNote || ''}
                                    onChange={(e) => setDevelopNote(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>

                    {/* 更新前的前置条件 */}
                    <div className='infoWrapper bg-white p-4 rounded-4'>
                        <div className='infoTop d-flex align-items-center mb-3 '>
                            <h6 className='fw-bold mb-0'>更新前的前置条件</h6>
                        </div>
                        <div className='infoBottom row'>
                            <div className='col-5'>
                                <label htmlFor='firmwarePrerequisite' className='fontSize75Rem mb-2'>
                                    固件的最低版本要求
                                </label>
                                <select
                                    className='form-control mb-3'
                                    name='firmwarePrerequisite'
                                    id='firmwarePrerequisite'
                                    onChange={(e) => setFirmwarePrerequisite(e.target.value)}
                                    value={firmwarePrerequisite || ''}
                                >
                                    <option value={''} disabled>
                                        -- 請選擇 --
                                    </option>
                                    {firmwareVerList &&
                                        firmwareVerList.map((el) => {
                                            return (
                                                <option key={el} value={el}>
                                                    {el}
                                                </option>
                                            );
                                        })}
                                </select>

                                <label htmlFor='appPrerequisite_iOS' className='fontSize75Rem mb-2'>
                                    iOS app的最低版本要求
                                </label>
                                <select
                                    className='form-control mb-3'
                                    name='appPrerequisite_iOS'
                                    id='appPrerequisite_iOS'
                                    onChange={(e) => setAppPrerequisite_iOS(e.target.value)}
                                    value={appPrerequisite_iOS || ''}
                                    required
                                >
                                    <option value={''} disabled>
                                        -- 請選擇 --
                                    </option>
                                    {iosVerList &&
                                        iosVerList.map((el) => {
                                            return (
                                                <option key={el} value={el}>
                                                    {el}
                                                </option>
                                            );
                                        })}
                                </select>

                                <label htmlFor='appPrerequisite_Android' className='fontSize75Rem mb-2'>
                                    Android app的最低版本要求
                                </label>
                                <select
                                    className='form-control mb-3'
                                    name='appPrerequisite_Android'
                                    id='appPrerequisite_Android'
                                    onChange={(e) => setAppPrerequisite_Android(e.target.value)}
                                    value={appPrerequisite_Android || ''}
                                    required
                                >
                                    <option value={''} disabled>
                                        -- 請選擇 --
                                    </option>
                                    {androidVerList &&
                                        androidVerList.map((el) => {
                                            return (
                                                <option key={el} value={el}>
                                                    {el}
                                                </option>
                                            );
                                        })}
                                </select>
                            </div>
                            <div className='col-5'>
                                <label htmlFor='appPrerequisite_HarmonyOS' className='fontSize75Rem mb-2'>
                                    HarmonyOS app的最低版本要求
                                </label>
                                <select
                                    className='form-control mb-3'
                                    name='appPrerequisite_HarmonyOS'
                                    id='appPrerequisite_HarmonyOS'
                                    onChange={(e) => setAppPrerequisite_HarmonyOS(e.target.value)}
                                    value={appPrerequisite_HarmonyOS || ''}
                                    required
                                >
                                    <option value={''} disabled>
                                        -- 請選擇 --
                                    </option>
                                    {harmonyOSVerList &&
                                        harmonyOSVerList.map((el) => {
                                            return (
                                                <option key={el} value={el}>
                                                    {el}
                                                </option>
                                            );
                                        })}
                                </select>
                            </div>
                        </div>
                    </div>

                    {/* 版本資訊 */}
                    <div className='infoWrapper bg-white p-4 rounded-4'>
                        <div className='infoTop d-flex align-items-center mb-3 '>
                            <h6 className='fw-bold mb-0'>版本資訊</h6>
                        </div>
                        <div className='infoBottom row'>
                            <div className='col-5'>
                                <label htmlFor='demand' className='fontSize75Rem mb-2'>
                                    更新要求
                                </label>
                                <select
                                    className='form-control mb-3'
                                    name='demand'
                                    id='demand'
                                    onChange={(e) => setDemand(e.target.value)}
                                    value={demand || ''}
                                    required
                                >
                                    <option value='' disabled>
                                        -- 請選擇 --
                                    </option>
                                    <option value='0'>關閉</option>
                                    <option value='1'>選擇性更新</option>
                                    <option value='2'>一定要更新</option>
                                </select>
                                <label htmlFor='updateTime' className='fontSize75Rem mb-2'>
                                    创建/修改时间
                                </label>
                                <input
                                    type='datetime-local'
                                    id='updateTime'
                                    name='updateTime'
                                    className='form-control mb-3'
                                    value={dayjs(updateTime || new Date()).format('YYYY-MM-DD HH:mm:ss')}
                                    readOnly
                                />
                            </div>
                            <div className='col-5'>
                                <label htmlFor='releaseTime' className='fontSize75Rem mb-2'>
                                    发布时间
                                </label>
                                <input
                                    type='datetime-local'
                                    id='releaseTime'
                                    name='releaseTime'
                                    className='form-control mb-3'
                                    value={releaseTime || ''}
                                    onChange={(e) => setReleaseTime(e.target.value)}
                                    required
                                />
                                <label htmlFor='endTime' className='fontSize75Rem mb-2'>
                                    结束时间
                                </label>
                                <input
                                    type='datetime-local'
                                    id='endTime'
                                    name='endTime'
                                    className='form-control'
                                    value={endTime || ''}
                                    onChange={(e) => setEndTime(e.target.value)}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </section>
        </>
    );
};

export default OTAFirmwareUpdateDetail;
