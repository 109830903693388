import './homepage.scss';
import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Carousel, Card } from 'react-bootstrap';
import { AccountContext } from '../../../App';
import { useTranslation } from 'react-i18next';

function Homepage() {
    const { t } = useTranslation('homepage'); // i18n

    const accountContext = useContext(AccountContext);
    const homePageIndex = accountContext.homePageIndex;
    const setHomePageIndex = accountContext.setHomePageIndex;
    const handleSelect = (selectedIndex, e) => {
        setHomePageIndex(selectedIndex);
        //// Navbar text color end
    };

    const isLoaded = document.querySelectorAll('.visually-hidden')[1];
    useEffect(() => {
        document.querySelectorAll('.visually-hidden')[0].textContent = '';
        document.querySelectorAll('.visually-hidden')[1].textContent = '';
        document.querySelectorAll('.head-menu-item1').forEach((el) => {
            el.classList.add('homepageNavColor');
        });

        return () => {
            document.querySelectorAll('.head-menu-item1').forEach((el) => {
                el.classList.remove('homepageNavColor');
            });
        };
    }, [isLoaded]);

    return (
        <div className='container-fluid p-0'>
            <article id='container-homepage'>
                <section className='carousel-section'>
                    <Carousel activeIndex={homePageIndex} onSelect={handleSelect} variant={'dark'} interval={5000}>
                        {/* 善愛嘉年華Banner */}
                        {/* <Carousel.Item>
                            <picture>
                                <source
                                    className='d-block w-100'
                                    media='(min-width: 992px)'
                                    srcSet='./images/home/banner-act-20240803-PC.png'
                                    alt='Third slide'
                                />
                                <img
                                    className='d-block w-100'
                                    src='./images/home/善愛嘉年華Banner-202407-MB-360x680.png'
                                    alt='Third slide'
                                />
                            </picture>
                        </Carousel.Item> */}
                        <Carousel.Item>
                            <picture>
                                <source
                                    className='d-block w-100'
                                    media='(min-width: 992px)'
                                    srcSet='./images/home/homepage_slide_1_2x.png'
                                    alt='First slide'
                                />
                                <img
                                    className='d-block w-100'
                                    src='./images/home/homepage_slide_1_1x.png'
                                    alt='First slide'
                                />
                            </picture>

                            <Carousel.Caption>
                                <h1 className='text-white mb-2 mb-lg-5'>{t('synerter_ping_pong_robot')}</h1>
                                <h2 className='text-white mb-3 mb-lg-4'>{t('ai_algorithm_imported_processor')}</h2>
                                <p className='text-white mb-3 mb-lg-6'>
                                    {t('flagship_performance')}
                                    <span className='d-none d-lg-inline px-lg-1'>｜</span>
                                    <br className=' d-lg-none' />
                                    {t('high_precision_brushless_dc_motor')}
                                </p>
                                <div
                                    className='carouselBtnWrap d-flex flex-column-reverse flex-md-row  align-items-center justify-content-md-center justify-content-lg-start'
                                    style={{ gap: '16px' }}
                                >
                                    <Link className=' btn-size-m' to='/productcenter/productfeature'>
                                        <button type='button' className='btn btn-size-m btn-hover-white  rounded-pill '>
                                            {t('learn_more')}
                                        </button>
                                    </Link>
                                    {/* <Link className=' btn-size-m' to='/support/contactus'> */}
                                    <Link className=' btn-size-m' to='/onlineStore'>
                                        <button
                                            type='button'
                                            className='btn btn-size-m btn-color-main rounded-pill text-white'
                                        >
                                            {t('buy_now')}
                                        </button>
                                    </Link>
                                </div>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <picture>
                                <source
                                    className='d-block w-100'
                                    media='(min-width: 992px)'
                                    srcSet='./images/home/homepage_slide_2_2x.png'
                                    alt='Second slide'
                                />
                                <img
                                    className='d-block w-100'
                                    src='./images/home/homepage_slide_2_1x.png'
                                    alt='Second slide'
                                />
                            </picture>

                            <Carousel.Caption>
                                <h1 className='text-white mb-2 mb-lg-5'>{t('synerter_ping_pong_robot')}</h1>
                                <h2 className='text-white mb-3 mb-lg-4'>{t('flexible_variation_start_training')}</h2>
                                <p className='text-white mb-3 mb-lg-6'>
                                    {t('head_and_stand_description')}
                                    <span className='d-none d-lg-inline px-lg-1'>｜</span>
                                    <br className=' d-lg-none' />
                                    {t('automatic_circulation_no_ball_jamming')}
                                </p>
                                <div
                                    className='carouselBtnWrap d-flex flex-column-reverse flex-md-row  align-items-center justify-content-md-center justify-content-lg-start'
                                    style={{ gap: '16px' }}
                                >
                                    <Link className=' btn-size-m' to='/explore'>
                                        <button type='button' className='btn btn-size-m btn-hover-white  rounded-pill '>
                                            {t('learn_more')}
                                        </button>
                                    </Link>
                                    {/* <Link className=' btn-size-m' to='/support/contactus'> */}
                                    <Link className=' btn-size-m' to='/onlineStore'>
                                        <button
                                            type='button'
                                            className='btn btn-size-m btn-color-main rounded-pill text-white'
                                        >
                                            {t('buy_now')}
                                        </button>
                                    </Link>
                                </div>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <picture>
                                <source
                                    className='d-block w-100'
                                    media='(min-width: 992px)'
                                    srcSet='./images/home/homepage_slide_3_2x.png'
                                    alt='Third slide'
                                />
                                <img
                                    className='d-block w-100'
                                    src='./images/home/homepage_slide_3_1x.png'
                                    alt='Third slide'
                                />
                            </picture>

                            <Carousel.Caption>
                                <h1 className='mb-2 mb-lg-5'>{t('synerter_ping_pong_robot')}</h1>
                                <h2 className='mb-3 mb-lg-4'>{t('app_control_efficient_extraordinary')}</h2>
                                <p className='mb-3 mb-lg-6'>
                                    {t('customizable_ball_trajectory_parameters')}
                                    <span className='d-none d-lg-inline px-lg-1'>｜</span>
                                    <br className=' d-lg-none' />
                                    {t('cloud_storage_on-demand_access')}
                                </p>
                                <div
                                    className='carouselBtnWrap d-flex flex-column-reverse flex-md-row  align-items-center justify-content-md-center justify-content-lg-start'
                                    style={{ gap: '16px' }}
                                >
                                    <Link className=' btn-size-m' to='/app/download'>
                                        <button
                                            type='button'
                                            className='btn btn-size-m btn-color-outline-transparent-auxiliary  rounded-pill'
                                        >
                                            {t('learn_more')}
                                        </button>
                                    </Link>
                                    {/* <Link className=' btn-size-m' to='/support/contactus'> */}
                                    <Link className=' btn-size-m' to='/onlineStore'>
                                        <button
                                            type='button'
                                            className='btn btn-size-m btn-color-main rounded-pill text-white'
                                        >
                                            {t('buy_now')}
                                        </button>
                                    </Link>
                                </div>
                            </Carousel.Caption>
                        </Carousel.Item>
                    </Carousel>
                </section>
                <section className='articleCard-section row flex-column flex-lg-row g-0'>
                    <Card className='col-lg-4 px-3 py-3 py-lg-0 px-xxl-4 border-0'>
                        <Link to='/productcenter/productfeature' alc='productfeature'>
                            <div className='imgBox'>
                                <Card.Img variant='top' src='./images/home/homepage_1.png' />
                            </div>
                            <Card.Body className='bg-background'>
                                <Card.Title>{t('smooth_experience_realistic_practice')}</Card.Title>
                                <Card.Text>{t('durable_core_components_your_best_training_companion')}</Card.Text>
                            </Card.Body>
                        </Link>
                    </Card>
                    <Card className='col-lg-4 px-3 py-3 py-lg-0 px-xxl-4 border-0'>
                        <Link to='/explore' alc='explore'>
                            <div className='imgBox'>
                                <Card.Img variant='top' src='./images/home/homepage_2.png' />
                            </div>
                            <Card.Body className='bg-background'>
                                <Card.Title>{t('appreciation_journey_carefully_appreciate')}</Card.Title>
                                <Card.Text>{t('explore_more_product_content')}</Card.Text>
                            </Card.Body>
                        </Link>
                    </Card>
                    <Card className='col-lg-4 px-3 py-3 py-lg-0 px-xxl-4 border-0'>
                        <Link to='/app/download' alc='appDownload'>
                            <div className='imgBox'>
                                <Card.Img variant='top' src='./images/home/homepage_3.png' />
                            </div>
                            <Card.Body className='bg-background'>
                                <Card.Title>{t('ready_to_use_one_click_start')}</Card.Title>
                                <Card.Text>{t('install_synerter_exclusive_app_now')}!</Card.Text>
                            </Card.Body>
                        </Link>
                    </Card>
                </section>
            </article>
        </div>
    );
}

export default Homepage;
