import './actRegisterInfoForUser.scss';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import useAxios from '../../../hooks/useAxios';
import { QRCodeSVG } from 'qrcode.react';

// 報名成功QRCode訊息 與 查詢報名多筆成功QRCode頁面 (共用)
function ActRegisterInfoForUser() {
    const [data, setData] = useState();
    const [topValue, setTopValue] = useState(0); // 初始的相對高度 top 值
    const [fullPageMinHeight, setFullPageMinHeight] = useState(0); // 頁面最小高度
    const { registrationGuid, scheduleGuid } = useParams();
    const { operation } = useAxios();

    // console.log('registrationGuid', registrationGuid); // 報名成功頁面
    // console.log('scheduleGuid', scheduleGuid); // 查詢頁面
    // console.log('data', data);

    // 對應APP.js的路由，兩個功能帶進來的guid不同，用此來判斷目前顯示哪個功能
    const whichPageInfo = registrationGuid ? 'successRegistration' : scheduleGuid ? 'searchSeveralRegistration' : '';

    const navigate = useNavigate();
    const location = useLocation();

    // const searchParams = new URLSearchParams(location.search); // 使用 URLSearchParams 來解析 query parameters

    // 使用 get 方法來取得特定的參數值(從報名查詢頁面轉過來的參數)
    // const actName = searchParams.get('actName');
    // const boothName = searchParams.get('boothName');
    // const scheduleName = searchParams.get('scheduleName');
    // const listSchedule = searchParams.get('listSchedule');
    // const activityGuid = searchParams.get('activityGuid');
    // const searchName = searchParams.get('searchName');
    // const searchPhone = searchParams.get('searchPhone');
    // const searchEmail = searchParams.get('searchEmail');
    // const registrationKey = searchParams.get('registrationKey');
    // const playLimit = searchParams.get('playLimit');

    const {
        actName = '',
        boothName = '',
        scheduleName = '',
        listSchedule = '',
        activityGuid = '',
        searchName = '',
        searchPhone = '',
        searchEmail = '',
        registrationKey = '',
        playLimit = '',
    } = location.state || {};

    useEffect(() => {
        // 將資料轉成二維碼並且依照號碼排序
        const convertToQRCodesAndSort = (jsonData) => {
            return jsonData
                .map((item) => {
                    // 加入各項目的二維碼
                    const jsonString = JSON.stringify({
                        ...item,
                    });
                    const base64Encoded = btoa(unescape(encodeURIComponent(jsonString)));

                    return {
                        ...item,
                        dataToQrCode: base64Encoded,
                    };
                })
                .sort((a, b) => {
                    return a.queueNo - b.queueNo; // 依照號碼排序
                });
        };

        // 1. 獲取註冊成功資料(1筆)
        const getRegistrationDetails = async () => {
            const { success, response, error } = await operation(
                {
                    url: `/ActRegistration/getRegistrationDetails/${registrationGuid}`,
                },
                'dataAddBaseInfo'
            );
            if (success) {
                // console.log('getRegistrationDetails', response.data);

                const { registrationGuid, scheduleGuid, queueNo, name, phone, score, playCount, rank } = {
                    ...response.data.registration,
                };

                const dataAddBaseInfo = {
                    registrationGuid,
                    scheduleGuid,
                    queueNo,
                    name,
                    phone,
                    score,
                    playCount,
                    rank,
                    actName: actName,
                    scheduleName: scheduleName,
                };

                // console.log('dataAddBaseInfo', dataAddBaseInfo);

                const newData = convertToQRCodesAndSort([
                    {
                        ...dataAddBaseInfo,
                    },
                ]);

                setData([...newData]);
            } else if (error) {
                console.log(error);
            }
        };

        // 2. 查詢註冊成功資料(可能多筆)
        const searchSeveralRegistration = async () => {
            const advancedSearchDataList = [
                {
                    searchFilter: 'name',
                    searchValue: searchName,
                    searchLogic: '',
                    searchOperator: '==',
                },
                {
                    searchFilter: registrationKey === '0' ? 'phone' : 'email',
                    searchValue: registrationKey === '0' ? searchPhone : searchEmail,
                    searchLogic: searchName && (registrationKey === '0' ? searchPhone : searchEmail) ? 'And' : 'Or', // 使用者姓名與電話都有填資料時，搜尋方式為 And，否則為 Or
                    searchOperator: '==',
                },
            ];

            const { success, response, error } = await operation(
                {
                    url: `/ActRegistration/GetRegistrationList?guid=${scheduleGuid}`,
                    method: 'POST',
                    data: {
                        advancedSearchDataList: advancedSearchDataList,
                    },
                },
                'getRegistrationList'
            );
            if (success) {
                console.log('getRegistrationList', response.data);

                const dataAddBaseInfo = response.data.data.map((item) => {
                    const { registrationGuid, scheduleGuid, queueNo, name, phone, email, score, playCount, rank } = {
                        ...item,
                    };

                    return {
                        registrationGuid,
                        scheduleGuid,
                        queueNo,
                        name,
                        phone,
                        email,
                        score,
                        playCount,
                        rank,
                        actName: actName,
                        scheduleName: scheduleName,
                    };
                });

                // console.log('dataAddBaseInfo', dataAddBaseInfo);

                if (response.data.data.length > 0) {
                    const newData = convertToQRCodesAndSort([...dataAddBaseInfo]);
                    setData([...newData]);
                } else {
                    // 查無資料
                    const newData = [
                        {
                            registrationGuid: '00000000-0000-0000-0000-000000000000', // 作為map的key
                            isNoInfoFound: true, // 是否為查無資料
                        },
                    ];
                    setData([...newData]);
                }
            } else if (error) {
                console.log(error);
            }
        };

        if (whichPageInfo === 'successRegistration') {
            getRegistrationDetails();
        } else if (whichPageInfo === 'searchSeveralRegistration') {
            searchSeveralRegistration();
        }

        // 3.依照螢幕寬度調整與最上方圖片的相對高度
        // 函式：根據視窗寬度返回初始的 top 值
        const windowWidth = window.innerWidth;

        // 在組件掛載後計算初始的 top 值並設置
        const initialTop = windowWidth * 0.68;
        setTopValue(initialTop);

        const initialMinHeight = 950 + windowWidth * 0.63;
        setFullPageMinHeight(initialMinHeight);

        const handleResize = () => {
            const windowWidth = window.innerWidth;

            // 根據視窗寬度調整值
            setTopValue(windowWidth * 0.68);
            setFullPageMinHeight(950 + windowWidth * 0.63);
        };

        // 監聽視窗大小變化事件
        window.addEventListener('resize', handleResize);

        // 清除效果
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // 捲動頁面改變nav背景顏色
    const [scrollTop, setScrollTop] = useState(0);

    function throttle(callback, delay) {
        let previousCall = new Date().getTime();
        return function () {
            const time = new Date().getTime();
            if (time - previousCall >= delay) {
                previousCall = time;
                callback.apply(null, arguments);
            }
        };
    }

    useEffect(() => {
        const header = document.getElementById('header-app');
        header.classList.add('customerHeader');
        header.classList.remove('bg-white');

        const handleScroll = () => {
            setScrollTop(window.pageYOffset || document.documentElement.scrollTop);
        };

        // const throttledScroll = throttle(handleScroll, 300); // 設置 200 毫秒的節流

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, [scrollTop]); // 只在 scrollTop 改變時觸發

    return (
        <div className='container-fluid p-0'>
            <article
                id='container-actRegisterInfoForUser'
                className='bgImage bg-background flex-column align-items-center px-0'
                style={{ minHeight: `${fullPageMinHeight}px` }}
            >
                <picture>
                    <source
                        srcSet='../../../../images/actManage/actRegisterInfoForUser/topTitle_2x.png'
                        media='(min-width: 1025px)'
                    />
                    <img
                        className='w-100'
                        src='../../../../images/actManage/actRegisterInfoForUser/topTitle_1x.png'
                        alt='topTitle_1x'
                    />
                </picture>
                {data?.map((item) => {
                    return (
                        <article
                            key={item?.registrationGuid}
                            className='position-absolute  w-100 articleBox bg-transparent border-0 mx-0 my-4'
                            style={{ top: `${topValue}px` }}
                        >
                            {/* <section className={`row mb-6`}>
								<img
									src="../../../../images/actManage/actRegisterInfoForUser/topTitle_1x.png"
									alt="topTitle_1x"
								/>
							</section> */}

                            <section className={`row g-3 h-100 registerPageShow d-flex justify-content-center`}>
                                <h1 className='pageTitle fw-bold mt-0 mb-2'>{actName}</h1>
                                {listSchedule === true && <p className='text-center fw-bold m-0'>{scheduleName}</p>}
                                <p className='text-center fw-bold mt-0 mb-3' style={{ fontSize: '24px' }}>
                                    {boothName}
                                </p>

                                <div className='codeWrap  d-inline-block position-relative border border-3 border-dark px-8 px-sm-10 pt-6 pb-6'>
                                    <h1
                                        className='codeTitle position-absolute fw-bold text-center px-3 mb-0'
                                        style={{
                                            fontSize: '26px',
                                            color: whichPageInfo === 'successRegistration' ? '#263BE3' : '#263BE3',
                                            width: whichPageInfo === 'successRegistration' ? '230px' : 'auto',
                                        }}
                                    >
                                        {whichPageInfo === 'successRegistration' ? '恭喜您報名成功!' : '查詢結果'}
                                    </h1>

                                    {item.isNoInfoFound ? (
                                        <></>
                                    ) : (
                                        <div className='text-center'>
                                            <QRCodeSVG
                                                value={item.dataToQrCode}
                                                size={200}
                                                bgColor={'transparent'}
                                                fgColor={'#000000'}
                                                level={'L'}
                                                includeMargin={false}
                                                // imageSettings={{
                                                // 	src: 'https://static.zpao.com/favicon.png',
                                                // 	x: undefined,
                                                // 	y: undefined,
                                                // 	height: 24,
                                                // 	width: 24,
                                                // 	excavate: true,
                                                // }}
                                            />
                                        </div>
                                    )}

                                    <div
                                        style={{
                                            fontWeight: 'bold',
                                            color: '#263BE3',
                                        }}
                                    >
                                        <div
                                            className='text-center mt-3'
                                            style={{
                                                fontSize: '20px',
                                                fontWeight: 'bold',
                                                color: '#263BE3',
                                            }}
                                        >
                                            QRCODE編號
                                        </div>
                                        <div
                                            className='text-center p-0 mt-0 mb-0'
                                            style={{
                                                fontSize: item.isNoInfoFound ? '50px' : '90px',
                                                lineHeight: '80px',
                                            }}
                                        >
                                            {item.isNoInfoFound ? '查無資料' : `${item?.queueNo ?? ''}`}
                                        </div>
                                    </div>

                                    <div
                                        className='text-center mt-0 mb-4'
                                        style={{
                                            fontSize: '24px',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {`${item?.name ?? ''}`}
                                    </div>

                                    <div
                                        className='col-12 text-center mb-0'
                                        style={{
                                            fontSize: '16px',
                                        }}
                                    >
                                        <h6 className='fw-bold'>{`我的排名：${item?.rank ?? ''}`}</h6>
                                        <h6 className='fw-bold'>{`我的最高分數：${item?.score ?? ''}`}</h6>
                                        <h6 className='fw-bold'>{`已上場次數：${item?.playCount ?? ''}`}</h6>
                                        {playLimit !== 'null' && (
                                            <h6 className='fw-bold'>{`可上場次數：${
                                                playLimit * 1 - (item?.playCount ?? 0 * 1)
                                            }`}</h6>
                                        )}
                                    </div>

                                    <button
                                        type='button'
                                        id='registerAgreeBtn'
                                        className='btn btn-color-main rounded-pill position-absolute fw-bold text-center p-0'
                                        onClick={() => navigate(`/activity/${activityGuid}`)}
                                    >
                                        返回報名
                                    </button>
                                </div>

                                {item.isNoInfoFound ? (
                                    <div className='mb-10'></div>
                                ) : (
                                    <div className='col-12 mt-6 text-center position-relative'>
                                        <p className='fw-bold mb-3' style={{ fontSize: '20px' }}>
                                            請將QRCODE編號截圖保存!
                                        </p>
                                        <p className='fw-bold mb-1' style={{ fontSize: '16px' }}>
                                            活動現場報到時，
                                        </p>
                                        <p className='fw-bold mb-2' style={{ fontSize: '16px' }}>
                                            請出示本頁QRcode編號截圖給工作人員。
                                        </p>
                                    </div>
                                )}
                            </section>
                        </article>
                    );
                })}
            </article>
        </div>
    );
}

export default ActRegisterInfoForUser;
