import './checkoutInfo.scss';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useAxios from '../../../hooks/useAxios';
import Swal from 'sweetalert2';
import { SimpleLoading } from '../../../components/simpleLoading/SimpleLoading';
import { ReactComponent as Icn_CheckBlueSmBtn } from '../../../assets/images/icn_CheckBlueSmBtn.svg';
import { AccountContext } from '../../../App';
import { useTranslation } from 'react-i18next';

const CheckoutInfo = () => {
    const { t } = useTranslation('checkoutInfo'); // i18n
    const { operation } = useAxios();

    const navigate = useNavigate();
    const location = useLocation();
    const { lang } = useContext(AccountContext);

    //#region [States initializations]
    const [isLoading, setIsLoading] = useState(false);
    const [isTheSameBuyerInfo, setIsTheSameBuyerInfo] = useState(false);
    const [countryList, setCountryList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [districtList, setDistrictList] = useState([]);
    const [isNeedToPayDeliveryFee, setIsNeedToPayDeliveryFee] = useState(false); //透過收件人地址的鄉鎮區判斷是否需要付運費;
    const [donateList, setDonateList] = useState([]);
    const [accountInfo, setAccountInfo] = useState([]);

    const getAccountInfo = async () => {
        try {
            const { success, response, error } = await operation(
                {
                    url: '/user?languageCode=ZF',
                },
                'getAffiliatedList'
            );

            if (success) {
                // console.log(response);
                const defaultData = {
                    invoiceFormat: '0', // 預設為個人
                    paymentMethod: '0', // 預設為信用卡付款
                    deliveryMethod: '1', // 預設宅配到府

                    orderDate: new Date().toISOString().split('T')[0],
                    lastname: response.data.lastname ?? '',
                    firstname: response.data.firstname ?? '',
                    phone1CountryCode: response.data.phone1CountryCode ?? '886', // 預設台灣
                    phone1: response.data.phone1 ?? '',
                    email1: response.data.email1 ?? '',

                    recipientLastname: '',
                    recipientFirstname: '',
                    recipientCountryCode: '886', // 預設台灣
                    recipientPhone: '',

                    recipientCountry: 'TW',

                    recipientProvince: '', // guid
                    recipientCity: '', // guid
                    recipientDistrict: '', // guid

                    recipientStreet: '',

                    note: '',

                    // invoiceFormat 1
                    companyName: '',
                    taxID: '',
                    // invoiceFormat 2
                    donateCode: '',
                    // invoiceFormat 3
                    carrierId: '',
                    // paymentMethod 1
                    bankAccount: '',
                };
                setAccountInfo(defaultData);
            } else {
                console.log('Failed to fetch affiliated list');
                console.log(error);
            }
        } catch (error) {
            console.log(error);
        }
    };
    //     invoiceFormat: '0', // 預設為個人
    //     paymentMethod: '0', // 預設為信用卡付款
    //     deliveryMethod: '1', // 預設宅配到府

    //     // orderDate: new Date().toISOString().split('T')[0],
    //     // lastname: accountInfo.lastname ?? '',
    //     // firstname: accountInfo.firstname ?? '',
    //     // countryCode: accountInfo.phone1CountryCode ?? '886', // 預設台灣
    //     // phone: accountInfo.phone1 ?? '',
    //     // email: accountInfo.email1 ?? '',

    //     recipientLastname: '',
    //     recipientFirstname: '',
    //     recipientCountryCode: '886', // 預設台灣
    //     recipientPhone: '',

    //     recipientCountry: '',

    //     recipientProvince: '', // guid
    //     recipientCity: '', // guid
    //     recipientDistrict: '', // guid

    //     recipientStreet: '',

    //     note: '',

    //     // invoiceFormat 1
    //     companyName: '',
    //     taxID: '',
    //     // invoiceFormat 2
    //     donateCode: '',
    //     // invoiceFormat 3
    //     carrierId: '',
    //     // paymentMethod 1
    //     bankAccount: '',
    // };

    // const [data, setData] = useState(defaultData);

    const handleCityChange = async (e) => {
        setAccountInfo((prev) => {
            return {
                ...prev,
                recipientCity: e.target.value,
                recipientCityWarning: '',
            };
        });

        setIsNeedToPayDeliveryFee(false); // 先清空
        const { success, response, error } = await operation(
            {
                url: `/user/getDistrictList?cityGuid=${e.target.value}`,
            },
            'handleCityChange'
        );
        if (success) {
            if (response.status === 200) {
                // console.log(response.data.result);

                setDistrictList(response.data.result);
                setAccountInfo((prev) => {
                    return {
                        ...prev,
                        recipientDistrict: '',
                    };
                });
            }
        }
        if (error) {
            console.log(error);
        }
    };

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const verifyRequired = () => {
        let newData = { ...accountInfo };
        let faultCount = 0;

        const requiredValue = [
            'orderDate',
            'lastname',
            'firstname',
            'phone1',
            'email1',

            'recipientLastname',
            'recipientFirstname',
            'recipientPhone',

            'deliveryMethod',
            'invoiceFormat',
            'paymentMethod',
        ];

        const addressRequired = ['recipientCountry', 'recipientCity', 'recipientDistrict', 'recipientStreet'];

        const invoiceRequired = {
            0: [],
            1: ['companyName', 'taxID'],
            2: ['donateCode'],
            3: ['carrierId'],
        };
        const paymentRequired = {
            0: [], // 信用卡
            1: ['bankAccount'], // ATM
            2: [], // 現金
            3: [], // 貨到付款
        };

        // 1.必填欄位
        requiredValue.forEach((key) => {
            if (newData[key] === '') {
                newData = {
                    ...newData,
                    [`${key}Warning`]: t('please_fill_out_information'),
                };
            }
        });

        // 2.選擇宅配到府，則地址必填
        if (accountInfo['deliveryMethod'] === '1') {
            addressRequired.forEach((key) => {
                if (accountInfo[key] === '') {
                    newData = {
                        ...newData,
                        [`${key}Warning`]: t('please_fill_out_information'),
                    };
                }
            });
        }

        // 3.選擇發票，則其對應的欄位必填
        invoiceRequired[accountInfo['invoiceFormat']].forEach((key) => {
            if (accountInfo[key] === '') {
                newData = {
                    ...newData,
                    [`${key}Warning`]: t('please_fill_out_information'),
                };
            }
        });

        // 3.選擇付款方式，則其對應的欄位必填
        paymentRequired[accountInfo['paymentMethod']].forEach((key) => {
            if (accountInfo[key] === '') {
                newData = {
                    ...newData,
                    [`${key}Warning`]: t('please_fill_out_information'),
                };
            }
        });

        // 5.驗證是否所有warning都為空
        Object.keys(newData).forEach((key) => {
            if (key.includes('Warning')) {
                if (newData[key] !== '') {
                    faultCount++;
                }
            }
        });

        setAccountInfo(newData);

        return faultCount;
    };

    // 信用卡付款開新分頁(合庫)
    const handleCreditCardFormSubmit = async (orderGuid) => {
        // 從環境變數中獲取值
        const {
            NODE_ENV,
            REACT_APP_MER_ID,
            REACT_APP_MERCHANT_ID,
            REACT_APP_TERMINAL_ID,
            REACT_APP_MERCHANT_NAME,
            REACT_APP_CUSTOMIZE,
            REACT_APP_AUTO_CAP,
            REACT_APP_PAY_TYPE,
            REACT_APP_PERIOD_NUM,
            REACT_APP_SUB_MERCH_ID,
            REACT_APP_EN_CODE_TYPE,
            REACT_APP_TIMEOUT_SECS,
            REACT_APP_THREE_DS_AUTH_IND,
            REACT_APP_REQ_TOKEN,
            REACT_APP_LAG_SELECT,
            REACT_APP_AUTH_RES_URL_PROD,
            REACT_APP_AUTH_RES_URL_DEV,
            REACT_APP_FRONT_FAIL_URL_PROD,
            REACT_APP_FRONT_FAIL_URL_DEV,
        } = process.env;

        try {
            const { success, response, error } = await operation(
                {
                    url: `/payment/getCreditCardInfo/${orderGuid}`,
                },
                'handleCreditCardFormSubmit'
            );
            console.log(response.data.result);
            const orderInfo = response.data.result;

            // 填充表单数据
            const keysToFrom = {
                MerchantID: REACT_APP_MERCHANT_ID,
                MerchantName: REACT_APP_MERCHANT_NAME,
                customize: REACT_APP_CUSTOMIZE,
                AutoCap: REACT_APP_AUTO_CAP,
                TerminalID: REACT_APP_TERMINAL_ID,
                frontFailUrl: NODE_ENV === 'development' ? REACT_APP_FRONT_FAIL_URL_DEV : REACT_APP_FRONT_FAIL_URL_PROD,
                AuthResURL: NODE_ENV === 'development' ? REACT_APP_AUTH_RES_URL_DEV : REACT_APP_AUTH_RES_URL_PROD,
                merID: REACT_APP_MER_ID,
                PayType: REACT_APP_PAY_TYPE,
                PeriodNum: REACT_APP_PERIOD_NUM,
                subMerchID: REACT_APP_SUB_MERCH_ID,
                enCodeType: REACT_APP_EN_CODE_TYPE,
                timeoutSecs: REACT_APP_TIMEOUT_SECS,
                lagSelect: lang === 'zh-TW' ? 0 : lang === 'zh-CN' ? 1 : lang === 'en' ? 2 : 0,
                reqToken: REACT_APP_REQ_TOKEN,
                threeDSAuthInd: REACT_APP_THREE_DS_AUTH_IND,

                lidm: orderInfo.lidm,
                purchAmt: orderInfo.purchAmt,
                LocalDate: orderInfo.localDate,
                LocalTime: orderInfo.localTime,
                timeoutDate: orderInfo.timeoutDate,
                timeoutTime: orderInfo.timeoutTime,
                mobileNbr: orderInfo.mobileNbr,
                walletVerifyCode: orderInfo.walletVerifyCode,
            };

            // 構建查詢參數
            const queryParams = new URLSearchParams(keysToFrom).toString();

            // 重定向到目標頁面
            window.location.href = `https://www.focas.fisc.com.tw/FOCAS_WEBPOS/online/?${queryParams}`;
        } catch (error) {
            console.error('Error fetching credit card info:', error);
            alert('Error fetching credit card info:', error);
            // 這裡可以添加錯誤處理的額外邏輯，例如顯示錯誤訊息給用戶
        }
    };

    const handleSubmit = async () => {
        const faultCount = verifyRequired();
        console.log('faultCount', faultCount);
        if (faultCount !== 0) return;

        setIsLoading(true);

        let newSalesOrderItemList = location.state.checkOrderData.map((item) => {
            return {
                CartGuid: item.cartGuid,
                SalesKitGuid: item.salesKitGuid,
                Quantity: item.quantity,
                PromoteGrossAmount: item.promoteGrossAmount,
            };
        });

        const newData = {
            couponCode: location.state.promoCode,
            OrderDate: new Date().toISOString().split('T')[0],
            Lastname: accountInfo.lastname,
            Firstname: accountInfo.firstname,
            CountryCode: accountInfo.phone1CountryCode,
            Phone: accountInfo.phone1,
            Email: accountInfo.email1,
            RecipientLastname: accountInfo.recipientLastname,
            RecipientFirstname: accountInfo.recipientFirstname,
            RecipientCountryCode: accountInfo.recipientCountryCode,
            RecipientPhone: accountInfo.recipientPhone,
            RecipientCountry: accountInfo.recipientCountry,
            RecipientProvince: accountInfo.recipientProvince,
            RecipientCity: accountInfo.recipientCity,
            RecipientDistrict: accountInfo.recipientDistrict,
            RecipientStreet: accountInfo.recipientStreet,
            PaymentMethod: accountInfo.paymentMethod,
            BankAccount: accountInfo.bankAccount,
            DeliveryMethod: accountInfo.deliveryMethod,
            InvoiceFormat: accountInfo.invoiceFormat,
            TaxID: accountInfo.taxID,
            CompanyName: accountInfo.companyName,
            CarrierId: accountInfo.carrierId,
            NPOBAN: accountInfo.donateCode,
            Note: accountInfo.note,
            SalesOrderItemList: newSalesOrderItemList,
        };
        console.log(newData.CountryCode);
        try {
            const { success, response, error } = await operation(
                {
                    url: '/salSalesOrder/insertSalesOrder',
                    method: 'POST',
                    data: newData,
                },
                'handleSubmit'
            );
            // console.log(response.data);
            setIsLoading(false);
            const orderGuid = response.data.orderGuid;
            // 錯誤操作(使用者已送出訂單，又回到此頁面，再次送出訂單)
            if (response.data.status === '700') {
                Swal.fire({
                    title: t('order_or_cart_changed'),
                    icon: 'warning',
                    text: t('order_or_cart_changed_description'),
                    allowOutsideClick: false,
                    confirmButtonText: t('view_order_status'),
                    confirmButtonColor: 'var(--primary)',
                    cancelButtonText: t('back_to_shopping_cart'),
                    cancelButtonColor: 'var(--secondary)',
                    showCancelButton: true,
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate('/orders');
                    } else if (result.isDismissed) {
                        navigate('/cart');
                    }
                });
                return;
            }

            // 正常操作
            switch (newData.PaymentMethod) {
                case '0': // 信用卡
                    handleSendEmail(orderGuid);
                    handleCreditCardFormSubmit(orderGuid);
                    break;

                case '2': // ATM
                    handleSendEmail(orderGuid);
                    navigate(`/PdUnpaid/${orderGuid}`);
                    break;

                case '3': // 貨到付款
                    handleSendEmail(orderGuid);
                    navigate(`/PdCheckout/${orderGuid}`);
                    break;

                default:
                    break;
            }
        } catch (err) {
            setIsLoading(false);
            Swal.fire({
                title: t('order_or_cart_changed'),
                icon: 'warning',
                text: t('order_or_cart_changed_description'),
                allowOutsideClick: false,
                confirmButtonText: t('view_order_status'),
                confirmButtonColor: 'var(--primary)',
                cancelButtonText: t('back_to_shopping_cart'),
                cancelButtonColor: 'var(--secondary)',
                showCancelButton: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate('/orders');
                } else if (result.isDismissed) {
                    navigate('/cart');
                }
            });
            console.log(err);
        }
    };

    // 訂單建立(未付款)時寄信給使用者與管理員
    const handleSendEmail = async (orderGuid) => {
        const locale = 'zh-TW'; // 單一字串資料
        const { success, response, error } = await operation(
            {
                url: `/SalSalesOrder/sendOrderConfirmation/${orderGuid}`,
                method: 'POST',
                data: locale,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept-Language': lang,
                },
            },
            'handleSendEmail'
        );
        if (success) {
            console.log(response.data);
        } else if (error) {
            console.log(error);
        }
    };
    //#endregion

    //#region [useEffect]

    // 使用者資料
    // useEffect(() => {
    //     // 確保 accountInfo 有值
    //     if (accountInfo) {
    //         setData((prevData) => ({
    //             ...prevData,
    //             lastname: accountInfo.lastname,
    //             firstname: accountInfo.firstname,
    //             countryCode: accountInfo.phone1CountryCode ?? '886',
    //             phone: accountInfo.phone1,
    //             email: accountInfo.email1,
    //         }));
    //     }
    // }, [accountInfo]); // 只有當 accountInfo 更新時才執行

    useEffect(() => {
        // 如果沒有傳入資料(非正規從cart頁面下一步進到此頁面)，就跳轉回購物車
        // console.log(location.state);
        if (location.state == null) {
            Swal.fire({
                title: t('please_return_to_shopping_cart'),
                icon: 'warning',
                allowOutsideClick: false,
                confirmButtonText: t('confirm'),
                confirmButtonColor: 'var(--primary)',
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate('/cart');
                }
            });
        }

        const getCountryList = async () => {
            // 取國家 目前國家台灣為預設
            const { success, response, error } = await operation(
                {
                    url: '/user/getCountryList',
                },
                'getCountryList'
            );
            if (success) {
                // console.log(response.data.result);
                // 排序讓台灣在前面
                let newList = response.data.result.sort((a, b) => {
                    return b.countryCode.localeCompare(a.countryCode);
                });
                setCountryList(newList); // 供電話使用

                setAccountInfo((accountInfo) => ({
                    ...accountInfo,
                    recipientCountry: 'TW',
                }));
            } else if (error) {
                console.log(error);
            }

            // 取省州 目前台灣省為預設
            const defaultProvinceGuid = async () => {
                const { success, response, error } = await operation(
                    {
                        url: `/user/getProvinceList?countryCode=TW`,
                    },
                    'defaultProvinceGuid'
                );
                if (success) {
                    // console.log(response.data);

                    setAccountInfo((pre) => ({
                        ...pre,
                        recipientProvince: response.data.result[0].provinceGuid,
                    }));

                    return response.data.result[0].provinceGuid;
                } else if (error) {
                    console.log(error);
                    return null; // 如果有錯誤，返回一個明確的值（例如 null）
                }
            };

            const provinceGuid = await defaultProvinceGuid();

            if (provinceGuid) {
                // 取縣市 (預設臺灣省的guid)
                const cityResponse = async () => {
                    const { success, response, error } = await operation(
                        {
                            url: `/user/getProvinceCityList?provinceGuid=${provinceGuid}`,
                        },
                        'getCityResponse'
                    );
                    if (success) {
                        // console.log(response.data);
                        setCityList(response.data.result);
                    } else if (error) {
                        console.log(error);
                    }
                };

                await cityResponse();
            } else {
                console.log('無法獲取省份 GUID');
            }
        };

        const getDonateList = async () => {
            const { success, response, error } = await operation(
                {
                    url: '/SalSalesOrder/getDonationCodes',
                },
                'getDonateList'
            );
            if (success) {
                // console.log(response.data);
                setDonateList(response.data);
            } else if (error) {
                console.log(error);
            }
        };
        getAccountInfo();
        getCountryList();
        getDonateList();
    }, []);
    console.log(accountInfo);
    //#endregion

    return (
        <>
            {/* loading animate */}
            <SimpleLoading isLoading={isLoading} />

            <div
                className='container-fluid position-relative p-0 text-center bg-background'
                id='container-checkoutInfo'
            >
                <div className='topTagArea'></div>

                <div className='mainArea wrapper mx-auto d-flex flex-column flex-lg-row px-3 px-sm-5 px-lg-0'>
                    {/* 使用者資訊區塊 */}
                    <section className='userInfoSection d-flex flex-column w-100 mb-0 mb-lg-0'>
                        <div className='infoWrapper bg-white py-3 mb-4'>
                            <div className='infoTop d-flex align-items-center pb-3 mb-4 border-bottom border-grey'>
                                <h6 className='px-4 mb-0'>{t('billing_information')}</h6>
                            </div>
                            <div className='infoBottom px-4'>
                                <div className='row'>
                                    <div className='col-12 col-sm-6 col-lg-3 ps-0'>
                                        <label htmlFor='lastname' className='w-100 text-start mb-2'>
                                            {t('last_name')}
                                        </label>
                                        <input
                                            type='text'
                                            id='lastname'
                                            name='lastname'
                                            className={`form-control ${
                                                accountInfo.lastnameWarning ? 'border-danger' : ''
                                            }`}
                                            value={accountInfo.lastname || ''}
                                            onChange={(e) =>
                                                setAccountInfo({
                                                    ...accountInfo,
                                                    lastname: e.target.value,
                                                    fullname: `${e.target.value}${accountInfo.firstname ?? ''}`,
                                                    lastnameWarning: '',
                                                })
                                            }
                                        />
                                        <div className='warningText text-danger text-start mb-3'>
                                            {accountInfo.lastnameWarning}
                                        </div>
                                    </div>
                                    <div className='col-12 col-sm-6 col-lg-5 ps-0'>
                                        <label htmlFor='firstname' className='w-100 text-start mb-2'>
                                            {t('first_name')}
                                        </label>
                                        <input
                                            type='text'
                                            id='firstname'
                                            name='firstname'
                                            className={`form-control ${
                                                accountInfo.firstnameWarning ? 'border-danger' : ''
                                            }`}
                                            value={accountInfo.firstname || ''}
                                            onChange={(e) =>
                                                setAccountInfo({
                                                    ...accountInfo,
                                                    firstname: e.target.value,
                                                    fullname: `${accountInfo.lastname ?? ''}${e.target.value}`,
                                                    firstnameWarning: '',
                                                })
                                            }
                                        />
                                        <div className='warningText text-danger text-start mb-3'>
                                            {accountInfo.firstnameWarning}
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-12 col-sm-6 col-lg-3 ps-0'>
                                        <label htmlFor='countryCode' className='w-100 text-start mb-2'>
                                            {t('contact_number')}
                                        </label>
                                        <select
                                            className='form-select'
                                            value={accountInfo.phone1CountryCode}
                                            onChange={(e) =>
                                                setAccountInfo({
                                                    ...accountInfo,
                                                    phone1CountryCode: e.target.value,
                                                })
                                            }
                                        >
                                            {countryList.map((item) => (
                                                <option key={item.telCode} value={item.telCode}>
                                                    {`${item.name_ZF}(+${item.telCode}) `}
                                                </option>
                                            ))}
                                        </select>

                                        <div className='warningText text-danger text-start mb-3'>
                                            {accountInfo.countryCodeWarning}
                                        </div>
                                    </div>
                                    <div className='col-12 col-sm-6 col-lg-5 ps-0 mt-2 mt-sm-0'>
                                        <label htmlFor='phone' className='w-100 text-start mb-2 invisibleItem'>
                                            phone
                                        </label>
                                        <input
                                            type='text'
                                            id='phone'
                                            name='phone'
                                            className={`form-control ${
                                                accountInfo.phone1Warning ? 'border-danger' : ''
                                            }`}
                                            value={accountInfo.phone1 || ''}
                                            onChange={(e) =>
                                                setAccountInfo({
                                                    ...accountInfo,
                                                    phone1: e.target.value,
                                                    phone1Warning: '',
                                                })
                                            }
                                        />
                                        <div className='warningText text-danger text-start mb-3'>
                                            {accountInfo.phone1Warning}
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-12 col-lg-8 ps-0'>
                                        <label htmlFor='email' className='w-100 text-start mb-2'>
                                            {t('email')}
                                        </label>
                                        <input
                                            type='email'
                                            id='email'
                                            name='email'
                                            className={`form-control ${
                                                accountInfo.email1Warning ? 'border-danger' : ''
                                            }`}
                                            value={accountInfo.email1 || ''}
                                            onChange={(e) =>
                                                setAccountInfo({
                                                    ...accountInfo,
                                                    email1: e.target.value,
                                                    email1Warning: '',
                                                })
                                            }
                                            onBlur={(e) => {
                                                if (e.target.value !== '' && !validateEmail(e.target.value)) {
                                                    setAccountInfo({
                                                        ...accountInfo,
                                                        email1Warning: t('please_enter_valid_email'),
                                                    });
                                                }
                                            }}
                                        />
                                        <div className='warningText text-danger text-start mb-3'>
                                            {accountInfo.email1Warning}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='infoWrapper bg-white py-3 mb-4'>
                            <div className='infoTop d-flex align-items-center pb-3 mb-5 border-bottom border-grey'>
                                <h6 className='px-4 mb-0'>{t('shipping_information')}</h6>
                            </div>
                            <div className='infoBottom px-4'>
                                <div className='row'>
                                    <div className='col-12 ps-0'>
                                        <p className='mb-0 text-start'>{t('recipient_information')}</p>
                                        <div className='d-flex align-items-center mb-0'>
                                            <input
                                                type='checkbox'
                                                id='isTheSameBuyerInfo'
                                                name='isTheSameBuyerInfo'
                                                className='form-check mb-0'
                                                style={{
                                                    cursor: 'pointer',
                                                }}
                                                checked={isTheSameBuyerInfo}
                                                onChange={(e) => {
                                                    setIsTheSameBuyerInfo(e.target.checked);
                                                    e.target.checked
                                                        ? setAccountInfo((prev) => {
                                                              return {
                                                                  ...prev,
                                                                  recipientLastname: accountInfo.lastname,
                                                                  recipientFirstname: accountInfo.firstname,
                                                                  recipientCountryCode: accountInfo.phone1CountryCode,
                                                                  recipientPhone: accountInfo.phone1,
                                                                  recipientLastnameWarning: '',
                                                                  recipientFirstnameWarning: '',
                                                                  recipientPhoneWarning: '',
                                                              };
                                                          })
                                                        : setAccountInfo((prev) => {
                                                              return {
                                                                  ...prev,
                                                                  recipientLastname: '',
                                                                  recipientFirstname: '',
                                                                  recipientCountryCode: accountInfo.phone1CountryCode,
                                                                  recipientPhone: '',
                                                                  recipientLastnameWarning: '',
                                                                  recipientFirstnameWarning: '',
                                                                  recipientPhoneWarning: '',
                                                              };
                                                          });
                                                }}
                                            />
                                            <label
                                                htmlFor='isTheSameBuyerInfo'
                                                className='text-start ms-2'
                                                style={{
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                {t('same_as_billing_information')}
                                            </label>
                                        </div>
                                    </div>

                                    <div className='col-12 col-sm-6 col-lg-3 ps-0'>
                                        <label htmlFor='recipientLastname' className=' w-100 text-start mb-2'>
                                            {t('last_name')}
                                        </label>
                                        <input
                                            type='text'
                                            id='recipientLastname'
                                            name='recipientLastname'
                                            className={`form-control ${
                                                accountInfo.recipientLastnameWarning ? 'border-danger' : ''
                                            }`}
                                            value={accountInfo.recipientLastname || ''}
                                            onChange={(e) =>
                                                setAccountInfo({
                                                    ...accountInfo,
                                                    recipientLastname: e.target.value,
                                                    recipientLastnameWarning: '',
                                                })
                                            }
                                        />
                                        <div className='warningText text-danger text-start mb-3'>
                                            {accountInfo.recipientLastnameWarning}
                                        </div>
                                    </div>
                                    <div className='col-12 col-sm-6 col-lg-5 ps-0'>
                                        <label htmlFor='recipientFirstname' className=' w-100 text-start mb-2'>
                                            {t('first_name')}
                                        </label>
                                        <input
                                            type='text'
                                            id='recipientFirstname'
                                            name='recipientFirstname'
                                            className={`form-control ${
                                                accountInfo.recipientFirstnameWarning ? 'border-danger' : ''
                                            }`}
                                            value={accountInfo.recipientFirstname || ''}
                                            onChange={(e) =>
                                                setAccountInfo({
                                                    ...accountInfo,
                                                    recipientFirstname: e.target.value,
                                                    recipientFirstnameWarning: '',
                                                })
                                            }
                                        />
                                        <div className='warningText text-danger text-start mb-3'>
                                            {accountInfo.recipientFirstnameWarning}
                                        </div>
                                    </div>
                                </div>

                                <div className='row mb-2 mb-sm-8 mb-lg-4'>
                                    <div className='col-12 col-sm-6 col-lg-3 ps-0'>
                                        <label
                                            htmlFor='recipientCountryCode
'
                                            className=' w-100 text-start mb-2'
                                        >
                                            {t('contact_number')}
                                        </label>

                                        <select
                                            className='form-select'
                                            value={accountInfo.recipientCountryCode}
                                            onChange={(e) =>
                                                setAccountInfo({
                                                    ...accountInfo,
                                                    recipientCountryCode: e.target.value,
                                                })
                                            }
                                        >
                                            {countryList.map((item) => (
                                                <option key={item.telCode} value={item.telCode}>
                                                    {`${item.name_ZF}(+${item.telCode}) `}
                                                </option>
                                            ))}
                                        </select>

                                        <div className='warningText text-danger text-start mb-3'>
                                            {accountInfo.recipientCountryCodeWarning}
                                        </div>
                                    </div>
                                    <div className='col-12 col-sm-6 col-lg-5 ps-0 mt-2 mt-sm-0'>
                                        <label
                                            htmlFor='recipientPhone'
                                            className=' w-100 text-start invisibleItem mb-2'
                                        >
                                            recipientPhone
                                        </label>
                                        <input
                                            type='tel'
                                            id='recipientPhone'
                                            name='recipientPhone'
                                            className={`form-control ${
                                                accountInfo.recipientPhoneWarning ? 'border-danger' : ''
                                            }`}
                                            value={accountInfo.recipientPhone || ''}
                                            onChange={(e) =>
                                                setAccountInfo({
                                                    ...accountInfo,
                                                    recipientPhone: e.target.value,
                                                    recipientPhoneWarning: '',
                                                })
                                            }
                                        />
                                        <div className='warningText text-danger text-start mb-3'>
                                            {accountInfo.recipientPhoneWarning}
                                        </div>
                                    </div>
                                </div>

                                <div className='d-flex flex-column'>
                                    <p className='text-start mb-2 ps-0'>{t('shipping_method')}</p>
                                    <div className='row'>
                                        {/* <div className="col-6 col-lg-4 ps-0">
												<button
													className="w-100 d-flex align-items-center bg-transparent border-1 rounded-3 formBtn active p-0 me-2 mb-3"
													style={{
														borderColor:
															data.deliveryMethod ===
															'0'
																? 'var(--primary)'
																: 'var(--grey2)',
													}}
												>
													<label
														htmlFor="pickOnSite"
														className="d-flex align-items-center px-2"
														style={{
															cursor: 'pointer',
														}}
													>
														現場領貨
														<input
															type="checkbox"
															id="pickOnSite"
															name="pickOnSite"
															className="form-check ms-2 mb-0"
															style={{
																cursor: 'pointer',
															}}
															checked={
																data.deliveryMethod ===
																'0'
															}
															onChange={(e) => {
																setData({
																	...data,
																	deliveryMethod:
																		'0',
																	recipientAddrWarning:
																		'',
																});
															}}
														/>
													</label>
												</button>
											</div> */}
                                        <div className='col-6 col-lg-4 ps-0'>
                                            <button
                                                className='w-100 d-flex align-items-center bg-transparent border-1 rounded-3 formBtn active px-0 mb-5'
                                                style={{
                                                    border:
                                                        accountInfo.deliveryMethod === '1'
                                                            ? '1px solid var(--primary)'
                                                            : '1px solid var(--grey2)',
                                                }}
                                            >
                                                <label
                                                    htmlFor='homeDelivery'
                                                    className='w-100 h-100 d-flex justify-content-between align-items-center'
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    <span>{t('home_delivery')}</span>
                                                    <input
                                                        type='checkbox'
                                                        id='homeDelivery'
                                                        name='homeDelivery'
                                                        className='form-check ms-2 mb-0 d-none'
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        checked={accountInfo.deliveryMethod === '1'}
                                                        onChange={(e) => {
                                                            setAccountInfo({
                                                                ...accountInfo,
                                                                deliveryMethod: '1',
                                                            });
                                                        }}
                                                    />
                                                    {accountInfo.deliveryMethod === '1' && <Icn_CheckBlueSmBtn />}
                                                </label>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className='row mb-0 mb-lg-4'>
                                    <div className='col-12 col-lg-4 ps-0'>
                                        {/* 國家 */}
                                        <select
                                            className={`form-select ${
                                                accountInfo.recipientCountryWarning ? 'border-danger' : ''
                                            }`}
                                            defaultValue={''}
                                            onChange={(e) => {
                                                setAccountInfo((prev) => {
                                                    return {
                                                        ...prev,
                                                        recipientCountry: e.target.value,
                                                        recipientCountryWarning: '',
                                                    };
                                                });
                                            }}
                                        >
                                            <option value='TW'>{t('taiwan')}</option>
                                        </select>

                                        <div className='warningText text-danger text-start mb-3'>
                                            {accountInfo.recipientCountryWarning}
                                        </div>
                                    </div>
                                </div>

                                <div className='row mb-0 mb-lg-4'>
                                    <div className='col-12 col-lg-4 ps-0'>
                                        {/* 選擇縣市 */}
                                        <select
                                            className={`form-select ${
                                                accountInfo.recipientCityWarning ? 'border-danger' : ''
                                            }`}
                                            defaultValue={''}
                                            onChange={(e) => handleCityChange(e)}
                                        >
                                            <option value='' disabled>
                                                {t('please_select')}
                                            </option>
                                            {cityList?.map((item) => (
                                                <option key={item.cityGuid} value={item.cityGuid}>
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                        <div className='warningText text-danger text-start mb-3'>
                                            {accountInfo.recipientCityWarning}
                                        </div>
                                    </div>
                                    <div className='col-12 col-lg-4 ps-0'>
                                        {/* 選擇鄉鎮市區 */}
                                        <select
                                            className={`form-select ${
                                                accountInfo.recipientDistrictWarning ? 'border-danger' : ''
                                            }`}
                                            defaultValue={''}
                                            onChange={(e) => {
                                                setAccountInfo((prev) => {
                                                    return {
                                                        ...prev,
                                                        recipientDistrict: e.target.value,
                                                        recipientDistrictWarning: '',
                                                    };
                                                });

                                                setIsNeedToPayDeliveryFee(
                                                    districtList?.find((item) => item.districtGuid === e.target.value)
                                                        .deliveryFee
                                                );
                                            }}
                                        >
                                            <option value=''>{t('please_select')}</option>
                                            {districtList?.map((item) => (
                                                <option key={item.districtGuid} value={item.districtGuid}>
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                        <div className='warningText text-danger text-start mb-3'>
                                            {accountInfo.recipientDistrictWarning}
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-12 col-lg-8 ps-0 mb-3 mb-sm-6 mb-lg-10'>
                                        <input
                                            type='address'
                                            id='recipientStreet'
                                            name='recipientStreet'
                                            className={`form-control ${
                                                accountInfo.recipientStreetWarning ? 'border-danger' : ''
                                            }`}
                                            value={accountInfo.recipientStreet || ''}
                                            onChange={(e) =>
                                                setAccountInfo({
                                                    ...accountInfo,
                                                    recipientStreet: e.target.value,
                                                    recipientStreetWarning: '',
                                                })
                                            }
                                        />
                                        <div className='warningText text-danger text-start mb-3'>
                                            {accountInfo.recipientStreetWarning}
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-12 col-lg-8 ps-0'>
                                        <label htmlFor='note' className=' w-100 text-start mb-2'>
                                            {t('order_notes')}
                                        </label>
                                        <textarea
                                            className='form-control mb-3'
                                            id='note'
                                            name='note'
                                            rows='7'
                                            value={accountInfo.note}
                                            onChange={(e) =>
                                                setAccountInfo({
                                                    ...accountInfo,
                                                    note: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='infoWrapper bg-white py-3 mb-4'>
                            <div className='infoTop d-flex align-items-center pb-3 mb-5 border-bottom border-grey'>
                                <h6 className='px-4 mb-0'>{t('invoice_information')}</h6>
                            </div>
                            <div className='infoBottom px-4 pb-2'>
                                <div className='row'>
                                    <div className='col-6 col-lg-4 ps-0'>
                                        <button
                                            className='w-100 d-flex align-items-center bg-transparent border-1 rounded-3 formBtn active p-0 mb-3'
                                            style={{
                                                border:
                                                    accountInfo.invoiceFormat === '0'
                                                        ? '1px solid var(--primary)'
                                                        : '1px solid var(--grey2)',
                                            }}
                                        >
                                            <label
                                                htmlFor='personalInvoice'
                                                className='w-100 h-100 d-flex justify-content-between align-items-center'
                                                style={{
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                <span>{t('personal_e_invoice')}</span>
                                                <input
                                                    type='checkbox'
                                                    id='personalInvoice'
                                                    name='personalInvoice'
                                                    className='form-check ms-2 mb-0 d-none'
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                    checked={accountInfo.invoiceFormat === '0'}
                                                    onChange={(e) => {
                                                        setAccountInfo({
                                                            ...accountInfo,
                                                            invoiceFormat: '0',

                                                            companyName: '',
                                                            companyNameWarning: '',
                                                            taxID: '',
                                                            taxIDWarning: '',
                                                            donateCode: '',
                                                            donateCodeWarning: '',
                                                            carrierId: '',
                                                            carrierIdWarning: '',
                                                        });
                                                    }}
                                                />
                                                {accountInfo.invoiceFormat === '0' && <Icn_CheckBlueSmBtn />}
                                            </label>
                                        </button>
                                    </div>
                                    <div className='col-6 col-lg-4 ps-0'>
                                        <button
                                            className='w-100 d-flex align-items-center bg-transparent border-1 rounded-3 formBtn active px-0 mb-3'
                                            style={{
                                                border:
                                                    accountInfo.invoiceFormat === '1'
                                                        ? '1px solid var(--primary)'
                                                        : '1px solid var(--grey2)',
                                            }}
                                        >
                                            <label
                                                htmlFor='companyInvoice'
                                                className='w-100 h-100 d-flex justify-content-between align-items-center'
                                                style={{
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                <span>
                                                    {t('company_e_invoice')}
                                                    <span className='smDisplayNone'>({t('vat_number')})</span>
                                                </span>

                                                <input
                                                    type='checkbox'
                                                    id='companyInvoice'
                                                    name='companyInvoice'
                                                    className='form-check ms-2 mb-0 d-none'
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                    checked={accountInfo.invoiceFormat === '1'}
                                                    onChange={(e) => {
                                                        setAccountInfo({
                                                            ...accountInfo,
                                                            invoiceFormat: '1',
                                                            donateCode: '',
                                                            donateCodeWarning: '',
                                                            carrierId: '',
                                                            carrierIdWarning: '',
                                                        });
                                                    }}
                                                />
                                                {accountInfo.invoiceFormat === '1' && <Icn_CheckBlueSmBtn />}
                                            </label>
                                        </button>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-6 col-lg-4 ps-0'>
                                        <button
                                            className='w-100 d-flex align-items-center bg-transparent border-1 rounded-3 formBtn active p-0 mb-3'
                                            style={{
                                                border:
                                                    accountInfo.invoiceFormat === '2'
                                                        ? '1px solid var(--primary)'
                                                        : '1px solid var(--grey2)',
                                            }}
                                        >
                                            <label
                                                htmlFor='donateInvoice'
                                                className='w-100 h-100 d-flex justify-content-between align-items-center'
                                                style={{
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                <span>{t('donation_invoice')}</span>
                                                <input
                                                    type='checkbox'
                                                    id='donateInvoice'
                                                    name='donateInvoice'
                                                    className='form-check ms-2 mb-0 d-none'
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                    checked={accountInfo.invoiceFormat === '2'}
                                                    onChange={(e) => {
                                                        setAccountInfo({
                                                            ...accountInfo,
                                                            invoiceFormat: '2',
                                                            companyName: '',
                                                            companyNameWarning: '',
                                                            taxID: '',
                                                            taxIDWarning: '',
                                                            carrierId: '',
                                                            carrierIdWarning: '',
                                                        });
                                                    }}
                                                />
                                                {accountInfo.invoiceFormat === '2' && <Icn_CheckBlueSmBtn />}
                                            </label>
                                        </button>
                                    </div>
                                    <div className='col-6 col-lg-4 ps-0'>
                                        <button
                                            className='w-100 d-flex align-items-center bg-transparent border-1 rounded-3 formBtn active px-0 mb-3'
                                            style={{
                                                border:
                                                    accountInfo.invoiceFormat === '3'
                                                        ? '1px solid var(--primary)'
                                                        : '1px solid var(--grey2)',
                                            }}
                                        >
                                            <label
                                                htmlFor='mobileCarrier'
                                                className='w-100 h-100 d-flex justify-content-between align-items-center'
                                                style={{
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                <span>{t('mobile_barcode_carrier')}</span>
                                                <input
                                                    type='checkbox'
                                                    id='mobileCarrier'
                                                    name='mobileCarrier'
                                                    className='form-check ms-2 mb-0 d-none'
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                    checked={accountInfo.invoiceFormat === '3'}
                                                    onChange={(e) => {
                                                        setAccountInfo({
                                                            ...accountInfo,
                                                            invoiceFormat: '3',
                                                            companyName: '',
                                                            companyNameWarning: '',
                                                            taxID: '',
                                                            taxIDWarning: '',
                                                            donateCode: '',
                                                            donateCodeWarning: '',
                                                        });
                                                    }}
                                                />
                                                {accountInfo.invoiceFormat === '3' && <Icn_CheckBlueSmBtn />}
                                            </label>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {accountInfo.invoiceFormat === '1' ? (
                                <div className='infoBottom border-top px-4 pt-3'>
                                    <div className='row ps-0'>
                                        <div className='col-12 col-lg-4 ps-0'>
                                            <label htmlFor='taxID' className='w-100 text-start mb-2'>
                                                <span>{t('vat_number')}</span>
                                            </label>
                                            <input
                                                type='text'
                                                id='taxID'
                                                name='taxID'
                                                className={`form-control ${
                                                    accountInfo.taxIDWarning ? 'border-danger' : ''
                                                }`}
                                                value={accountInfo.taxID || ''}
                                                onChange={(e) =>
                                                    setAccountInfo({
                                                        ...accountInfo,
                                                        taxID: e.target.value,
                                                        taxIDWarning: '',
                                                    })
                                                }
                                            />
                                            <div className='warningText text-danger text-start mb-3'>
                                                {accountInfo.taxIDWarning}
                                            </div>
                                        </div>
                                        <div className='col-12 col-lg-4 ps-0'>
                                            <label htmlFor='companyName' className='w-100 text-start mb-2'>
                                                {t('company_name')}
                                            </label>
                                            <input
                                                type='text'
                                                id='companyName'
                                                name='companyName'
                                                className={`form-control ${
                                                    accountInfo.companyNameWarning ? 'border-danger' : ''
                                                }`}
                                                value={accountInfo.companyName || ''}
                                                onChange={(e) =>
                                                    setAccountInfo({
                                                        ...accountInfo,
                                                        companyName: e.target.value,
                                                        companyNameWarning: '',
                                                    })
                                                }
                                            />
                                            <div className='warningText text-danger text-start mb-3'>
                                                {accountInfo.companyNameWarning}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : accountInfo.invoiceFormat === '2' ? (
                                <div className='infoBottom border-top px-4 pt-3'>
                                    <div className='row ps-0'>
                                        <div className='col-12 col-lg-8 ps-0'>
                                            <label htmlFor='donateCode' className='w-100 text-start mb-2'>
                                                <span>{t('donation_code')}</span>
                                            </label>

                                            <input
                                                type='text'
                                                className={`form-control w-100 ${
                                                    accountInfo.donateCodeWarning ? 'border-danger' : ''
                                                }`}
                                                list='donateCodeOptions' // 與 <datalist> 元素關聯
                                                placeholder={t('enter_text_to_search')}
                                                onChange={(e) => {
                                                    setAccountInfo({
                                                        ...accountInfo,
                                                        donateCode: e.target.value.split(' ')[0],
                                                        donateCodeWarning: '',
                                                    });
                                                }}
                                            />

                                            {/* 資料清單 */}
                                            <datalist id='donateCodeOptions'>
                                                {/* 渲染選項 */}
                                                {donateList.map((item) => (
                                                    <option
                                                        key={item.donateCode}
                                                        value={item.donateCode + ' ' + item.donateNm}
                                                    ></option>
                                                ))}
                                            </datalist>

                                            <div className='warningText text-danger text-start mb-3'>
                                                {accountInfo.donateCodeWarning}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : accountInfo.invoiceFormat === '3' ? (
                                <div className='infoBottom border-top px-4 pt-3'>
                                    <div className='row ps-0'>
                                        <div className='col-12 col-lg-4 ps-0'>
                                            <label htmlFor='carrierId' className='w-100 text-start mb-2'>
                                                <span>{t('mobile_barcode_carrier')}</span>
                                            </label>
                                            <input
                                                type='text'
                                                id='carrierId'
                                                name='carrierId'
                                                className={`form-control ${
                                                    accountInfo.carrierIdWarning ? 'border-danger' : ''
                                                }`}
                                                value={accountInfo.carrierId || ''}
                                                onChange={(e) =>
                                                    setAccountInfo({
                                                        ...accountInfo,
                                                        carrierId: e.target.value,
                                                        carrierIdWarning: '',
                                                    })
                                                }
                                            />
                                            <div className='warningText text-danger text-start mb-3'>
                                                {accountInfo.carrierIdWarning}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>

                        <div className='infoWrapper bg-white py-3 mb-4'>
                            <div className='infoTop d-flex align-items-center pb-3 mb-5 border-bottom border-grey'>
                                <h6 className='px-4 mb-0'>{t('payment_method')}</h6>
                            </div>
                            <div className='infoBottom px-4 pb-2'>
                                <div className='row'>
                                    <div className='col-6 col-lg-3 ps-0'>
                                        <button
                                            className='w-100 d-flex align-items-center bg-transparent border-1 rounded-3 formBtn active px-0 me-0 me-lg-3'
                                            style={{
                                                border:
                                                    accountInfo.paymentMethod === '0'
                                                        ? '1px solid var(--primary)'
                                                        : '1px solid var(--grey2)',
                                            }}
                                        >
                                            <label
                                                htmlFor='paymentCreditCard'
                                                className='w-100 h-100 d-flex justify-content-between align-items-center'
                                                style={{
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                <span>{t('credit_card_payment')}</span>
                                                <input
                                                    type='checkbox'
                                                    id='paymentCreditCard'
                                                    name='paymentCreditCard'
                                                    className='form-check ms-2 mb-0 d-none'
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                    checked={accountInfo.paymentMethod === '0'}
                                                    onChange={(e) => {
                                                        setAccountInfo({
                                                            ...accountInfo,
                                                            paymentMethod: '0',
                                                            bankAccount: '',
                                                            bankAccountWarning: '',
                                                        });
                                                    }}
                                                />
                                                {accountInfo.paymentMethod === '0' && <Icn_CheckBlueSmBtn />}
                                            </label>
                                        </button>
                                    </div>
                                    {/* <div className="col-6 col-lg-3 ps-0">
										<button
											className="w-100 d-flex align-items-center bg-transparent border-1 rounded-3 formBtn active px-0 mb-3 me-0 me-lg-3"
											style={{
												border:
													data.paymentMethod === '1'
														? '1px solid var(--primary)'
														: '1px solid var(--grey2)',
											}}
										>
											<label
												htmlFor="atmTransfer"
												className="w-100 h-100 d-flex justify-content-between align-items-center"
												style={{
													cursor: 'pointer',
												}}
											>
												<span>ATM轉帳</span>
												<input
													type="checkbox"
													id="atmTransfer"
													name="atmTransfer"
													className="form-check ms-2 mb-0 d-none"
													style={{
														cursor: 'pointer',
													}}
													checked={
														data.paymentMethod ===
														'1'
													}
													onChange={(e) => {
														setData({
															...data,
															paymentMethod: '1',
														});
													}}
												/>
												{data.paymentMethod === '1' && (
													<Icn_CheckBlueSmBtn />
												)}
											</label>
										</button>
									</div>
									<div className="col-6 col-lg-3 ps-0">
										<button
											className="w-100 d-flex align-items-center bg-transparent border-1 rounded-3 formBtn active px-0 mb-3 me-0 me-lg-3"
											style={{
												border:
													data.paymentMethod === '3'
														? '1px solid var(--primary)'
														: '1px solid var(--grey2)',
											}}
										>
											<label
												htmlFor="cashOnDelivery"
												className="w-100 h-100 d-flex justify-content-between align-items-center"
												style={{
													cursor: 'pointer',
												}}
											>
												<span>貨到付款</span>
												<input
													type="checkbox"
													id="cashOnDelivery"
													name="cashOnDelivery"
													className="form-check ms-2 mb-0 d-none"
													style={{
														cursor: 'pointer',
													}}
													checked={
														data.paymentMethod ===
														'3'
													}
													onChange={(e) => {
														setData({
															...data,
															paymentMethod: '3',
															bankAccount: '',
															bankAccountWarning:
																'',
														});
													}}
												/>
												{data.paymentMethod === '3' && (
													<Icn_CheckBlueSmBtn />
												)}
											</label>
										</button>
									</div> */}
                                </div>
                            </div>

                            {accountInfo.paymentMethod === '1' && (
                                <div className='infoBottom px-4 pt-3 border-top'>
                                    <div className='row ps-0'>
                                        <div className='col-12 col-lg-4 ps-0'>
                                            <label htmlFor='bankAccount' className='w-100 text-start mb-2'>
                                                {t('last_five_digits_of_bank_account')}
                                            </label>
                                            <input
                                                type='text'
                                                id='bankAccount'
                                                name='bankAccount'
                                                className={`form-control ${
                                                    accountInfo.bankAccountWarning ? 'border-danger' : ''
                                                }`}
                                                placeholder={t('please_fill_in_last_five_digits')}
                                                value={accountInfo.bankAccount || ''}
                                                onChange={(e) =>
                                                    setAccountInfo({
                                                        ...accountInfo,
                                                        bankAccount: e.target.value,
                                                        bankAccountWarning: '',
                                                    })
                                                }
                                            />
                                            <div className='warningText text-danger text-start mb-3'>
                                                {accountInfo.bankAccountWarning}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </section>

                    {/* 訂單區塊 */}
                    {location.state && (
                        <section className='orderSection d-flex flex-column align-items-center bg-white mb-14 mb-lg-0'>
                            <p className='title w-100 text-start px-4 py-3 mb-0'>{t('order_information')}</p>
                            <div className='content w-100 px-4 pt-4 pb-4 py-lg-3'>
                                <div className='priceWrap d-flex flex-column align-items-baseline pb-4 pb-lg-3 mb-4'>
                                    <div className='w-100 d-flex justify-content-between mb-1'>
                                        <span>{t('subtotal')}：</span>
                                        <span>
                                            NT$
                                            {location.state.price.netPrice.toLocaleString()}
                                        </span>
                                    </div>
                                    <div className='w-100 d-flex justify-content-between'>
                                        <span>{t('discount')}：</span>
                                        <span>NT${location.state.price.discount}</span>
                                    </div>
                                    <div className='w-100 d-flex justify-content-between'>
                                        <span>{t('shipping_fee')}：</span>
                                        <span>
                                            NT$
                                            {isNeedToPayDeliveryFee
                                                ? location.state.price.deliveryFee.toLocaleString()
                                                : 0}
                                        </span>
                                    </div>
                                </div>

                                <div className='totalPriceWrap d-flex flex-column align-items-baseline mb-6'>
                                    <div className='w-100 d-flex justify-content-between align-items-center'>
                                        <span>{t('total_amount')}</span>
                                        <span className='orderTotalPrice'>
                                            NT$
                                            {isNeedToPayDeliveryFee
                                                ? (
                                                      location.state.price.netPrice -
                                                      location.state.price.discount +
                                                      location.state.price.deliveryFee
                                                  ).toLocaleString()
                                                : (
                                                      location.state.price.netPrice - location.state.price.discount
                                                  ).toLocaleString()}
                                        </span>
                                    </div>
                                </div>

                                <div className='btnWrap d-flex flex-column w-100'>
                                    <button
                                        className='btn btn-primary w-100 d-flex align-items-center justify-content-center text-white text-center border-0'
                                        onClick={() => handleSubmit()}
                                    >
                                        {t('confirm_order')}
                                    </button>
                                </div>
                            </div>
                        </section>
                    )}
                </div>
            </div>
        </>
    );
};

export default CheckoutInfo;
