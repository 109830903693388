import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as dayjs from 'dayjs';

// `Page1` 組件用於編輯銷售活動的第一步，包含商品主檔的基本信息編輯。

// 主要功能：
// - 提供商品主檔的基本信息編輯區域，包括銷售商品分類、名稱（繁中、簡中、英文）、銷售起始日期、銷售結束日期、狀態等。
// - 提供刪除商品和取消操作的按鈕，僅在商品狀態為未啟用時顯示。
// - 提供保存、刪除商品和跳轉到下一步的按鈕，具體操作由父組件控制。
// - 利用 `Swal` 库實現刪除確認的彈窗效果。

// 注意：
// - 商品的編輯和保存邏輯未完全提供，可能需要進一步根據項目需求進行實現。
// - 使用了 `dayjs` 库來格式化日期，確保該庫的正確引入。
// - 表單控件的值和變更事件由 `handleSalesKitDataChange` 函數管理。
// - 商品狀態的選擇和判斷，以及相應按鈕的啟用和禁用，由狀態 `data.salesKit.originalStatus` 控制。

// 使用方式：
// - 作為 `SalSalesKitUpdate` 組件的子組件之一，通過父組件的 `setData`、`setPage`、`navigate` 等函數進行數據管理和頁面控制。
// - 通過 `data` 狀態獲取商品主檔的當前信息，通過 `handleSalesKitDataChange` 函數實現信息編輯。
// - 通過 `handleSubmit` 函數實現商品信息的保存。
// - 通過 `deleteSalesKitAlert` 函數實現商品刪除的確認和操作。
// - 通過 `handleNextPage` 函數實現跳轉到下一步頁面。

const Page1 = ({ salesKitName, salesKitGuid, setPage, data, setData, page, page1Data }) => {
    const navigate = useNavigate();
    const location = useLocation();

    // warning
    const [nameWarning, setNameWarning] = useState('');
    const [frequencyWarning, setFrequencyWarning] = useState('');

    // -- 確定刪除銷售 --
    const deleteSalesKitAlert = () => {
        Swal.fire({
            title: '確定要刪除此商品嗎?',
            icon: 'warning',
            showCancelButton: true,
            allowOutsideClick: false,

            // i18n
            confirmButtonText: '確定',
            cancelButtonText: '取消',
        }).then((result) => {
            if (result.isConfirmed) {
                handleDeleteSalesKit();
            }
        });
    };

    // 編輯銷售 PUT
    const handleSubmit = () => {
        console.log(data?.salesKit);

        axios.put(`web/SalSalesKit/updateSalesKit`, data).then((res) => navigate(-1));
    };

    // 刪除銷售 POST
    const handleDeleteSalesKit = () => {
        axios
            .post(`web/ActRegistration/deleteActivity/${salesKitGuid}`)
            .then((res) => {
                alert('刪除成功!');
                navigate(-1);
            })
            .catch((err) => console.log(err));
    };

    const handleSalesKitDataChange = (e) => {
        setData({
            ...data,
            salesKit: {
                ...data.salesKit,
                [e.target.name]: e.target.value,
            },
        });
    };

    const handleNextPage = () => {
        // alert('save data?.salesKit.data?.salesKit');
        setData((prev) => ({ ...prev, salesKit: data.salesKit }));
        setPage((prev) => prev + 1);
    };

    return (
        <>
            <section id='section-main'>
                <div id='container-activityUpdate'>
                    <div className='d-flex'>
                        <h5 className='fw-bold mb-4'>商品主檔 - {salesKitName}</h5>

                        {data?.salesKit?.originalStatus === '0' ? (
                            <>
                                <button
                                    className='btn btn-outline-danger px-2 me-2 ms-auto'
                                    onClick={deleteSalesKitAlert}
                                    style={{
                                        height: '32px',
                                        paddingBlock: 0,
                                        fontSize: '14px',
                                    }}
                                    disabled={!data}
                                >
                                    <svg
                                        id='icn_Trash'
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='16'
                                        height='16'
                                        viewBox='0 0 16 16'
                                    >
                                        <defs>
                                            <clipPath id='clipPath'>
                                                <rect
                                                    id='Rectangle_3041'
                                                    data-name='Rectangle 3041'
                                                    width='13.263'
                                                    height='14'
                                                    fill='#ea4132'
                                                />
                                            </clipPath>
                                        </defs>
                                        <g id='Group_6120' data-name='Group 6120' transform='translate(1 1)'>
                                            <g id='Group_6119' data-name='Group 6119' clipPath='url(#clipPath)'>
                                                <path
                                                    id='Path_1388'
                                                    data-name='Path 1388'
                                                    d='M10.316,2.21V1.473A1.474,1.474,0,0,0,8.842,0H4.421A1.473,1.473,0,0,0,2.948,1.473V2.21H.737a.737.737,0,1,0,0,1.474h.737v8.1A2.211,2.211,0,0,0,3.684,14h5.9a2.211,2.211,0,0,0,2.211-2.211v-8.1h.737a.737.737,0,0,0,0-1.474ZM8.842,1.473H4.421V2.21H8.842Zm1.474,2.211H2.948v8.1a.736.736,0,0,0,.736.737h5.9a.737.737,0,0,0,.737-.737Z'
                                                    fill='#ea4132'
                                                    fillRule='evenodd'
                                                />
                                                <rect
                                                    id='Rectangle_3039'
                                                    data-name='Rectangle 3039'
                                                    width='1.474'
                                                    height='5.895'
                                                    transform='translate(4.421 5.158)'
                                                    fill='#ea4132'
                                                />
                                                <rect
                                                    id='Rectangle_3040'
                                                    data-name='Rectangle 3040'
                                                    width='1.474'
                                                    height='5.895'
                                                    transform='translate(7.368 5.158)'
                                                    fill='#ea4132'
                                                />
                                            </g>
                                        </g>
                                        <rect
                                            id='Rectangle_3042'
                                            data-name='Rectangle 3042'
                                            width='16'
                                            height='16'
                                            fill='none'
                                        />
                                    </svg>
                                </button>
                                <div
                                    className='me-2'
                                    style={{
                                        height: '32px',
                                        borderLeft: '1px solid #DADADA',
                                    }}
                                ></div>
                                <button
                                    className='btnCancel btn btn-outline-primary px-4 me-2'
                                    style={{
                                        height: '32px',
                                        paddingBlock: 0,
                                        fontSize: '14px',
                                    }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate(-1);
                                    }}
                                    disabled={!data}
                                >
                                    取消
                                </button>
                                <button
                                    type='button'
                                    className='btn btn-primary text-white px-4 me-2'
                                    style={{
                                        height: '32px',
                                        paddingBlock: 0,
                                        fontSize: '14px',
                                    }}
                                    onClick={handleSubmit}
                                    disabled={!data}
                                >
                                    儲存
                                </button>
                                <button
                                    type='button'
                                    className='btn btn-primary text-white px-4'
                                    style={{
                                        height: '32px',
                                        paddingBlock: 0,
                                        fontSize: '14px',
                                    }}
                                    onClick={handleNextPage}
                                    disabled={!data}
                                >
                                    下一步
                                </button>
                            </>
                        ) : (
                            <>
                                <button
                                    className='btnCancel btn btn-outline-primary px-4 me-2 ms-auto'
                                    style={{
                                        height: '32px',
                                        paddingBlock: 0,
                                        fontSize: '14px',
                                    }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate(-1);
                                    }}
                                    disabled={!data}
                                >
                                    取消
                                </button>
                                <button
                                    type='button'
                                    className='btn btn-primary text-white px-4 me-2'
                                    style={{
                                        height: '32px',
                                        paddingBlock: 0,
                                        fontSize: '14px',
                                    }}
                                    onClick={handleSubmit}
                                    disabled={!data}
                                >
                                    儲存
                                </button>
                                <button
                                    type='button'
                                    className='btn btn-primary text-white px-4'
                                    style={{
                                        height: '32px',
                                        paddingBlock: 0,
                                        fontSize: '14px',
                                    }}
                                    onClick={handleNextPage}
                                    disabled={!data}
                                >
                                    下一步
                                </button>
                            </>
                        )}
                    </div>

                    <div className='infoWrapper bg-white p-4 rounded-4 mb-3'>
                        <div className='infoTop d-flex align-items-center mb-3 '>
                            <h6 className='fw-bold'>商品管理</h6>
                        </div>
                        <div className='infoBottom row'>
                            <div className='col-4'>
                                <label htmlFor='salesKitClassGuid' className='fontSize75Rem mb-2'>
                                    銷售商品分類
                                </label>
                                <select
                                    className='form-control mb-3 w-100'
                                    name='salesKitClassGuid'
                                    id='salesKitClassGuid'
                                    value={data?.salesKit?.salesKitClassGuid || ''}
                                    onChange={handleSalesKitDataChange}
                                    required
                                >
                                    {data?.salesKitClassList.map((kitClass) => (
                                        <option value={kitClass.salesKitClassGuid} key={kitClass.salesKitClassGuid}>
                                            {kitClass.name_01}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className='infoBottom row'>
                            <div className='col-4'>
                                <label htmlFor='name_01' className='fontSize75Rem mb-2'>
                                    名稱(繁中)
                                </label>
                                <input
                                    id='name_01'
                                    name='name_01'
                                    className='form-control mb-3'
                                    type='text'
                                    value={data?.salesKit?.name_01 || ''}
                                    onChange={handleSalesKitDataChange}
                                />
                                <span className='text-danger'>{frequencyWarning}</span>
                            </div>
                            <div className='col-4'>
                                <label htmlFor='name_02' className='fontSize75Rem mb-2'>
                                    名稱(簡中)
                                </label>
                                <input
                                    id='name_02'
                                    name='name_02'
                                    className='form-control mb-3'
                                    type='text'
                                    value={data?.salesKit?.name_02 || ''}
                                    onChange={handleSalesKitDataChange}
                                />
                                <span className='text-danger'>{frequencyWarning}</span>
                            </div>
                            <div className='col-4'>
                                <label htmlFor='name_03' className='fontSize75Rem mb-2'>
                                    名稱(英文)
                                </label>
                                <input
                                    id='name_03'
                                    name='name_03'
                                    className='form-control mb-3'
                                    value={data?.salesKit?.name_03 || ''}
                                    onChange={handleSalesKitDataChange}
                                />
                                <span className='text-danger'>{nameWarning}</span>
                            </div>
                        </div>
                        <div className='infoBottom row'>
                            <div className='col-4'>
                                <label htmlFor='familyMax' className='fontSize75Rem mb-2'>
                                    銷售起始日期
                                </label>
                                <input
                                    id='startDate'
                                    name='startDate'
                                    className='form-control mb-3'
                                    type='datetime-local'
                                    step='1'
                                    value={
                                        data?.salesKit?.startDate
                                            ? dayjs(data?.salesKit?.startDate).format('YYYY-MM-DD HH:mm:ss')
                                            : ''
                                    }
                                    onChange={handleSalesKitDataChange}
                                />
                                <span className='text-danger'>{frequencyWarning}</span>
                            </div>
                            <div className='col-4'>
                                <label htmlFor='familyMax' className='fontSize75Rem mb-2'>
                                    銷售結束日期
                                </label>
                                <input
                                    id='endDate'
                                    name='endDate'
                                    className='form-control mb-3'
                                    type='datetime-local'
                                    step='1'
                                    value={
                                        data?.salesKit?.endDate
                                            ? dayjs(data?.salesKit?.endDate).format('YYYY-MM-DD HH:mm:ss')
                                            : ''
                                    }
                                    onChange={handleSalesKitDataChange}
                                />
                                <span className='text-danger'>{frequencyWarning}</span>
                            </div>
                        </div>
                        <div className='infoBottom row'>
                            <div className='col-4'>
                                <label htmlFor='gender' className='fontSize75Rem mb-2'>
                                    狀態
                                </label>
                                <select
                                    className='form-control mb-3 w-100'
                                    name='status'
                                    id='status'
                                    value={data?.salesKit?.status || ''}
                                    onChange={handleSalesKitDataChange}
                                    required
                                >
                                    {data?.salesKit?.originalStatus === '0' && <option value='0'>"0"：未启用</option>}
                                    <option value='1'>"1"：启用中</option>
                                    <option value='2'>"2"：停用中</option>
                                </select>
                            </div>
                            <div className='col-4'>
                                <label htmlFor='familyMax' className='fontSize75Rem mb-2'>
                                    狀態變更時間
                                </label>
                                <input
                                    id='endDate'
                                    name='endDate'
                                    className='form-control mb-3'
                                    type='datetime-local'
                                    step='1'
                                    disabled
                                    value={
                                        data?.salesKit?.endDate
                                            ? dayjs(data?.salesKit?.endDate).format('YYYY-MM-DD HH:mm:ss')
                                            : ''
                                    }
                                    onChange={handleSalesKitDataChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Page1;
