import { Navigate, Outlet } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import useRefreshToken from '../hooks/useRefreshToken';

export default function RequireAuth() {
    const { auth } = useAuth();
    const refresh = useRefreshToken();
    const accessToken = auth?.accessToken;
    const isTriedGetAccessToken = auth?.isTriedGetAccessToken;

    if (!accessToken && !isTriedGetAccessToken) {
        refresh();
    } else if (!accessToken && isTriedGetAccessToken) {
        return <Navigate to='/notFoundPage' />;
    }

    return auth?.accessToken ? <Outlet /> : null;
}
