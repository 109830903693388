import './onlineStore.scss';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Carousel } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ProductCard from './ProductCard.jsx';
import { ReactComponent as Icn_Filter } from '../../../assets/images/icn_Filter.svg';
import { ReactComponent as Icn_Cancel } from '../../../assets/images/icn_Cancel.svg';
import { ReactComponent as Icn_chevronRight } from '../../../assets/images/icn_chevron-right.svg';
import useAxios from '../../../hooks/useAxios';

function OnlineStore() {
    const { t } = useTranslation('onlineStore'); // i18n
    const { operation } = useAxios();

    const [carouselIndex, setCarouselIndex] = useState(0);
    const [mobileFilter, setMobileFilter] = useState(false);
    const [pdList, setPdList] = useState([]);

    const handleSelect = (selectedIndex, e) => {
        setCarouselIndex(selectedIndex);
    };

    // API
    const getSalesKitList = async () => {
        const { success, response, error } = await operation(
            {
                url: '/salSalesShop/getSalesKitList',
                method: 'POST',
                data: {
                    page: 1,
                    pageSize: 500,
                    sort: ['DisplayOrder'],
                },
            },
            'getSalesKitList'
        );
        if (success) {
            setPdList(response.data.data);
        }
        if (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getSalesKitList();
    }, []);

    return (
        <div className='container-fluid position-relative p-0 text-center bg' id='container-onlineStore'>
            <article className='topTagArea d-flex flex-column align-items-center px-5 mx-auto'>
                {/* <div className='wrapper'>
                    <section className=' py-4 row align-items-center'>
                        <div className='col buttonArea p-0'>
                            <div className='me-lg-7 text-primary onlineShopTitleSectionLink'>
                                {t('online_store')}
                                網路商城
                            </div>
                            <div
								className="physicalStoreTitleSectionLink"
								onClick={() => {}}
							>
								銷售據點
							</div>
                        </div>
                    </section>
                </div> */}
            </article>

            <div className='w-100 d-flex flex-column align-items-center'>
                {/* 輪播圖 */}
                <section className='carousel-section w-100'>
                    <Carousel activeIndex={carouselIndex} onSelect={handleSelect} variant={'dark'}>
                        <Carousel.Item interval={99999999}>
                            {/* <picture>
                                <source
                                    className='d-block w-100'
                                    media='(min-width: 576px)'
                                    srcSet='./images/purchaseCenter/onlineStore/onlineStore_slide_1_2x.jpg'
                                    alt='First slide'
                                />
                                <img
                                    className='d-block w-100'
                                    src='./images/purchaseCenter/onlineStore/onlineStore_slide_1_1x.jpg'
                                    alt='First slide'
                                />
                            </picture> */}

                            <Carousel.Caption>
                                <h1 className='text-white mb-2 mb-lg-5'>{t('synerter_ping_pong_robot')}</h1>
                                <h2 className='text-white mb-3 mb-lg-4'>{t('ai_algorithm_imported_processor')}</h2>
                                <p className='text-white mb-3 mb-lg-6'>
                                    {t('flagship_performance')}
                                    <span className='d-none d-lg-inline px-lg-1'>｜</span>
                                    <br className=' d-lg-none' />
                                    {t('high_precision_brushless_dc_motor')}
                                </p>
                                <div
                                    className='carouselBtnWrap d-flex flex-column-reverse flex-md-row  align-items-center align-items-sm-start
									justify-content-md-start justify-content-lg-start'
                                    style={{ gap: '16px' }}
                                >
                                    <Link className='btn-size-m' to='/productcenter/productfeature'>
                                        <button type='button' className='btn btn-size-m btn-hover-white rounded-pill '>
                                            {t('learn_more')}
                                        </button>
                                    </Link>
                                </div>
                            </Carousel.Caption>
                        </Carousel.Item>
                        {/* <Carousel.Item interval={99999999}>
							<picture>
								<source
									className="d-block w-100"
									media="(min-width: 992px)"
									srcSet="./images/home/homepage_slide_2_2x.png"
									alt="Second slide"
								/>
								<img
									className="d-block w-100"
									src="./images/home/homepage_slide_2_1x.png"
									alt="Second slide"
								/>
							</picture>

							<Carousel.Caption>
								<h1 className="text-white mb-2 mb-lg-5">
									{t('synerter_ping_pong_robot')}
								</h1>
								<h2 className="text-white mb-3 mb-lg-4">
									{t('flexible_variation_start_training')}
								</h2>
								<p className="text-white mb-3 mb-lg-6">
									{t('head_and_stand_description')}
									<span className="d-none d-lg-inline px-lg-1">
										｜
									</span>
									<br className=" d-lg-none" />
									{t('automatic_circulation_no_ball_jamming')}
								</p>
								<div
									className="carouselBtnWrap d-flex flex-column-reverse flex-md-row  align-items-center justify-content-md-center justify-content-lg-start"
									style={{ gap: '16px' }}
								>
									<Link className=" btn-size-m" to="/explore">
										<button
											type="button"
											className="btn btn-size-m btn-hover-white  rounded-pill "
										>
											{t('learn_more')}
										</button>
									</Link>
									<Link
										className=" btn-size-m"
										to="/support/contactus"
									>
										<button
											type="button"
											className="btn btn-size-m btn-color-main rounded-pill text-white"
										>
											{t('buy_now')}
										</button>
									</Link>
								</div>
							</Carousel.Caption>
						</Carousel.Item>
						<Carousel.Item>
							<picture>
								<source
									className="d-block w-100"
									media="(min-width: 992px)"
									srcSet="./images/home/homepage_slide_3_2x.png"
									alt="Third slide"
								/>
								<img
									className="d-block w-100"
									src="./images/home/homepage_slide_3_1x.png"
									alt="Third slide"
								/>
							</picture>

							<Carousel.Caption>
								<h1 className="mb-2 mb-lg-5">
									{t('synerter_ping_pong_robot')}
								</h1>
								<h2 className="mb-3 mb-lg-4">
									{t('app_control_efficient_extraordinary')}
								</h2>
								<p className="mb-3 mb-lg-6">
									{t(
										'customizable_ball_trajectory_parameters'
									)}
									<span className="d-none d-lg-inline px-lg-1">
										｜
									</span>
									<br className=" d-lg-none" />
									{t('cloud_storage_on-demand_access')}
								</p>
								<div
									className="carouselBtnWrap d-flex flex-column-reverse flex-md-row  align-items-center justify-content-md-center justify-content-lg-start"
									style={{ gap: '16px' }}
								>
									<Link
										className=" btn-size-m"
										to="/app/download"
									>
										<button
											type="button"
											className="btn btn-size-m btn-color-outline-transparent-auxiliary  rounded-pill"
										>
											{t('learn_more')}
										</button>
									</Link>
									<Link
										className=" btn-size-m"
										to="/support/contactus"
									>
										<button
											type="button"
											className="btn btn-size-m btn-color-main rounded-pill text-white"
										>
											{t('buy_now')}
										</button>
									</Link>
								</div>
							</Carousel.Caption>
						</Carousel.Item> */}
                    </Carousel>
                </section>

                {/* 主要區塊 */}
                <div className='mainArea wrapper d-flex flex-column align-items-center px-4 px-sm-5'>
                    {/* 麵包屑 */}
                    <div className='breadCrumb w-100 text-start pt-4 pt-sm-6 pb-3 pb-sm-9'>
                        <div>
                            <Link to='/'>{t('home')}</Link>
                            <span className='mx-2'>
                                <Icn_chevronRight />
                            </span>
                            <Link to=''>{t('online_store')}</Link>
                        </div>
                    </div>

                    {/* 內容區塊 */}
                    <section className='contentArea w-100 d-flex flex-column flex-sm-row'>
                        {/* 篩選區塊  目前先d-none，拔掉d-none即可使用*/}
                        <div className='filterArea d-flex flex-column align-items-start d-none'>
                            <button className='fontSize125Rem border-0 bg-transparent ps-0 mb-3'>
                                {t('latest_products')}
                                {/* 最新商品 */}
                            </button>

                            {/* 顯示商品數量 */}
                            <div className='d-flex justify-content-between align-items-center w-100'>
                                <span className='fontSize875Rem text-grey3 mb-4 mb-sm-3'>{pdList.length} 項商品</span>

                                {/* 手機板顯示 */}
                                <div className='d-block d-sm-none filterBtnArea mb-4'>
                                    <button
                                        type='button'
                                        className='border-0 bg-transparent'
                                        onClick={() => setMobileFilter((prev) => !prev)}
                                    >
                                        <Icn_Filter />
                                    </button>
                                </div>
                            </div>

                            {/* 手機板隱藏 */}
                            <div className='d-none d-sm-block'>
                                <p className='text-start mb-2'>{t('filter_type')}</p>
                                {/* 顯示篩選類型 */}
                                <div className='checkboxArea mt-1'>
                                    <div className='form-check mb-2'>
                                        <input
                                            className='form-check-input cursorPointer'
                                            type='checkbox'
                                            value=''
                                            id='flexCheckDefault'
                                        />
                                        <label className='form-check-label cursorPointer' htmlFor='flexCheckDefault'>
                                            發球機器人
                                        </label>
                                    </div>
                                    <div className='form-check mb-2'>
                                        <input
                                            className='form-check-input cursorPointer'
                                            type='checkbox'
                                            value=''
                                            id='flexCheckChecked'
                                        />
                                        <label className='form-check-label cursorPointer' htmlFor='flexCheckChecked'>
                                            40+乒乓球
                                        </label>
                                    </div>
                                </div>
                            </div>

                            {/* 手機板顯示 filter框 */}
                            <div className={`mobileFilterArea  ${mobileFilter ? 'h-100' : 'd-none'}  px-4`}>
                                {/* 顯示商品數量 */}
                                <div className='d-flex justify-content-between align-items-center w-100 mt-3'>
                                    <span className='fontSize875Rem text-grey3 mb-2'>{pdList.length} 項商品</span>

                                    {/* 手機板顯示 */}
                                    <div className='d-block d-sm-none filterBtnArea mb-2'>
                                        <button
                                            type='button'
                                            className='border-0 bg-transparent'
                                            onClick={() => setMobileFilter((prev) => !prev)}
                                        >
                                            <Icn_Cancel
                                                style={{
                                                    width: '24px',
                                                    height: '24px',
                                                }}
                                            />
                                        </button>
                                    </div>
                                </div>

                                <div className='py-5 mb-4 border-top border-bottom'>
                                    <p className='text-start mb-2'>{t('filter_type')}</p>
                                    {/* 顯示篩選類型 */}
                                    <div className='checkboxArea mt-1'>
                                        <div className='form-check text-start mb-2'>
                                            <input
                                                className='form-check-input cursorPointer'
                                                type='checkbox'
                                                value=''
                                                id='flexCheckDefault'
                                            />
                                            <label
                                                className='form-check-label cursorPointer'
                                                htmlFor='flexCheckDefault'
                                            >
                                                發球機器人
                                            </label>
                                        </div>
                                        <div className='form-check text-start mb-2'>
                                            <input
                                                className='form-check-input cursorPointer'
                                                type='checkbox'
                                                value=''
                                                id='flexCheckChecked'
                                            />
                                            <label
                                                className='form-check-label cursorPointer'
                                                htmlFor='flexCheckChecked'
                                            >
                                                40+乒乓球
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                {/* 確認按鈕 */}
                                <button
                                    className='btn btn-primary addCartBtn w-100 d-flex align-items-center justify-content-center text-white text-center border-0 mt-auto'
                                    onClick={() => setMobileFilter(false)}
                                >
                                    {t('apply_conditions')}
                                </button>
                            </div>
                        </div>

                        {/* 產品區塊 */}
                        {pdList.length ? (
                            <div className='productArea w-100 px-sm-3'>
                                <div className='container d-flex justify-content-center justify-content-sm-start flex-wrap px-0'>
                                    {pdList.map((item, index) => (
                                        <ProductCard key={index} pd={item} />
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <div className='productArea w-100 d-flex justify-content-center align-items-center px-sm-3'>
                                {t('no_matching_products')}
                            </div>
                        )}
                    </section>
                </div>
            </div>
        </div>
    );
}

export default OnlineStore;
