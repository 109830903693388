import useAxios from '../../../hooks/useAxios';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './actActivity.scss';

export default function ActActivity() {
    const { operation } = useAxios();

    const [isGetActivityList, setIsGetActivityList] = useState(false);
    const [activityList, setActivityList] = useState('');

    const navigate = useNavigate();

    const statusList = {
        0: '"0"：未啟用',
        1: '"1"：啟用中',
        2: '"2"：鎖定（停用）',
        4: '"4"：刪除 ',
    };

    const getActivityList = async () => {
        const { success, response, error } = await operation(
            {
                url: '/ActRegistration/getActivityList',
            },
            'getActivityList'
        );
        if (success) {
            // console.log(response.data.activityList);
            setActivityList(response.data.activityList);
            setIsGetActivityList(true);
        } else if (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        const initialRequestBody = {
            page: 1,
            pageSize: 10000,
            Sort: ['startDate desc'],
        };
        getActivityList();
    }, []);

    return (
        <section id='section-main'>
            <div id='container-userDrillAll'>
                <h5 className='fw-bold mb-4'>活動主檔</h5>

                <div className='searchTableWrapper bg-white p-4 rounded-4'>
                    <div className='searchTableTop d-flex align-items-center mb-3 '>
                        <h6 className='fw-bold'>活動日程查询</h6>
                        <button
                            className='btn btn-primary text-white ms-auto me-2'
                            onClick={() => {
                                navigate('./create');
                            }}
                        >
                            <svg
                                id='icn_Create'
                                className='me-2 mb-1'
                                viewBox='0 0 12 12'
                                width='12'
                                height='12'
                                fill='#fff'
                            >
                                <defs> </defs>
                                <path
                                    className='cls-1'
                                    id='_Color'
                                    d='M 12 6.857 H 6.857 V 12 H 5.143 V 6.857 H 0 V 5.143 H 5.143 V 0 H 6.857 V 5.143 H 12 Z'
                                    data-name=' ↳Color'
                                ></path>
                            </svg>
                            <span>新增活動</span>
                        </button>
                    </div>
                    <div className='searchTableBottom'>
                        <section>
                            <table className='UserDrillListTable table table-hover text-center' id='table-drill'>
                                <thead>
                                    <tr className='bg-background'>
                                        <th scope='col' className='text-grey4 border-0'>
                                            順序<span></span>
                                        </th>
                                        <th scope='col' className='text-grey4 border-0'>
                                            活動代碼<span></span>
                                        </th>
                                        <th scope='col' className='text-grey4 border-0'>
                                            活動名稱<span></span>
                                        </th>
                                        <th scope='col' className='text-grey4 border-0'>
                                            攤位號碼<span></span>
                                        </th>
                                        <th scope='col' className='text-grey4 border-0'>
                                            攤位名稱<span></span>
                                        </th>
                                        <th scope='col' className='text-grey4 border-0'>
                                            活動起始日期<span></span>
                                        </th>
                                        <th scope='col' className='text-grey4 border-0'>
                                            活動結束日期<span></span>
                                        </th>
                                        <th scope='col' className='text-grey4 border-0'>
                                            活動天數<span></span>
                                        </th>
                                        <th scope='col' className='text-grey4 border-0'>
                                            註記<span></span>
                                        </th>
                                        <th scope='col' className='text-grey4 border-0'>
                                            狀態<span></span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className='border-top-0'>
                                    {isGetActivityList &&
                                        (activityList.length !== 0 ? (
                                            activityList.map((activity, index) => {
                                                // console.log(activity?.fullname);
                                                return (
                                                    <tr
                                                        id={activity?.activityGuid}
                                                        key={activity?.activityGuid}
                                                        onClick={() =>
                                                            navigate(`${activity?.activityGuid}`, {
                                                                state: {
                                                                    activity: activity,
                                                                },
                                                            })
                                                        }
                                                    >
                                                        <td>{index + 1}</td>
                                                        <td>{activity?.code}</td>
                                                        <td>{activity?.name}</td>
                                                        <td>{activity?.boothNumber}</td>
                                                        <td>{activity?.boothName}</td>
                                                        <td>
                                                            {activity.startDate
                                                                ? dayjs(activity.startDate).format('YYYY-MM-DD')
                                                                : ''}
                                                        </td>
                                                        <td>
                                                            {activity.endDate
                                                                ? dayjs(activity.endDate).format('YYYY-MM-DD')
                                                                : ''}
                                                        </td>
                                                        <td>{activity?.days}</td>
                                                        <td>{activity?.note}</td>
                                                        <td>{statusList[activity?.status]}</td>
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <tr>
                                                <td colSpan='7'>无用戶纪录</td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>

                            {/*讀取畫面*/}
                            <div className={`text-center loadingBox ${isGetActivityList && 'd-none'}`} id='loadingBox'>
                                <h6 className='sr-only'>请查询</h6>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </section>
    );
}
