import { useState, useEffect } from 'react';
import useAxios from '../../../hooks/useAxios';
import { useTranslation } from 'react-i18next';
import TabPage from '../../../components/TabPage/TabPage';
import TabContent from './TabContent';
import styled from 'styled-components';

// #region styled-components
const OrderContainer = styled.article`
    max-width: 1216px;
    width: 100%;
    margin: auto;
`;

const Wrapper = styled.div`
    min-height: 752px;
    border: 1px solid #dadada;
    border-radius: 30px;

    @media only screen and (max-width: 1020px) {
        border: none;
    }
`;

const PageTitle = styled.div`
    font-size: 20px;
    padding: 2rem;

    @media only screen and (max-width: 458px) {
        padding: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`;
// #endregion

export default function OrderOverview() {
    const { t } = useTranslation(['order']); // i18n
    const { operation } = useAxios();

    const [isGettingList, setIsGettingList] = useState(true);
    const [allList, setAllList] = useState([]);

    // pagination
    const [paginationData, setPaginationData] = useState({});
    const sortBy = ['orderDate desc'];
    const [advancedSearchDataList, setAdvancedSearchDataList] = useState([]);

    // 判斷裝置類型
    function detectDeviceType() {
        const width = window.innerWidth;

        if (width <= 576) {
            // 寬度小於等於768px，視為手機板
            return 'mb';
        } else if (width <= 992) {
            // 寬度小於等於1024px，視為平板
            return 'pad';
        } else {
            // 寬度大於1024px，視為電腦版
            return 'pc';
        }
    }

    const deviceType = detectDeviceType();

    const handleSearch = async (val) => {
        if (!val) return getList();

        console.log(val);

        const newAdvancedSearchData = [
            {
                searchLogic: '',
                searchFilter: 'salesKitCode',
                searchOperator: 'CONTAINS',
                searchValue: val,
            },
            {
                searchLogic: 'OR',
                searchFilter: 'name_01',
                searchOperator: 'CONTAINS',
                searchValue: val,
            },
            {
                searchLogic: 'OR',
                searchFilter: 'name_02',
                searchOperator: 'CONTAINS',
                searchValue: val,
            },
            {
                searchLogic: 'OR',
                searchFilter: 'name_03',
                searchOperator: 'CONTAINS',
                searchValue: val,
            },
        ];

        await setAdvancedSearchDataList(newAdvancedSearchData);

        getList(newAdvancedSearchData);
    };

    const tabNavList = [
        t('all'),
        t('pending_payment'),
        t('pending_shipment'),
        t('pending_receipt'),
        t('completed'),
        t('incomplete'),
    ];

    const orderStatus = {
        0: t('pending_payment'),
        1: '待理貨',
        2: t('pending_shipment'),
        3: t('pending_receipt'),
        4: t('completed'),
        5: '待退貨',
        6: '待換貨',
        9: t('incomplete'),
    };

    //#region [function]
    const getList = async (advancedSearchDataList = null, pageSize = 10, sort = sortBy, pageNumber = 1) => {
        const { success, response, error } = await operation(
            {
                url: '/salSalesOrder/getSalesOrderList',
                method: 'POST',
                data: {
                    advancedSearchDataList,
                    pageSize,
                    sort,
                    page: pageNumber,
                },
            },
            'getList'
        );
        if (success) {
            console.log(response.data.data);
            // pagination
            const { numberOfData } = response.data;
            const totalPage = Math.ceil(numberOfData / pageSize);
            setPaginationData({
                ...paginationData,
                numberOfData: numberOfData,
                totalPage: totalPage,
                pageNumber: pageNumber,
            });
            deviceType === 'pc' ? setAllList(response.data.data) : setAllList([...allList, ...response.data.data]);
            setIsGettingList(false);
        } else if (error) {
            console.log(error);
        }
    };

    //#endregion

    useEffect(() => {
        getList();
        // sortTable(5)
    }, []);
    // console.log(paginationData.numberOfData);
    const tabContent = [
        <TabContent
            t={t}
            list={allList}
            orderStatus={orderStatus}
            handleSearch={handleSearch}
            deviceType={deviceType}
            paginationData={paginationData}
            advancedSearchDataList={advancedSearchDataList}
            getList={getList}
            sortBy={sortBy}
            setPaginationData={setPaginationData}
        />,

        // "0": 待付款
        <TabContent
            t={t}
            list={allList?.filter((el) => el.status === '0')}
            orderStatus={orderStatus}
            handleSearch={handleSearch}
            deviceType={deviceType}
            paginationData={paginationData}
            advancedSearchDataList={advancedSearchDataList}
            getList={getList}
            sortBy={sortBy}
            setPaginationData={setPaginationData}
        />,

        // "1": 待出貨, "2": 待出貨 -> 都顯示 "待出貨"
        <TabContent
            t={t}
            list={allList?.filter((el) => el.status === '1' || el.status === '2')}
            orderStatus={orderStatus}
            handleSearch={handleSearch}
            deviceType={deviceType}
            paginationData={paginationData}
            advancedSearchDataList={advancedSearchDataList}
            getList={getList}
            sortBy={sortBy}
            setPaginationData={setPaginationData}
        />,

        // "3": 待收貨
        <TabContent
            t={t}
            list={allList?.filter((el) => el.status === '3')}
            orderStatus={orderStatus}
            handleSearch={handleSearch}
            deviceType={deviceType}
            paginationData={paginationData}
            advancedSearchDataList={advancedSearchDataList}
            getList={getList}
            sortBy={sortBy}
            setPaginationData={setPaginationData}
        />,

        // "4": 訂單完成 -> 顯示 "已完成"
        <TabContent
            t={t}
            list={allList?.filter((el) => el.status === '4')}
            orderStatus={orderStatus}
            handleSearch={handleSearch}
            deviceType={deviceType}
            paginationData={paginationData}
            advancedSearchDataList={advancedSearchDataList}
            getList={getList}
            sortBy={sortBy}
            setPaginationData={setPaginationData}
        />,

        // "9": 訂單不成立
        <TabContent
            t={t}
            list={allList?.filter((el) => el.status === '9')}
            orderStatus={orderStatus}
            handleSearch={handleSearch}
            deviceType={deviceType}
            paginationData={paginationData}
            advancedSearchDataList={advancedSearchDataList}
            getList={getList}
            sortBy={sortBy}
            setPaginationData={setPaginationData}
        />,
    ];
    console.log(tabContent);
    return (
        <OrderContainer>
            <Wrapper>
                <section>
                    <PageTitle>{t('order_overview')}</PageTitle>
                    {!isGettingList && (
                        <TabPage
                            t={t}
                            tabNavList={tabNavList}
                            // tabContent={
                            //   isGettingList ? (
                            //     <h1 className="text-center">loading...</h1>
                            //   ) : (
                            //     tabContent
                            //   )
                            // }
                            deviceType={deviceType}
                            paginationData={paginationData}
                            setPaginationData={setPaginationData}
                            getList={getList}
                            sortBy={sortBy}
                            advancedSearchDataList={advancedSearchDataList}
                            tabContent={tabContent}
                            defaultPage={0}
                        />
                    )}
                </section>
                {/* {deviceType === 'pc' && (
                    <Pagination
                        t={t}
                        paginationData={paginationData}
                        setPaginationData={setPaginationData}
                        fetchData={getList}
                        sortBy={sortBy}
                        advancedSearchDataList={advancedSearchDataList}
                    />
                )} */}
            </Wrapper>
        </OrderContainer>
    );
}
