import './presetDrillCategoryItemUpdateDetail.scss';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams, useLocation } from 'react-router-dom';
import * as dayjs from 'dayjs';
import useAxios from '../../../hooks/useAxios';

function PresetDrillCategoryItemUpdateDetail() {
    //#region States initializations
    const navigate = useNavigate();
    const location = useLocation();
    const { operation } = useAxios();
    const paramsCode = useParams('categoryItemGuid');
    const categoryGuid = location.state.categoryGuid;

    const [data, setData] = useState({
        displayOrder: '',
        name_ZH: '',
        name_ZF: '',
        name_EN: '',
        name_JA: '',
        status: '0',
        rowStamp: '',
    });
    //#endregion

    //#region [function]
    // edit delete
    const handleDeleteItem = async () => {
        const { success, response, error } = await operation(
            {
                url: `/sysDrill/drillCategoryItem/${paramsCode.categoryItemGuid}`,
                method: 'DELETE',
            },
            'handleDeleteItem'
        );
        if (success) {
            // console.log(response.data);
            response.status === 200 && navigate('/presetDrill');
        } else if (error) {
            console.log(error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (Object.keys(paramsCode).length) {
            //edit
            const { success, response, error } = await operation(
                {
                    url: `/sysDrill/drillCategoryItem/${paramsCode.categoryItemGuid}`,
                    method: 'PUT',
                    data: {
                        ...data,
                    },
                },
                'handleEditItem'
            );
            if (success) {
                // console.log(response.data);
                response.status === 200 && navigate('/presetDrill');
            } else if (error) {
                console.log(error);
            }
        } else {
            //create
            const { success, response, error } = await operation(
                {
                    url: '/sysDrill/drillCategoryItem',
                    method: 'POST',
                    data: {
                        ...data,
                        categoryGuid,
                    },
                },
                'handleCreateItem'
            );
            if (success) {
                // console.log(response.data);
                response.status === 200 && navigate('/presetDrill');
            } else if (error) {
                console.log(error);
            }
        }
    };
    //#endregion

    //#region useEffect
    // getDetails
    useEffect(() => {
        const fetchDrillGradeDetails = async () => {
            const { success, response, error } = await operation(
                {
                    url: `/SysDrill/drillCategoryItem/${paramsCode.categoryItemGuid}`,
                },
                'fetchDrillGradeDetails'
            );
            if (success) {
                const data = response.data.result;
                // console.log(data);
                const { displayOrder, name_ZH, name_ZF, name_EN, name_JA, status, updateTime, rowStamp } = data;

                setData({
                    ...data,
                    displayOrder,
                    name_ZH,
                    name_ZF,
                    name_EN,
                    name_JA,
                    status,
                    updateTime: dayjs(updateTime).format('YYYY-MM-DD HH:mm:ss'),
                    rowStamp,
                });
            } else if (error) {
                console.log(error);
            }
        };

        if (Object.keys(paramsCode).length) {
            fetchDrillGradeDetails();
        }
    }, [paramsCode]);
    //#endregion

    return (
        <>
            <section id='section-main'>
                <form id='container-presetDrillCategoryItemUpdateDetail' onSubmit={handleSubmit}>
                    <div className='d-flex'>
                        <h5 className='fw-bold mb-4'>宾乐达球谱型态明细(子型态)</h5>
                        {Object.keys(paramsCode).length ? (
                            <React.Fragment>
                                <button
                                    className='btnDelete btn btn-outline-danger px-4 ms-auto me-2'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleDeleteItem();
                                    }}
                                >
                                    刪除
                                </button>
                                <button
                                    className='btnCancel btn btn-outline-primary px-4 me-2'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate('/presetDrill');
                                    }}
                                >
                                    取消
                                </button>
                            </React.Fragment>
                        ) : (
                            <button
                                className='btnCancel btn btn-outline-primary px-4 ms-auto me-2'
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate('/presetDrill');
                                }}
                            >
                                取消
                            </button>
                        )}
                        <button className='btn btn-primary text-white px-4'>储存</button>
                    </div>
                    {/* <div className="d-flex"></div> */}
                    <div className='manageFileWrapper bg-white p-4 rounded-4 mb-3'>
                        <input id='rowStamp' name='rowStamp' className='d-none' defaultValue={data.rowStamp} />
                        <div className='d-flex'>
                            <div className='col-6'>
                                <h6 className='fw-bold mb-3'>档案管理</h6>
                                <h6 className='fontSize75Rem mb-2'>显示顺序</h6>
                                <input
                                    id='displayOrder'
                                    name='displayOrder'
                                    type='number'
                                    className='form-control mb-3 w-100'
                                    required
                                    value={data.displayOrder || ''}
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            displayOrder: e.target.value,
                                        })
                                    }
                                />
                                <h6 className='fontSize75Rem mb-2'>中文名称</h6>
                                <input
                                    id='name_ZH'
                                    name='name_ZH'
                                    className='form-control mb-3 w-100'
                                    value={data.name_ZH || ''}
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            name_ZH: e.target.value,
                                        })
                                    }
                                    required
                                />
                                <h6 className='fontSize75Rem mb-2'>中文名称(繁)</h6>
                                <input
                                    id='name_ZF'
                                    name='name_ZF'
                                    className='form-control mb-3 w-100'
                                    value={data.name_ZF || ''}
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            name_ZF: e.target.value,
                                        })
                                    }
                                    required
                                />
                                <h6 className='fontSize75Rem mb-2'>英文名称</h6>
                                <input
                                    id='name_EN'
                                    name='name_EN'
                                    className='form-control mb-3 w-100'
                                    value={data.name_EN || ''}
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            name_EN: e.target.value,
                                        })
                                    }
                                />
                                <h6 className='fontSize75Rem mb-2'>日文名称</h6>
                                <input
                                    id='name_JA'
                                    name='name_JA'
                                    className='form-control mb-3 w-100'
                                    value={data.name_JA || ''}
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            name_JA: e.target.value,
                                        })
                                    }
                                />
                                <h6 h6='demand' className='fontSize75Rem mb-2'>
                                    狀態
                                </h6>
                                <select
                                    className='form-control mb-3 '
                                    name='status'
                                    id='status'
                                    value={data.status || ''}
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            status: e.target.value,
                                        })
                                    }
                                    required
                                >
                                    <option value='0'>0：停用</option>
                                    <option value='1'>1：啟用</option>
                                </select>
                                {Object.keys(paramsCode).length !== 0 && (
                                    <React.Fragment>
                                        <h6 className='fontSize75Rem mb-2'>创建/修改时间</h6>
                                        <input
                                            type='datetime-local'
                                            id='updateTime '
                                            name='updateTime'
                                            className='form-control mb-3 w-100'
                                            value={data.updateTime || ''}
                                            onChange={(e) =>
                                                setData({
                                                    ...data,
                                                    updateTime: e.target.value,
                                                })
                                            }
                                            disabled
                                        />
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                    </div>
                </form>
            </section>
        </>
    );
}

export default PresetDrillCategoryItemUpdateDetail;
