import './productRegister.scss';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';
import useAxios from '../../../hooks/useAxios';
import { useTranslation } from 'react-i18next';

// 2024-07-08 YL
// 1. Comment out '購物通道' input & 'storeValidated
// 2. Comment out 2 of 'benefits_of_product_registration' sentences
// 3. Disable checkStore function
function ProductRegister() {
    const { t } = useTranslation(['productRegister']); // i18n
    const { operation } = useAxios();
    const navigate = useNavigate();

    const [modelId, setModelId] = useState('');
    const [serialNo, setSerialNo] = useState('');
    const [purchaseDate, setPurchaseDate] = useState('');
    const [store, setStore] = useState('');
    // const [checkAgreement, setCheckAgreement] = useState('');
    const [modalShow, setModalShow] = useState(false);

    // sweetAlert
    // --未同意註冊條款dialog --
    // const checkAgreementError = () => {
    // 	Swal.fire({
    // 		title: '尚未同意註冊條款',
    // 		icon: 'warning',
    // 	});
    // };

    // --查无此产品dialog --
    const notFoundError = () => {
        Swal.fire({
            title: '查無此產品',
            icon: 'warning',
            confirmButtonText: '確定',
        });
    };

    // --已被本人註冊過dialog --
    const regByOriginUserError = () => {
        Swal.fire({
            title: '此產品您已註冊過了',
            icon: 'warning',
            confirmButtonText: '確定',
        });
    };

    // --已被其他人註冊dialog --
    const regByOthersError = () => {
        Swal.fire({
            title: '此產品已被其他人註冊',
            icon: 'warning',
            confirmButtonText: '確定',
        });
    };

    // --註冊成功dialog --
    const successAlert = () => {
        Swal.fire({
            title: '註冊成功',
            // html: `<h6>產品註冊成功！<br/><br/>你的帳號已升级為【尊榮會員】。<br/><br/>此外，我们將提供给您5個免費的家族會員帳號，您可以授權给親朋好友使用。具體使用方式，請進入家族會員帳號管理頁面</h6>`,
            // html: `<h6>產品註冊成功！<br/><br/>你的帳號已升级為【尊榮會員】。</h6>`,
            icon: 'success',
            confirmButtonText: '跳轉至我的產品',
            allowOutsideClick: false,
        }).then((result) => {
            if (result.isConfirmed) {
                navigate('/product');
            }
        });
    };

    // 驗證欄位
    let modelIdReg = /^[0-9A-Z]{5}$/;
    let serialNoReg = /^[0-9]{7}$/;
    let Today = new Date();

    let serialNoValidated = false;
    let purchaseDateValidated = false;
    let storeValidated = false;

    const checkModelId = () => {
        if (!modelId) {
            document.querySelector('#serialNo-info').textContent = t('cannot_be_empty');
            document.querySelector('#modelId').style.borderColor = '#CC3300';
            serialNoValidated = false;
        } else if (!modelIdReg.test(modelId)) {
            document.querySelector('#serialNo-info').textContent = '格式錯誤 請重新輸入';
            document.querySelector('#modelId').style.borderColor = '#CC3300';
            serialNoValidated = false;
        } else {
            document.querySelector('#serialNo-info').textContent = '';
            document.querySelector('#modelId').style.borderColor = '#ced4da';
            serialNoValidated = true;
        }
    };

    const checkSerialNo = () => {
        if (!serialNo) {
            document.querySelector('#serialNo-info').textContent = t('cannot_be_empty');
            document.querySelector('#serialNo').style.borderColor = '#CC3300';
            serialNoValidated = false;
        } else if (!serialNoReg.test(serialNo)) {
            document.querySelector('#serialNo-info').textContent = '格式錯誤 請重新輸入';
            document.querySelector('#serialNo').style.borderColor = '#CC3300';
            serialNoValidated = false;
        } else {
            //驗證碼驗證
            const sixSerialNo = serialNo.slice(0, 6).split('');
            const verifyNo = parseInt(serialNo.slice(-1));
            // console.log(sixSerialNo, verifyNo);

            let num1 =
                sixSerialNo[0] * 1 +
                sixSerialNo[1] * 7 +
                sixSerialNo[2] * 1 +
                sixSerialNo[3] * 7 +
                sixSerialNo[4] * 1 +
                sixSerialNo[5] * 7;
            let num2 = parseInt(num1 % 10) * 7;
            let num3 = parseInt((num2 / 10) % 10) + parseInt(num2 % 10);
            let num4 = parseInt(num3 % 10);
            // console.log(num1, num2, num3, num4);

            if (num4 === verifyNo) {
                document.querySelector('#serialNo-info').textContent = '';
                document.querySelector('#serialNo').style.borderColor = '#ced4da';
                serialNoValidated = true;
            } else {
                document.querySelector('#serialNo-info').textContent = '格式錯誤 請重新輸入';
                document.querySelector('#serialNo').style.borderColor = '#CC3300';
                serialNoValidated = false;
            }
        }
    };

    const checkPurchaseDate = () => {
        if (!purchaseDate) {
            document.querySelector('#purchaseDate-info').textContent = t('cannot_be_empty');
            document.querySelector('#purchaseDate').style.borderColor = '#CC3300';
        } else if (Date.parse(purchaseDate).valueOf() > Date.parse(Today).valueOf()) {
            document.querySelector('#purchaseDate-info').textContent = '格式錯誤 請重新輸入';
            document.querySelector('#purchaseDate').style.borderColor = '#CC3300';
        } else {
            document.querySelector('#purchaseDate-info').textContent = '';
            document.querySelector('#purchaseDate').style.borderColor = '#ced4da';
            purchaseDateValidated = true;
        }
    };

    // const checkStore = () => {
    //     if (!store) {
    //         document.querySelector('#store-info').textContent = t('cannot_be_empty');
    //         document.querySelector('#store').style.borderColor = '#CC3300';
    //     } else {
    //         document.querySelector('#store-info').textContent = '';
    //         document.querySelector('#store').style.borderColor = '#ced4da';
    //         storeValidated = true;
    //     }
    // };

    // 註冊產品
    const handleRegisterProduct = async (e) => {
        e.preventDefault();

        checkModelId();
        checkSerialNo();
        checkPurchaseDate();
        // checkStore();

        try {
            // 註冊產品API
            const data = {
                // 目前暫定產品一種
                modelID: modelId,
                SerialNo: serialNo,
                PurchaseDate: purchaseDate,
                SalesChannel: null,
            };
            // console.log(data);

            if (serialNoValidated && purchaseDateValidated) {
                // document.querySelector('#store-info').textContent = '';
                const { success, response, error } = await operation(
                    {
                        url: '/product/register',
                        method: 'PUT',
                        data: data,
                    },
                    'handleRegisterProduct'
                );
                if (success) {
                    if (response.data.status === '1') {
                        notFoundError();
                    } else if (response.data.status === '2') {
                        regByOriginUserError();
                    } else if (response.data.status === '3') {
                        regByOthersError();
                    } else if (response.data.status === '0') {
                        successAlert();
                    }
                }
                if (error) {
                    console.log(error);
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <article id='container-productRegister'>
            <div className='articleBoxWithSideBox'>
                <section className='row g-3 mb-3'>
                    <div className='col-12'>
                        <h1 className='registerProductTitle'>{t('register_product')}</h1>
                        <h2 className='mb-4'>{t('simple_quick_secure')}</h2>
                        <h2>{t('benefits_of_product_registration')}</h2>
                        <ol className='list-unstyled'>
                            <li>1. {t('enjoy_full_support')}</li>
                            {/* <li>2. {t('upgrade_to_premium_member')}</li>
                            <li>3. {t('invite_family_members')}</li> */}
                        </ol>
                    </div>
                </section>
                <section className='row g-3 mb-3'>
                    <div className='col-12'>
                        <form className='row g-3' onSubmit={handleRegisterProduct}>
                            {/* 设备序列号 */}
                            <div className='col-lg-6'>
                                <label className='form-label' htmlFor='serialNo'>
                                    {t('device_serial_number')}
                                </label>
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg'
                                            id='modelId'
                                            name='modelId'
                                            value={modelId}
                                            onChange={(e) => setModelId(e.target.value.toUpperCase())}
                                            onBlur={checkModelId}
                                        />
                                    </div>
                                    <div className='col-lg-1 d-none d-lg-block'>
                                        <h6 className='h-100 m-0'>-</h6>
                                    </div>
                                    <div className='col-lg-7'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg'
                                            id='serialNo'
                                            name='serialNo'
                                            placeholder={t('enter_device_serial_number')}
                                            value={serialNo}
                                            onChange={(e) => setSerialNo(e.target.value.toUpperCase())}
                                            onBlur={checkSerialNo}
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-6'>
                                        <div className='col-sm-6 invalid-feedback' id='serialNo-info'></div>
                                    </div>
                                    <div className='col-sm-6 mt-1 text-sm-end'>
                                        <Link
                                            to='#'
                                            data-toggle='modal'
                                            data-target='#dialog-howToCheck'
                                            onClick={() => setModalShow(true)}
                                        >
                                            {t('view_serial_number')}
                                        </Link>
                                    </div>
                                </div>
                                <Modal
                                    className='serialNoModal'
                                    size='xl'
                                    show={modalShow}
                                    onHide={() => setModalShow(false)}
                                    centered
                                >
                                    <Modal.Header closeButton></Modal.Header>
                                    <Modal.Body>
                                        <div className='row g-3'>
                                            <div className='col-12 text-center'>
                                                <h5 className='dialog-title'>{t('view_serial_number')}</h5>
                                                <div className='imgDiv'>
                                                    <img
                                                        className='serialNoCheckImg'
                                                        src='./images/machineCheck.png'
                                                        alt='serialNoCheckImg'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </Modal.Body>
                                </Modal>
                            </div>
                            <div className='w-100 d-none d-lg-block m-0'></div>

                            {/* 购买日期 */}
                            <div className='col-lg-6'>
                                <label className='form-label' htmlFor='purchaseDate'>
                                    {t('purchase_date')}
                                </label>
                                <input
                                    type='date'
                                    className='form-control form-control-lg'
                                    id='purchaseDate'
                                    name='purchaseDate'
                                    value={purchaseDate}
                                    onChange={(e) => setPurchaseDate(e.target.value)}
                                    onBlur={checkPurchaseDate}
                                />
                                <div className='invalid-feedback' id='purchaseDate-info'></div>
                            </div>
                            <div className='w-100 d-none d-lg-block m-0'></div>

                            {/* 购买通路 */}
                            {/* <div className='col-lg-6'>
                                <label className='form-label' htmlFor='store'>
                                    {t('purchase_channel')}
                                </label>
                                <select
                                    className='form-select form-select-lg'
                                    id='store'
                                    name='store'
                                    value={store}
                                    onChange={(e) => setStore(e.target.value)}
                                    onBlur={checkStore}
                                >
                                    <option value=''>{t('select_channel')}</option>
                                    <option value='af28f9f8-3c1c-ec11-8c5b-00155d070f06'>
                                        {t('official_website')}
                                    </option>
                                    <option value='bf28f9f8-3c1c-ec11-8c5b-00155d070f06'>{t('online_store')}</option>
                                    <option value='cf28f9f8-3c1c-ec11-8c5b-00155d070f06'>{t('retail_store')}</option>
                                    <option value='df28f9f8-3c1c-ec11-8c5b-00155d070f06'>{t('training_center')}</option>
                                </select>
                                <div className='invalid-feedback' id='store-info'></div>
                            </div> */}
                            <div className='w-100 d-none d-lg-block m-0'></div>

                            {/* <div className="form-group">
								<input
									type="checkbox"
									id="agreement"
									className="mr-3"
									onChange={(e) =>
										setCheckAgreement(e.target.value)
									}
								/>
								<Link to="/product/productagreement">
									產品註冊同意条款
								</Link>
							</div> */}

                            {/*submit button */}
                            <div
                                className='col-lg-6 text-center mt-5 d-flex justify-content-center justify-content-lg-end'
                                style={{ gap: '16px' }}
                            >
                                <button
                                    type='button'
                                    className='btn btn-size-m btn-color-outline-auxiliary rounded-pill'
                                    onClick={() => navigate('/product')}
                                >
                                    {t('cancel')}
                                </button>
                                <button type='submit' className='btn btn-size-m btn-color-main rounded-pill'>
                                    {t('register')}
                                </button>
                            </div>
                        </form>
                    </div>
                </section>
            </div>
        </article>
    );
}

export default ProductRegister;
