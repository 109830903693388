import './appMainUpdateDetail.scss';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import * as dayjs from 'dayjs';
import Swal from 'sweetalert2';
import useAxios from '../../../hooks/useAxios';

function AppMainUpdateDetail() {
    //#region [States initializations]
    const paramsCode = useParams('appGuid');
    const navigate = useNavigate();
    const [rowStamp, setRowStamp] = useState('');
    const { responses, loadings, errors, operation, isAnyLoading, hasAnyError } = useAxios();

    const [data, setData] = useState({
        appGuid: '',
        appCode: '',
        name: '',
        note: '',
        updateTime: '',
    });

    //#endregion

    //#region [function]

    const checkAppCode = async (appCode) => {
        const { success, response, error } = await operation(
            {
                url: `/sys/checkAppCodeValid?appCode=${appCode}`,
            },
            'checkAppCode'
        );

        if (success) {
            if (response.data === false) {
                Swal.fire({
                    icon: 'error',
                    title: `APP代码重複，請重新輸入`,
                });
                setData({ ...data, appCode: '' });
            }
        }

        if (error) {
            console.log(error);
        }
    };

    const handleDeleteItem = async () => {
        const { success, response, error } = await operation(
            {
                url: `/sys/app/${paramsCode.appGuid}`,
                method: 'DELETE',
            },
            'handleDeleteItem'
        );

        if (success) {
            navigate('/appMainUpdate');
        }

        if (error) {
            console.log(error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const form = new FormData(e.target);

        if (Object.keys(paramsCode).length) {
            // 修改
            const { success, response, error } = await operation(
                {
                    url: `/sys/app/${paramsCode.appGuid}`,
                    method: 'PUT',
                    data: form,
                },
                'handleSubmit'
            );

            if (success) {
                navigate('/appMainUpdate');
            }

            if (error) {
                console.log(error);
            }
        } else {
            // 新增
            const { success, response, error } = await operation(
                {
                    url: `/sys/app`,
                    method: 'POST',
                    data: form,
                },
                'handleSubmit'
            );

            if (success) {
                navigate('/appMainUpdate');
            }
        }
    };

    const getAppDetails = async () => {
        const { success, response, error } = await operation(
            {
                url: `/sys/app/${paramsCode.appGuid}`,
            },
            'getAppDetails'
        );

        if (success) {
            const data = response.data.result;
            // console.log(data);
            const { appCode, appGuid, name, note, updateTime, rowStamp } = data;

            setRowStamp(rowStamp);
            setData({
                ...data,
                appCode,
                appGuid,
                name,
                note,
                updateTime: updateTime && dayjs(updateTime).format('YYYY-MM-DD HH:mm:ss'),
            });
        }

        if (error) {
            console.log(error);
        }
    };

    //#endregion

    //#region [useEffect]

    // getDetails
    useEffect(() => {
        if (Object.keys(paramsCode).length) {
            getAppDetails();
        }
    }, [paramsCode]);

    //#endregion

    return (
        <>
            <section id='section-main'>
                <form id='container-appMainUpdateDetail' onSubmit={handleSubmit}>
                    <div className='d-flex'>
                        <h5 className='fw-bold mb-4'>APP基本设定</h5>
                        <button
                            className='btnDelete btn btn-outline-danger px-4 ms-auto me-2'
                            onClick={(e) => {
                                e.preventDefault();
                                handleDeleteItem();
                            }}
                        >
                            刪除
                        </button>
                        <button
                            className='btnCancel btn btn-outline-primary px-4 me-2'
                            onClick={(e) => {
                                e.preventDefault();
                                navigate('/appMainUpdate');
                            }}
                        >
                            取消
                        </button>
                        <button className='btn btn-primary text-white px-4'>储存</button>
                    </div>
                    {/* <div className="d-flex"></div> */}
                    <div className='manageFileWrapper bg-white p-4 rounded-4 mb-3'>
                        <input id='rowStamp' name='rowStamp' className='d-none' defaultValue={rowStamp} />
                        <div className='d-flex'>
                            <div className='col-6'>
                                <h6 className='fw-bold mb-3'>资料管理</h6>
                                <h6 className='fontSize75Rem mb-2'>APP代码（创建后不能修改）</h6>
                                <input
                                    id='appCode'
                                    name='appCode'
                                    className='form-control mb-3 w-100'
                                    required
                                    value={data.appCode || ''}
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            appCode: e.target.value.toUpperCase(),
                                        })
                                    }
                                    maxLength='2'
                                    disabled={Object.keys(paramsCode).length !== 0}
                                    onBlur={(e) => checkAppCode(e.target.value)}
                                />
                                <h6 className='fontSize75Rem mb-2'>APP名称</h6>
                                <input
                                    id='name'
                                    name='name'
                                    className='form-control mb-3 w-100'
                                    required
                                    value={data.name || ''}
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            name: e.target.value,
                                        })
                                    }
                                />
                                <h6 className='fontSize75Rem mb-2'>注记</h6>
                                <input
                                    id='note'
                                    name='note'
                                    className='form-control mb-3 w-100'
                                    required
                                    value={data.note || ''}
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            note: e.target.value,
                                        })
                                    }
                                />
                                {Object.keys(paramsCode).length !== 0 && (
                                    <>
                                        <h6 className='fontSize75Rem mb-2'>创建/修改时间</h6>
                                        <input
                                            type='datetime-local'
                                            id='updateTime '
                                            name='updateTime'
                                            className='form-control mb-3 w-100'
                                            value={data.updateTime || ''}
                                            onChange={(e) =>
                                                setData({
                                                    ...data,
                                                    updateTime: e.target.value,
                                                })
                                            }
                                            disabled
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </form>
            </section>
        </>
    );
}

export default AppMainUpdateDetail;
