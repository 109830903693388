import { Link } from 'react-router-dom';
import './newsList.scss';
import { useContext, useEffect, useState } from 'react';
import useAxios from '../../../hooks/useAxios';
import { useTranslation } from 'react-i18next';
import { AccountContext } from '../../../App';

export default function NewsList() {
    const { t } = useTranslation(['news']); // i18n
    const { lang } = useContext(AccountContext);
    const { operation } = useAxios();
    const [newsList, setNewsList] = useState([]);

    // 多國語言
    let languageCode = 'ZF';

    if (lang === 'zh-CN') {
        languageCode = 'ZH';
    } else if (lang === 'en') {
        languageCode = 'EN';
    } else {
        languageCode = 'ZF';
    }

    useEffect(() => {
        const getNewsList = async () => {
            const { success, response, error } = await operation(
                {
                    url: `/service/getBulletinList?languageCode=${languageCode}`,
                    method: 'GET',
                },
                'getNewsList'
            );

            if (success) {
                console.log(response.data.result);
                setNewsList(response.data.result);
            }
            if (error) {
                console.log(error);
            }
        };
        getNewsList();
    }, [lang]);

    return (
        <div className='container-fluid p-0 bg-white'>
            <article id='container-newsList' className='d-flex flex-column align-items-center px-0 mx-auto'>
                <section className='newsList-section section1 w-100 row bg-white'>
                    <div
                        className='col-12 p-0 text-center
					'
                    >
                        <h2 className='px-6 mx-auto mb-3 text-grey5'>{t('news_updates')}</h2>
                        <h6 className='px-6 mx-auto mb-0 text-grey5'></h6>
                    </div>
                </section>
                <section className='newsList-section section2 w-100 row mb-10 mb-lg-14'>
                    {newsList
                        .sort((a, b) => b.displayOrder - a.displayOrder)
                        .map((item, index) => {
                            return (
                                <Link
                                    key={item.bulletinGuid}
                                    className={`newsWrapper link d-flex flex-column flex-lg-row ${
                                        index % 2 !== 0 ? 'flex-lg-row-reverse' : ''
                                    } justify-content-between align-items-center px-3 px-lg-0 mb-7 mb-lg-7`}
                                    to={`./${item.bulletinGuid}`}
                                    style={{ whiteSpace: 'pre-line' }}
                                >
                                    <div className='imgWrapper'>
                                        <img
                                            className={`icnMoreplusBlue me-2`}
                                            src={item.coverPath}
                                            alt={`newsCover${index}`}
                                        />
                                    </div>
                                    <div className='textWrapper w-100 ps-5 pe-3'>
                                        <h1 className='title mb-3'>{item.title}</h1>
                                        <p className='content'>{item.summary}</p>
                                        <h5>{item.releaseTime.split('T')[0]}</h5>
                                    </div>
                                </Link>
                            );
                        })}
                </section>
            </article>
        </div>
    );
}
