import './agreement.scss';
import React from 'react';
import { Link } from 'react-router-dom';

/// 用戶協議
/// Author : YL
function Agreement() {
    return (
        <div className='container-fluid p-0'>
            <article id='container-agreement' className='d-flex flex-column align-items-center px-0 mx-auto'>
                <div className='outerWrapper'>
                    <div className='wrapper'>
                        <div className='col-12 desc px-3'>
                            <div className='text-grey5 ps-0 mb-4 mb-lg-5 row justify-content-center'>
                                <main className=''>
                                    <h1>賓樂達運動使用者協定</h1>
                                    <section className='section0'>
                                        <div className='subSection subSession1'>
                                            <div className='wordWrap'>
                                                <p>
                                                    翔睿德股份有限公司（簡稱“翔睿德”或“我們”，地址：“台北市士林區中山北路六段七號三樓”）在此特別提醒您（簡稱“用戶”或“您”）認真閱讀並充分理解本使用者協定（簡稱“本協定”）。
                                                </p>
                                                <p>
                                                    本協議是使用者與翔睿德之間簽訂的法律協定，本協定是針對賓樂達乒乓球機器人（簡稱“賓樂達設備”或“本設備”或“本產品”）上的軟體、介面、圖形、文字、資料、檔案、後續可下載的更新等（簡稱“賓樂達智慧財產權”）而簽訂所共同約定的條款。
                                                </p>
                                                <p>翔睿德有權修訂本協議，訂定後或更新後的協議，自公佈之日起生效。</p>
                                                <p>
                                                    使用者應認真閱讀、充分理解本協議中，涉及免除或者限制翔睿德責任、爭議解決和法律適用的條款，
                                                    <strong>
                                                        其中免除或者限制責任的條款將以粗體標識，您需要重點閱讀。
                                                    </strong>
                                                </p>
                                            </div>
                                            <div className='wordWrap'>
                                                <strong>
                                                    重要通知：在
                                                    “打開包裝”或“使用產品”、以及“啟用軟體”前，請仔細閱讀以下協定條款（未成年人應在法定監護人陪同下閱讀）。
                                                </strong>
                                            </div>
                                            <div className='wordWrap'>
                                                <p>
                                                    “打開包裝”或“使用產品”，即表示您同意受本協議的約束，如果您不同意其中任何條款，請
                                                    不要 “打開包裝”或“使用產品”，則我們將 不會
                                                    向您許可賓樂達智慧財產權。且您必須依據翔睿德的退貨政策，立即將賓樂達設備連同文件、配件等包裝完好無損送回您購買本產品的零售商或購貨地點，以進行退貨程式。
                                                </p>
                                            </div>
                                            <div className='wordWrap'>
                                                <p>
                                                    “啟用軟體”即表示您同意受本協議的約束，如果您不同意其中任何條款，請
                                                    不要 “啟用軟體”，則我們將 不會 向您許可賓樂達智慧財產權，也 不會
                                                    向您提供賓樂達的軟體（簡稱“本軟體”），以及相關服務（簡稱“本軟體及服務”），
                                                    例如以下列示的內容，但具體將以翔睿德實際提供的為准。
                                                </p>
                                            </div>
                                            <div className='wordWrap'>
                                                <p>
                                                    (1)
                                                    軟體是用於賓樂達設備，為使用該智慧設備的使用者提供設備的連接與綁定、功能設置、設備管理與健康運動的資料服務。
                                                </p>
                                                <p>
                                                    (2)
                                                    位於www.synerter.com和www.synerter.com.tw與子域中關於賓樂達設備的網站內容。
                                                </p>
                                                <p>(3) 賓樂達設備通過網站獲得的服務，包括過去與將來提供的所有服務。</p>
                                                <p>(4) 可供下載的賓樂達軟體、以及提供的所有更新與升級。</p>
                                                <p>您的以下行為 不受到 “本協議”的約束：</p>
                                                <p>(5) 您購買本產品的購買行為。</p>
                                                <p>(6) 您購買本產品的有限保修條款。</p>
                                                <p>(7) 您購買本產品所相關或衍生的商務行為與相關條款。</p>
                                            </div>
                                            <div className='wordWrap'>
                                                <strong>
                                                    您清楚理解，翔睿德僅為使用者提供本軟體及服務，但與其有關的其他費用（如本軟體終端費用、為接入互聯網而支付的上網費）均應由您自行負擔。且您也清楚理解，在使用本軟體及服務時，將會耗用您的設備、頻寬、流量等資源。
                                                </strong>
                                            </div>
                                        </div>
                                    </section>

                                    <section className='section1'>
                                        <h2>一、授權範圍： </h2>
                                        <div className='subSection subSection1'>
                                            <div className='wordWrap'>
                                                <p>
                                                    1.1
                                                    依據本協議條款，翔睿德特此授予您在中華民國使用本軟體及服務，該授權是給予用戶一項個人、非專屬、非獨占、不可轉讓、不含智慧財產權授予的有限許可。
                                                </p>
                                            </div>
                                            <div className='wordWrap'>
                                                <p>1.2 使用者不允許將本軟體安裝於未經翔睿德書面許可的其他設備。</p>
                                            </div>
                                            <div className='wordWrap'>
                                                <p>
                                                    1.3
                                                    使用者不允許對本軟體或本軟體運行過程中，釋放到任何終端設備記憶體中的資料，以及本軟體運行過程中用戶端與伺服器端的交互資料，進行複製、更改、修改、掛接運行或創作任何衍生作品。
                                                </p>
                                            </div>
                                            <div className='wordWrap'>
                                                <p>
                                                    1.4
                                                    使用者只允許將本設備用於非商業性目的時使用，也只允許以非商業目的在設備端安裝、使用、顯示、運行本軟體。使用者不允許為商業運營目的安裝、使用、運行本軟體，如果需要進行商業性的銷售、複製和散發，必須事前取得翔睿德的書面許可。
                                                </p>
                                            </div>
                                            <div className='wordWrap'>
                                                <p>
                                                    1.5
                                                    除本協議明示授權外，翔睿德未授權給用戶其他權利，若使用者有需要使用其他權利時，必須事前取得翔睿德的書面許可。
                                                </p>
                                            </div>
                                        </div>
                                    </section>

                                    <section className='section2'>
                                        <h2>二、用戶承諾：</h2>
                                        <div className='subSection subSection1'>
                                            <p className='subtitle'>2.1 對智慧財產權的保護規範：</p>
                                            <div className='wordWrap'>
                                                <p>
                                                    2.1.1
                                                    用戶不允許複製賓樂達智慧財產權，除非是伴隨正常使用本設備或因本軟體而伴隨發生的情形。
                                                </p>
                                            </div>
                                            <div className='wordWrap'>
                                                <p>
                                                    2.1.2
                                                    用戶不允許出租、出借、再許可、分發、翻譯、改動、合併、更改、修改、變化、刪除賓樂達智慧財產權的全部或任意部分。
                                                </p>
                                            </div>
                                            <div className='wordWrap'>
                                                <p>
                                                    2.1.3
                                                    使用者不允許刪除本軟體及其他副本上一切關於版權的資訊，以及修改、刪除或避開本軟體為保護智慧財產權而設置的技術措施。
                                                </p>
                                            </div>
                                            <div className='wordWrap'>
                                                <p>
                                                    2.1.4
                                                    使用者不允許修改或偽造本軟體中的指令、資料，不可以增加、刪減、變動軟體的功能或運行效果，或者將用於上述用途的軟體、方法進行運營或向公眾傳播，無論這些行為是否為商業目的。
                                                </p>
                                            </div>
                                            <div className='wordWrap'>
                                                <p>
                                                    2.1.5
                                                    使用者不允許對本軟體進行反向工程，如反彙編、反編譯、或者其他試圖獲得本軟體的原始程式碼，或取得賓樂達智慧財產權的全部或任意部分內容，或根據其創造衍生作品，也不可以嘗試進行此類任意行為。
                                                </p>
                                            </div>
                                            <div className='wordWrap'>
                                                <p>
                                                    2.1.6
                                                    使用者不允許以本軟體進行任何危害網路安全的行為，包括但不限於：使用未經許可的資料或進入未經許可的伺服器/帳戶；未經允許進入公眾網路或者他人作業系統並刪除、修改、增加存儲資訊；未經許可企圖探查、掃描、測試本軟體的系統或網路的弱點或其它實施破壞網路安全的行為；企圖干涉、破壞本軟體系統或網站的正常運行，故意傳播惡意程式或病毒以及其他破壞干擾正常網路資訊服務的行為；偽造TCP/IP資料包名稱或部分名稱。
                                                </p>
                                            </div>
                                            <div className='wordWrap'>
                                                <p>
                                                    2.1.7
                                                    除因當地法律允許、或本協議明確許可、或事前取得翔睿德書面許可外，用戶如有以上所述不允許的或類似的行為，無論是否明知或故意，均視為侵害翔睿德所擁有的賓樂達智慧財產權。
                                                </p>
                                            </div>
                                        </div>

                                        <div className='subSection subSection2'>
                                            <p className='subtitle'>2.2 資訊發佈規範：</p>

                                            <div className='wordWrap'>
                                                <p>
                                                    2.2.1
                                                    使用者對於所使用本軟體發表的資訊內容，應確信保證，您擁有您所上傳資訊內容的智慧財產權或已取得合法授權，您使用本軟體及服務的任何行為未侵犯任何協力廠商之合法權益。
                                                </p>
                                            </div>
                                            <div className='wordWrap'>
                                                <p>
                                                    2.2.2
                                                    使用者在使用本軟體時不得利用本軟體從事以下未經授權、傷害他人、違反法規等行為，包括但不限於：
                                                </p>
                                            </div>
                                            <div className='wordWrap'>
                                                <p>(1) 製作、複製、發佈、傳播、儲存違反當地國家法律法規的內容。</p>
                                                <p>
                                                    (2)
                                                    發佈、傳送、傳播、儲存侵害他人名譽權、肖像權、智慧財產權、商業秘密等合法權利的內容。
                                                </p>
                                                <p>(3) 虛構事實、隱瞞真相以誤導、欺騙他人。</p>
                                                <p>(4) 發表、傳送、傳播廣告資訊及垃圾資訊。</p>
                                                <p>(5) 從事其他違反當地法律法規的其他行為。</p>
                                                <p>
                                                    (6)
                                                    未經翔睿德許可，您不得在本軟體中進行任何諸如發佈廣告、銷售商品的商業行為。
                                                </p>
                                            </div>
                                        </div>

                                        <div className='subSection subSection3'>
                                            <p className='subtitle'>2.3 合法合理使用規範：</p>
                                            <div className='wordWrap'>
                                                <p>
                                                    2.3.1
                                                    用戶不允許通過非翔睿德公司開發、授權或認可的協力廠商相容軟體、系統登錄或使用本軟體及服務，或製作、發佈、傳播上述工具。
                                                </p>
                                            </div>
                                            <div className='wordWrap'>
                                                <p>
                                                    2.3.2
                                                    事前未取得翔睿德的書面許可，使用者不允許對本軟體及其中的資訊擅自實施編譯或衍生行為，包括但不限於：使用、出租、出借、複製、修改、連結、轉載、彙編、發表、出版，建立鏡像網站、擅自借助本軟體發展與之有關的衍生產品、作品、服務、外掛程式、外掛、相容、互聯等。
                                                </p>
                                            </div>
                                            <div className='wordWrap'>
                                                <p>
                                                    2.3.3
                                                    使用者不允許利用本軟體發表、傳送、傳播、儲存違反當地法律的內容。
                                                </p>
                                            </div>
                                            <div className='wordWrap'>
                                                <p>
                                                    2.3.4
                                                    使用者不允許利用本軟體發表、傳送、傳播、儲存侵害他人智慧財產權、商業秘密等合法權利的內容。
                                                </p>
                                            </div>
                                            <div className='wordWrap'>
                                                <p>
                                                    2.3.5 使用者不允許利用本軟體批量發表、傳送、傳播廣告資訊及垃圾資訊。
                                                </p>
                                            </div>
                                            <div className='wordWrap'>
                                                <p>
                                                    2.3.6
                                                    使用者不允許以其他任何不合法的方式、為任何不合法的目的、或以任何與本協議許可使用不一致的方式使用本軟體及服務。
                                                </p>
                                            </div>
                                        </div>

                                        <div className='subSection subSection4'>
                                            <p className='subtitle'>2.4合法轉讓規範：</p>
                                            <div className='wordWrap'>
                                                <p>
                                                    如果您向其他人轉讓本設備，您可以向該收受方（簡稱“新用戶”）一次性永久轉讓本協議規定的使用賓樂達智慧財產權的權利，但必須符合以下規範：
                                                </p>
                                            </div>
                                            <div className='wordWrap'>
                                                <p>(1) 轉讓必須包括您的賓樂達設備及其所有權與其他權利；</p>
                                            </div>
                                            <div className='wordWrap'>
                                                <p>
                                                    (2)
                                                    您不允許再持有賓樂達智慧財產權的任何副本，包括存儲在電腦或其他存放裝置上的副本；
                                                </p>
                                            </div>
                                            <div className='wordWrap'>
                                                <p>
                                                    (3)
                                                    您必須在轉讓前，告知新使用者關於本協議的條款和條件，並明確告知新用戶在接受轉讓的同時，即視為“打開包裝”的行為，新用戶必須清楚瞭解對賓樂達智慧財產權的使用將受到這些條款和條件的約束，以作為收受本設備的前提要件。
                                                </p>
                                            </div>
                                        </div>

                                        <div className='subSection subSection5'>
                                            <p className='subtitle'>2.5 用戶理解並同意：</p>
                                            <div className='wordWrap'>
                                                <p>
                                                    2.5.1
                                                    翔睿德會對用戶是否涉嫌違反上述使用規範做出認定，並根據認定結果中止、終止對您的使用許可或採取其他依本約定可採取的限制措施。
                                                </p>
                                            </div>
                                            <div className='wordWrap'>
                                                <p>
                                                    2.5.2
                                                    對於使用者使用許可軟體時所發佈涉嫌違法或涉嫌侵犯他人合法權利或違反本協定的資訊，翔睿德會直接刪除。
                                                </p>
                                            </div>
                                            <div className='wordWrap'>
                                                <p>
                                                    2.5.3
                                                    對於使用者違反上述使用規範的行為對協力廠商造成損害的，您需要以自己的名義獨立承擔法律責任，並應確保翔睿德免於因此產生損失或增加費用。
                                                </p>
                                            </div>
                                            <div className='wordWrap'>
                                                <p>
                                                    2.5.4
                                                    若使用者違反有關法律規定或協定約定，使翔睿德遭受損失，或受到協力廠商的索賠，或受到行政管理機關的處罰，用戶應當賠償翔睿德因此造成的損失和（或）發生的費用，包括合理的律師費、調查取證費用。
                                                </p>
                                            </div>
                                        </div>
                                    </section>

                                    <section className='section3'>
                                        <h2>三、軟體更新：</h2>
                                        <p>
                                            3.1
                                            本軟體為了改善使用者體驗、漏洞修復程式、或完善服務內容，翔睿德有權不時地為您提供本軟體的替換、更新、修改的升級版本。
                                        </p>
                                        <p>3.2 本軟體可能在不經另行通知用戶或徵得額外同意下自動安裝。</p>
                                        <p>3.3 如果您不願意更新本軟體，翔睿德不保證舊軟體的繼續可用狀態。</p>
                                        <p>
                                            3.4
                                            翔睿德將依據使用者使用的設備機型與使用條件而提供不同的軟體版本，使用者不得有異。
                                        </p>
                                    </section>

                                    <section className='section4'>
                                        <h2>四、用戶帳號：</h2>
                                        <p>
                                            使用本軟體的部分功能，使用者可能需要註冊翔睿德帳號（簡稱“用戶帳號”），用戶對於翔睿德帳號的註冊、使用行為等規範，必須遵守以下使用者帳號使用規定：
                                        </p>
                                        <div className='subSection subSection1'>
                                            <p className=''>4.1 帳號使用權利：</p>
                                            <p>
                                                用戶帳號所有權屬於翔睿德，您作為初始申請註冊人，僅擁有您所註冊帳號的使用權。
                                            </p>
                                        </div>
                                        <div className='subSection subSection2'>
                                            <p className='subtitle'>4.2 帳號註冊條件：</p>
                                            <div className='wordWrap'>
                                                <p>
                                                    4.2.1
                                                    您必須確認在您開始註冊程式使用本軟體及服務前，您應當具備中華民國法律規定的與您行為相適應的民事行為能力。若您不具備前述與您行為相適應的民事行為能力，則您及您的監護人應依照法律規定承擔因此而導致的一切後果。
                                                </p>
                                            </div>
                                            <div className='wordWrap'>
                                                <p>
                                                    4.2.2
                                                    您應提供及時、詳盡及準確的個人資料，並不斷更新註冊資料，符合及時、詳盡、準確的要求。您保證在註冊時所提交的所有資料（包括但不限於手機號碼、郵箱等）真實、準確、合法、有效且系您本人的資料。如果因註冊資訊不真實或更新不及時而引發的相關問題，我們將不負任何責任。
                                                </p>
                                            </div>
                                            <div className='wordWrap'>
                                                <p>
                                                    4.2.3
                                                    如您的註冊資訊中出現或存在有違法和不良資訊，我們將不予註冊；同時，在註冊後，如發現您以虛假資訊騙取帳號名稱註冊，我們有權不經通知而單方採取限期改正、暫停使用、終止帳號等措施。
                                                </p>
                                            </div>
                                        </div>
                                        <div className='subSection subSection3'>
                                            <p className='subtitle'>4.3 帳號行使規則：</p>
                                            <div className='wordWrap'>
                                                <p>
                                                    4.3.1 帳號使用規範：您必須對使用者帳號的所有行為負擔全部法律責任。
                                                </p>
                                            </div>
                                            <div className='wordWrap'>
                                                <p>
                                                    4.3.2
                                                    帳號轉讓禁止：用戶不得贈與、借用、租用、有償或無償轉讓或售賣用戶帳號或者以其他方式許可非初始申請註冊人使用用戶帳號。如果我們發現使用者並非初始申請註冊人，我們有權不經通知而單方終止該用戶帳號。
                                                </p>
                                            </div>
                                            <div className='wordWrap'>
                                                <p>
                                                    4.3.3
                                                    帳號安全保管：您的帳號為您自行設置並由您保管，我們在任何時候均不會主動要求您提供您的帳號密碼。您的用戶帳號如因主動洩漏或遭受他人攻擊而遭受損失，我們不承擔因此的法律責任，您應通過法律或其他途徑向侵權行為人追償。
                                                </p>
                                            </div>
                                        </div>
                                        <div className='subSection subSection4'>
                                            <p className=''>4.4 帳戶註銷：</p>
                                            <p>
                                                在需要終止您的使用者帳號時，您可以依照程式通知我們登出您的用戶帳號，您瞭解該用戶帳號一旦註銷後，將從我們資料庫中永遠刪除並無法回復。但對於您用戶帳號所相應的法律責任，不因註銷帳號而有任何影響。
                                            </p>
                                        </div>
                                        <div className='subSection subSection5'>
                                            <p className=''>4.5 帳號回收：</p>
                                            <p>
                                                為了防止資源佔用，如您連續24個月未使用您的用戶帳號，我們有權對該帳號進行註銷，您將不能再通過該帳號登錄或使用相關服務。如該帳號有關聯的待處理交易或餘額等，我們會在合理範圍內協助您處理。
                                            </p>
                                        </div>
                                        <div className='subSection subSection6'>
                                            <p className='subtitle'>4.6 免責條款</p>
                                            <div className='wordWrap'>
                                                <p>
                                                    4.6.1
                                                    用戶拒絕註冊：關於本軟體中需要註冊帳號的功能，用戶如果選擇拒絕註冊，則相關功能的使用、存儲或限制可能受到影響，用戶必須自己承擔責任。
                                                </p>
                                            </div>
                                            <div className='wordWrap'>
                                                <p>
                                                    4.6.2
                                                    用戶違反法規：如我們因您未依照本協議或法律規定，而拒絕申請、限期改正、暫停使用、或終止使用您的用戶帳號，所由此帶來的各項損失（包括但不限於通信中斷，使用者資料、郵件和相關資料等的清空等），均由您自行承擔。
                                                </p>
                                            </div>
                                        </div>
                                    </section>

                                    <section className='section5'>
                                        <h2>五、智慧財產權聲明：</h2>
                                        <p>
                                            5.1
                                            翔睿德是本軟體的智慧財產權權利人。本軟體的一切著作權、商標權、專利權、商業秘密等智慧財產權，以及與本軟體相關的所有資訊內容（包括但不限於文字、圖片、音訊、視頻、圖表、介面設計、版面框架、有關資料或電子文檔等）均受當地法律法規和相應的國際條約保護，翔睿德享有上述智慧財產權。
                                        </p>
                                        <p>
                                            5.2
                                            您確認賓樂達智慧財產權的所有智慧財產權在全世界均屬於賓樂達，賓樂達智慧財產權的權利僅是許可給您（而非售予您），以及除了本協議中各條款使用權外，您無權使用賓樂達智慧財產權。
                                        </p>
                                        <p>
                                            5.3
                                            除非事前取得翔睿德的書面許可，用戶不允許為任何商業或非商業目的，自行或許可任何協力廠商實施、利用、轉讓上述智慧財產權。
                                        </p>
                                        <p>5.4 翔睿德將保留追究上述行為法律責任的權利。</p>
                                    </section>

                                    <section className='section6'>
                                        <h2>六、服務風險及免責聲明：</h2>
                                        <p>
                                            6.1
                                            翔睿德是以目前的技術提供服務支援，不保證本軟體及服務在操作上不會中斷或沒有錯誤，不保證會糾正本軟體及服務的所有缺陷，也不保證本軟體及服務能滿足使用者的所有要求。由此產生的後果，翔睿德不承擔任何責任。
                                        </p>
                                        <p>
                                            6.2
                                            使用者因協力廠商如通訊線路故障、技術問題、網路、電腦終端設備故障、系統不穩定性及其他各種不可抗力原因而遭受的一切損失，翔睿德不承擔責任。
                                        </p>
                                        <p>
                                            6.3
                                            翔睿德不保證通過本軟體所獲得資訊內容（包括但不限於調用的協力廠商服務內容）的合法性、真實性、準確性、有效性，翔睿德不對用戶基於查詢、查看做出的任何行為的結果承擔任何責任。
                                        </p>
                                        <p>
                                            6.4
                                            本軟體如同大多數互聯網軟體一樣，受包括但不限於使用者原因、網路服務品質、社會環境等因素的差異影響，可能受到各種安全問題的侵擾，如他人利用使用者的資料，造成現實生活中的騷擾；使用者下載安裝的其他軟體或訪問的其他網站中含有"特洛伊木馬"等病毒，威脅到使用者的終端設備資訊和資料的安全，繼而影響本軟體的正常使用等等。使用者應加強資訊安全及使用者資料的保護意識，要注意加強式密碼保護，以免遭致損失和騷擾。
                                        </p>
                                        <p>
                                            6.5
                                            因使用者使用本軟體或要求翔睿德提供特定服務時，本軟體可能會調用協力廠商系統或協力廠商軟體支援使用者的使用或訪問，使用或訪問的結果由該協力廠商提供，使用者除遵守本協定相關規則外，還應遵守協力廠商的協定、相關規則。使用者應理解並同意，在使用協力廠商服務時，協力廠商可能會對使用者資料進行讀取，翔睿德不保證通過協力廠商系統或協力廠商軟體支援實現的結果的安全性、準確性、有效性及其他不確定的風險，用戶應審慎判斷，由此若引發的任何爭議及損害，翔睿德不承擔任何責任。
                                        </p>
                                        <p>
                                            6.6
                                            用戶應理解並同意，翔睿德為了保障公司業務發展和調整的自主權，翔睿德採取在合理的方式通知使用者後，可隨時修改或中斷服務。
                                        </p>
                                        <p>
                                            6.7
                                            除法律法規有明確規定外，翔睿德將盡最大努力確保軟體及其所涉及的技術及資訊安全、有效、準確、可靠，但受限於現有技術，用戶應理解並同意，翔睿德不能對此進行擔保。
                                        </p>
                                        <div>
                                            6.8
                                            使用者應自行承擔因下述任一情況所引起或與此有關的人身傷害或附帶的、間接的經濟損害賠償，包括但不限於利潤損失、資料損失、業務中斷的損害賠償或其他商業損害賠償或損失：
                                            <p>(1) 使用或未能使用軟體。</p>
                                            <p>(2) 協力廠商未經許可的使用軟體或更改使用者的資料。</p>
                                            <p>(3) 使用者使用軟體進行的行為產生的費用及損失。</p>
                                            <p>(4) 使用者對軟體的誤解。</p>
                                            <p>(5) 非因翔睿德的原因引起的與軟體有關的其他損失。</p>
                                        </div>
                                        <p>
                                            6.9
                                            用戶同意翔睿德有權基於司法的要求或自身業務原因，暫停、中斷或終止向使用者提供全部或者部分本服務。對此翔睿德不承擔任何責任。
                                        </p>
                                        <p>
                                            6.10
                                            用戶理解並同意，翔睿德將會盡其商業上的合理努力保障使用者在本軟體及服務中的資料存儲安全，但翔睿德不能提供完全保證，且翔睿德不對服務中的資料的刪除或儲存或備份失敗負責。
                                        </p>
                                    </section>

                                    <section className='section7'>
                                        <h2>七、協力廠商服務說明：</h2>
                                        <p>
                                            7.1
                                            使用者理解並同意，本軟體可能包含由翔睿德的關聯方或協力廠商提供的服務，翔睿德只是為了使用者便利操作而在系統中提供相關功能模組，提供協力廠商服務的使用入口。
                                        </p>
                                        <p>
                                            7.2
                                            無論協力廠商服務預置於本軟體系統服務中，還是由使用者自行開通或訂購，用戶均應理解並同意，翔睿德不對協力廠商服務提供方或使用者行為的合法性、有效性，以及協力廠商服務的合法性、準確性、有效性、安全性進行任何明示或默示的保證或擔保。
                                        </p>
                                        <p>
                                            7.3
                                            翔睿德並不監督協力廠商服務，不對其擁有任何控制權，也不對協力廠商服務提供任何形式的保證或擔保，更不承擔任何責任。
                                        </p>
                                        <p>
                                            7.4
                                            使用者與協力廠商服務提供方之間發生的任何爭議、糾紛應由用戶與該方按照協力廠商服務提供方的業務流程及業務規範自行協商解決，翔睿德不承擔任何責任。
                                        </p>
                                    </section>

                                    <section className='section8'>
                                        <h2>八、未成年人使用條款：</h2>
                                        <p>
                                            8.1 用戶如果是未滿18
                                            周歲的未成年人，應在監護人監護、指導並獲得監護人同意情況下，閱讀本協定和使用本軟體及相關服務。
                                        </p>
                                    </section>

                                    <section className='section9'>
                                        <h2>九、協議變更：</h2>
                                        <p>
                                            9.1
                                            翔睿德有權在必要時修改本協定條款，協定條款一旦發生變動，將會在相關頁面上公佈修改後的協議條款。如果不同意所改動的內容，使用者應主動取消此項服務。如果使用者繼續使用服務，則視為接受協定條款的變動。
                                        </p>
                                        <p>
                                            9.2
                                            翔睿德有權依據需要修改或變更所提供的收費服務、收費標準、收費方式、服務費及服務條款。翔睿德在提供服務時，可能對部分服務的使用者開始收取一定的費用，如用戶拒絕支付該等費用或拒絕同意條款變更，則不能在收費的相關條款調整後，繼續使用相關的服務。
                                        </p>
                                    </section>

                                    <section className='section10'>
                                        <h2>十、違約責任：</h2>
                                        <p>
                                            10.1
                                            翔睿德有權判斷用戶的行為是否符合本協議條款規定，如果認為用戶違反有關法律法規或者本協定、相關規則的規定，根據使用者違規情形的嚴重程度，翔睿德有權對使用者採取刪除違規資訊、限制、中止、終止使用者使用本軟體服務、追究使用者的法律責任以及其他翔睿德認為適合的處理措施。如果使翔睿德遭受任何損失（包括但不限於收到任何協力廠商的索賠或任何行政管理部門的處罰），使用者應當承擔全部責任。
                                        </p>
                                    </section>

                                    <section className='section11'>
                                        <h2>十一、適用法律及爭議解決：</h2>
                                        <p>
                                            11.1
                                            本協議條款之效力和解釋均適用中華民國法律規定。如無相關法律規定的，則參照使用國際商業慣例和/或商業慣例。
                                        </p>
                                        <p>11.2 本協議的簽訂地是台北市士林區。</p>
                                        <p>
                                            11.3
                                            使用者和翔睿德一致同意凡因本服務所產生的糾紛，雙方應先友好的協商解決，不願協商或協商不成的，任何一方均有權可提交本協定簽訂地有管轄權的法院訴訟解決。
                                        </p>
                                    </section>

                                    <section className='section12'>
                                        <h2>十二、隱私政策與個人資訊保護：</h2>
                                        <p>
                                            12.1 翔睿德承諾按照本產品隱私政策保護使用者的隱私，具體請見：
                                            <Link to='/privacy'>賓樂達隱私政策</Link>。
                                        </p>
                                    </section>

                                    <section className='section13'>
                                        <h2>十三、其他：</h2>
                                        <p>
                                            13.1
                                            翔睿德可能以電子郵件或短信或網頁公告、認為適當的方式發送通知，告知使用者服務條款的修改、服務變更及/或其它重要事項。翔睿德通過以上任一方式向使用者發報消息後，使用者未在
                                            3 日內通過書面方式提出異議，視為接受新消息的內容。
                                        </p>
                                        <p>13.2 本協議更新時間為【2023】年【12】月【5】日。</p>
                                        <p>
                                            13.3
                                            本協議所有條款的標題僅為閱讀方便，本身並無實際涵義，不能作為本協定涵義解釋的依據。
                                        </p>
                                        <p>
                                            13.4
                                            本協定條款無論因何種原因以致部分無效或不可執行，其餘條款仍有效，對雙方具有約束力。
                                        </p>
                                    </section>
                                </main>
                            </div>
                        </div>
                    </div>
                </div>
            </article>
        </div>
    );
}

export default Agreement;
