import { Route, Routes, useOutletContext } from 'react-router-dom';
import pagesData from './pagesData';
import RequireAuth from '../components/RequireAuth';
import Layout from '../components/layout/Layout';
import NotFoundPage from '../components/notFoundPage/NotFoundPage';

const Router = ({ auth }) => {
    const pageRoutes = pagesData.map(({ path, title, element, requireAuth, permission }) => {
        if (requireAuth) {
            // 如果需要認證，先檢查 auth
            if (auth) {
                // 如果有 permission，需要檢查權限
                if (permission && !auth?.permissionList?.includes(permission)) {
                    return (
                        <Route
                            key={title}
                            path={path} // 使用具體的路徑而不是通配符
                            element={
                                <Layout>
                                    <NotFoundPage />
                                </Layout>
                            }
                        />
                    );
                }

                // 如果沒有 permission 或者有相應的權限
                return (
                    <Route element={<RequireAuth />} key={title}>
                        <Route path={path} element={element} />
                    </Route>
                );
            } else {
                return (
                    <Route
                        key={title}
                        path={path} // 使用具體的路徑而不是通配符
                        element={
                            <Layout>
                                <NotFoundPage />
                            </Layout>
                        }
                    />
                );
            }
        } else {
            return <Route key={title} path={path} element={element} />;
        }
    });

    return (
        <Routes>
            {pageRoutes}
            <Route
                path='*'
                element={
                    <Layout>
                        <NotFoundPage />
                    </Layout>
                }
            />
        </Routes>
    );
};

export default Router;
