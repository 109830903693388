import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import useAuth from './useAuth';
import Swal from 'sweetalert2';

const BASE_URL = '/web';

const axiosInstance = axios.create({
    baseURL: BASE_URL,
    timeout: 5000,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true,
});

const useAxios = () => {
    const { auth, setAuth } = useAuth();
    const requestInterceptor = useRef(null);
    const responseInterceptor = useRef(null);

    const [state, setState] = useState({
        responses: {},
        loadings: {},
        errors: {},
    });

    useEffect(() => {
        requestInterceptor.current = axiosInstance.interceptors.request.use(
            (config) => {
                if (auth?.accessToken) {
                    config.headers['accessToken'] = auth.accessToken;
                }
                return config;
            },
            (error) => Promise.reject(error)
        );

        responseInterceptor.current = axiosInstance.interceptors.response.use(
            (response) => {
                const newAccessToken = response.headers.accesstoken;
                if (newAccessToken) {
                    setAuth((prevAuth) => ({
                        ...prevAuth,
                        accessToken: newAccessToken,
                    }));
                }
                return response;
            },
            (error) => Promise.reject(error)
        );

        return () => {
            axiosInstance.interceptors.request.eject(requestInterceptor.current);
            axiosInstance.interceptors.response.eject(responseInterceptor.current);
        };
    }, [auth, setAuth]);

    const operation = async (params, identifier, retries = 3) => {
        let attempts = 0;
        let success = false;
        let error = null;

        // Show loading message
        // Swal.fire({
        //     title: '正在載入',
        //     icon: 'warning',
        //     showCancelButton: false,
        //     confirmButtonColor: '#1278e9',
        //     confirmButtonText: '更新',
        //     allowOutsideClick: false,
        //     didOpen: () => {
        //         Swal.showLoading();
        //     },
        // });

        while (attempts < retries && !success) {
            try {
                setState((prev) => ({
                    ...prev,
                    loadings: { ...prev.loadings, [identifier]: true },
                    errors: { ...prev.errors, [identifier]: null }, // 清除之前的錯誤
                }));
                // await new Promise((resolve) => setTimeout(resolve, 3000)); // 測試延遲
                const result = await axiosInstance.request(params);
                setState((prev) => ({
                    ...prev,
                    responses: { ...prev.responses, [identifier]: result },
                    loadings: { ...prev.loadings, [identifier]: false },
                }));
                success = true;
                return { success: true, response: result };
            } catch (err) {
                console.error(`[${identifier}] 發生錯誤:`, err);
                attempts += 1;
                error = err;
                setState((prev) => ({
                    ...prev,
                    loadings: { ...prev.loadings, [identifier]: false },
                    errors: { ...prev.errors, [identifier]: err },
                }));
            } finally {
                // Swal.close();
            }
        }

        if (error) {
            if (attempts >= retries) {
                console.log('已經錯誤三次了');
            }
            return { success: false, error };
        }

        return null;
    };

    const isAnyLoading = () => Object.values(state.loadings).some((loading) => loading);
    const hasAnyError = () => Object.values(state.errors).some((error) => error);

    const { responses, loadings, errors } = state;

    return { responses, loadings, errors, operation, isAnyLoading, hasAnyError };
};

export default useAxios;
