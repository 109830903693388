import './downloadCenterUpdateDetail.scss';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import useAxios from '../../../hooks/useAxios';
import { checkAndSetFile } from '../checkAndSetFile';
import Swal from 'sweetalert2';
import { LoadingAnimate } from '../loadingAnimate/LoadingAnimate';

const DownloadCenterUpdateDetail = () => {
    const navigate = useNavigate();
    const { operation } = useAxios();

    const [manualName, setManualName] = useState('');
    const [manualFile, setManualFile] = useState(null);
    const [manualFileSize, setManualFileSize] = useState('');
    const [category, setCategory] = useState('');
    const [displayOrder, setDisplayOrder] = useState('');
    const [releaseTime, setReleaseTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [status, setStatus] = useState('');
    const [rowStamp, setRowStamp] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    // console.log(manualFile);
    // console.log(manualFileSize);

    const paramsGuid = useParams('manualGuid');
    const isEditPage = Object.keys(paramsGuid).length === 0 ? false : true;
    // console.log(paramsGuid);
    // console.log(isEditPage);

    //edit getInfo
    useEffect(() => {
        if (isEditPage)
            (async () => {
                const { success, response, error } = await operation(
                    {
                        url: `/backstage/manual/${paramsGuid.manualGuid}`,
                    },
                    'getInfo'
                );
                if (success) {
                    const manualObj = response.data.result;
                    // console.log(manualObj);
                    const releaseTimeFormat =
                        manualObj.releaseTime.toLocaleString().split(':')[0] +
                        ':' +
                        manualObj.releaseTime.toLocaleString().split(':')[1];
                    const endTimeFormat =
                        manualObj.endTime.toLocaleString().split(':')[0] +
                        ':' +
                        manualObj.endTime.toLocaleString().split(':')[1];
                    setManualName(manualObj.title);
                    setManualFileSize(manualObj.fileSize);
                    setCategory(manualObj.category);
                    setDisplayOrder(manualObj.displayOrder);
                    setReleaseTime(releaseTimeFormat);
                    setEndTime(endTimeFormat);
                    setStatus(manualObj.status);
                    setRowStamp(manualObj.rowStamp);
                } else if (error) {
                    console.log(error);
                }
            })();
    }, [paramsGuid]);

    // edit delete
    const handleDeleteItem = async () => {
        await Swal.fire({
            html: `<h4>確定要刪除?</h4>`,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: '取消',
            showConfirmButton: true,
            confirmButtonText: '確定',
            allowOutsideClick: false,
            allowEscapeKey: false,
        }).then(async (res) => {
            if (res.dismiss === 'cancel') {
                console.log('cancelled');
                return;
            } else {
                console.log('confirmed');
                const { success, response, error } = await operation(
                    {
                        url: `/backstage/manual/${paramsGuid.manualGuid}`,
                        method: 'DELETE',
                    },
                    'handleDeleteItem'
                );
                if (success) {
                    navigate('/downloadcenterupdate');
                } else if (error) {
                    console.log(error);
                }
            }
        });
    };

    // edit submit
    const handleSubmit = async (e) => {
        e.preventDefault();
        const form = new FormData(e.target);

        if (isEditPage) {
            //edit
            setManualFile(null);
            setIsLoading(true);
            const { success, response, error } = await operation(
                {
                    url: `/backstage/manual/${paramsGuid.manualGuid}`,
                    method: 'PUT',
                    data: form,
                },
                'handleCreate'
            );
            if (success) {
                // console.log(response.data);
                setIsLoading(false);
                navigate('/downloadcenterupdate');
            } else if (error) {
                setIsLoading(false);
                Swal.fire({
                    icon: 'error',
                    title: '網路不穩，儲存失敗',
                    text: '請再次儲存。',
                });
                console.log(error);
            }
        } else {
            //create
            if (manualFile === null) {
                Swal.fire({
                    icon: 'info',
                    title: '請上傳pdf檔案',
                });
            } else {
                setIsLoading(true);
                console.log(form);
                const { success, response, error } = await operation(
                    {
                        url: `/backstage/manual`,
                        method: 'POST',
                        data: form,
                    },
                    'handleCreate'
                );
                if (success) {
                    // console.log(response.data);
                    setIsLoading(false);
                    navigate('/downloadcenterupdate');
                } else if (error) {
                    setIsLoading(false);
                    Swal.fire({
                        icon: 'error',
                        title: '網路不穩，儲存失敗',
                        text: '請再次儲存。',
                    });
                    console.log(error);
                }
            }
        }
    };

    return (
        <>
            <LoadingAnimate isLoading={isLoading} />

            <section id='section-main'>
                <form id='container-downloadCenterUpdateDetail' onSubmit={handleSubmit}>
                    <div className='d-flex'>
                        <h5 className='text-grey4 fw-bold mb-4'>產品說明書</h5>
                        <button
                            className={`btnDelete btn btn-outline-danger px-4 ms-auto me-2 ${!isEditPage && 'd-none'}`}
                            onClick={(e) => {
                                e.preventDefault();
                                handleDeleteItem();
                            }}
                            type='button'
                        >
                            刪除
                        </button>
                        <button
                            className={`btnCancel btn btn-outline-primary px-4 me-2 ${!isEditPage && 'ms-auto'}`}
                            onClick={(e) => {
                                e.preventDefault();
                                navigate('/downloadcenterupdate');
                            }}
                            type='button'
                        >
                            取消
                        </button>
                        <button className='btn btn-primary text-white px-4' type='submit'>
                            儲存
                        </button>
                    </div>
                    <div className='manageFileWrapper bg-white p-4 rounded-4 mb-3'>
                        <input id='rowStamp' name='rowStamp' className='d-none' defaultValue={rowStamp} />
                        <h6 className='text-grey4 fw-bold mb-3'>檔案管理</h6>
                        <h6 className='fontSize75Rem mb-2'>說明書名稱</h6>
                        <input
                            id='title'
                            name='title'
                            className={`form-control mb-3 w-75 
							}`}
                            value={manualName || ''}
                            onChange={(e) => setManualName(e.target.value)}
                            required
                        />
                        <h6 className='fontSize75Rem mb-3'>附加檔案 |</h6>
                        <h6 className='fontSize75Rem mb-2'>說明書</h6>
                        <div className='fileBox d-flex align-items-center mb-2'>
                            <div className={`fileView d-flex bg-grey1 text-primary px-2 py-1 ms-0 w-25 rounded-3`}>
                                <span className={`fontSize75Rem ${manualFile === null && !isEditPage && 'd-none'}`}>
                                    {manualName + '.pdf'}
                                </span>
                            </div>
                            <label htmlFor='file'>
                                <div className='btn btn-primary text-white px-4 py-1'>
                                    <svg
                                        id='icn_Create'
                                        className='me-2 mb-1'
                                        viewBox='0 0 12 12'
                                        width='12'
                                        height='12'
                                        fill='#fff'
                                    >
                                        {' '}
                                        <defs> </defs>{' '}
                                        <path
                                            className='cls-1'
                                            id='_Color'
                                            d='M 12 6.857 H 6.857 V 12 H 5.143 V 6.857 H 0 V 5.143 H 5.143 V 0 H 6.857 V 5.143 H 12 Z'
                                            data-name=' ↳Color'
                                        ></path>
                                    </svg>
                                    <span>上傳檔案</span>
                                </div>
                                <input
                                    type='file'
                                    className='form-control d-none'
                                    id='file'
                                    name='file'
                                    onChange={(e) => {
                                        if (checkAndSetFile(e.target, ['.pdf'])) {
                                            setManualFile(e.target.files[0]);

                                            // bytes轉MB 取到小數第二位四捨五入
                                            let fileSize =
                                                Math.round((parseInt(e.target.files[0].size, 10) / 1024 / 1024) * 100) /
                                                100;

                                            setManualFileSize(fileSize);

                                            console.log(parseInt(e.target.files[0].size, 10) / 1024 / 1024);
                                        }
                                    }}
                                />
                            </label>
                        </div>
                        <h6 className='fontSize75Rem text-grey3 mb-3'>以大小在 50 MB 以內的PDF檔案格式上傳。</h6>
                        <div className='row mb-3'>
                            <div className='col-9'>
                                <h6 className='fontSize75Rem mb-2'>說明書位址</h6>
                                <div className='fileView bg-grey1 px-2 py-1 rounded-3'>
                                    <span className='fontSize75Rem'>
                                        {`\\storage\\download\\manual\\${manualName || '說明書名稱'}.pdf`}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-9'>
                                <h6 className='fontSize75Rem mb-2'>檔案大小(MB)</h6>
                                <div className='fileView bg-grey1 px-2 py-1 rounded-3'>
                                    <span className='fontSize75Rem'>{manualFileSize}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='infoWrapper bg-white p-4 rounded-4'>
                        <div className='infoTop d-flex align-items-center mb-3 '>
                            <h6 className='text-grey4 fw-bold mb-0'>檔案資訊</h6>
                        </div>
                        <div className='infoBottom row'>
                            <div className='col-4'>
                                <label htmlFor='category' className='fontSize75Rem mb-2'>
                                    型態
                                </label>
                                <select
                                    className='form-control mb-3 '
                                    name='category'
                                    id='category'
                                    onChange={(e) => setCategory(e.target.value)}
                                    value={category || ''}
                                    required
                                >
                                    <option value='' disabled>
                                        -- 請選擇 --
                                    </option>
                                    <option value='0'>產品</option>
                                    <option value='1'>教學</option>
                                    <option value='2'>活動</option>
                                </select>
                                <label htmlFor='displayOrder' className='fontSize75Rem mb-2'>
                                    顯示順序
                                </label>
                                <input
                                    type='number'
                                    id='displayOrder'
                                    name='displayOrder'
                                    className='form-control'
                                    value={displayOrder || ''}
                                    onChange={(e) => setDisplayOrder(e.target.value)}
                                    required
                                />
                            </div>
                            <div className='col-4'>
                                <label htmlFor='releaseTime' className='fontSize75Rem mb-2'>
                                    發佈時間
                                </label>
                                <input
                                    type='datetime-local'
                                    id='releaseTime'
                                    name='releaseTime'
                                    className='form-control mb-3'
                                    value={releaseTime || ''}
                                    onChange={(e) => setReleaseTime(e.target.value)}
                                    required
                                />
                                <label htmlFor='endTime' className='fontSize75Rem mb-2'>
                                    結束時間
                                </label>
                                <input
                                    type='datetime-local'
                                    id='endTime'
                                    name='endTime'
                                    className='form-control'
                                    value={endTime || ''}
                                    onChange={(e) => setEndTime(e.target.value)}
                                    required
                                />
                            </div>
                            <div className='col-4'>
                                <label htmlFor='status' className='fontSize75Rem mb-2'>
                                    狀態
                                </label>
                                <select
                                    className='form-control'
                                    name='status'
                                    id='status'
                                    onChange={(e) => setStatus(e.target.value)}
                                    value={status || ''}
                                    required
                                >
                                    <option value='' disabled>
                                        -- 請選擇 --
                                    </option>
                                    <option value='0'>停用</option>
                                    <option value='1'>啟用</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </form>
            </section>
        </>
    );
};

export default DownloadCenterUpdateDetail;
