import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import axios from 'axios';
import SelectDescriptionImageModal from './SelectDescriptionImageModal';

const Textarea = styled.textarea`
    width: 100%;
    height: 300px;
    resize: none;
    white-space: pre-wrap || pre-line;
`;
// 商品內容編輯的 Modal 組件 SalesKitContentModal

// 接受的 props:
// - `content`: 商品內容的原始內容
// - `data`: 整個頁面的狀態數據
// - `setData`: 用於更新頁面狀態的函數
// - `modalOpen`: 控制 Modal 是否顯示的狀態
// - `setModalOpen`: 用於更新 Modal 顯示狀態的函數

// 在組件內部，使用 `useState` 定義一個狀態 `copyContent`，初始值為 `null`。
// 定義 `replaceWithBr` 函數，用於將 `<br />` 換行符號轉換為實際的換行符號 `\n`。
// 定義 `handleUpdateNote` 函數，將 `copyContent` 中的換行符號 `\n` 轉換為 `<br />`，然後更新 `data` 中 `salesKit` 的 `content` 屬性，最後關閉 Modal。
// 使用 `useEffect` 鉤子，初始化 `copyContent` 的值為傳入的 `content`。
// Modal 包含一個標題、一個文本區域（Textarea）用於編輯商品內容，以及確定和取消兩個按鈕。
// 使用 `Textarea` 組件來輸入商品內容，並使用 `onChange` 事件監聽器來實時更新 `copyContent` 的狀態。

// 注意：具體的樣式和 Modal 結構實現未提供，需要參考具體的項目需求進行實現。

export default function SalesKitContentModal({ content, data, setData, modalOpen, setModalOpen }) {
    console.log('reload SalesKitContentModal');
    const [copyContent, setCopyContent] = useState(null);
    const [selectDescriptionImageModalOpen, setSelectDescriptionImageModalOpen] = useState(false);
    function replaceWithBr(string) {
        return string.replace(/<br \/>/g, '\n');
    }

    const handleUpdateNote = () => {
        const newValue = copyContent.replace(/\n/g, '<br />');

        setData({ ...data, salesKit: { ...data.salesKit, content: newValue } });
        setModalOpen(false);
    };

    useEffect(() => {
        setCopyContent(content);
    }, [data]);

    return (
        <Modal className='salesKitContentModal modal' show={modalOpen} onHide={() => setModalOpen(false)} centered>
            <Modal.Header closeButton className='border-bottom'>
                <Modal.Title>修改商品內容</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div
                    className='text-primary ms-auto mb-2 text-decoration-underline'
                    onClick={() => setSelectDescriptionImageModalOpen(true)}
                >
                    從商品圖庫中選擇
                </div>
                <Textarea
                    name='description'
                    value={copyContent}
                    onChange={(e) => {
                        setCopyContent(e.target.value);

                        const newlineCount = (e.target.value.match(/\n/g) || []).length;
                        console.log('换行符数量：', newlineCount);
                    }}
                />
            </Modal.Body>
            <Modal.Footer className='justify-content-center mx-4'>
                <button
                    type='button'
                    className='btn btn-primary text-white px-4 me-2'
                    // onClick={() => {
                    //     checkVerticalValue(editDrillItemData.vertical) &&
                    //         checkHorizontalValue(
                    //             editDrillItemData.horizontal
                    //         ) &&
                    //         checkHighSpeedValue(editDrillItemData.highSpeed) &&
                    //         handleEditDrillItemSubmit();
                    // }}
                    onClick={handleUpdateNote}
                >
                    確定
                </button>
                <button
                    type='button'
                    className='btnCancel btn btn-outline-primary px-4'
                    onClick={() => setModalOpen(false)}
                >
                    取消
                </button>
            </Modal.Footer>

            {selectDescriptionImageModalOpen && (
                <SelectDescriptionImageModal
                    content={data?.salesKit?.content}
                    data={data}
                    setData={setData}
                    modalOpen={selectDescriptionImageModalOpen}
                    setModalOpen={setSelectDescriptionImageModalOpen}
                />
            )}
        </Modal>
    );
}
