import './login.scss';
import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Visibility, VisibilityOff, Sync } from '@material-ui/icons';
import Captcha from 'react-captcha-code';
import { randomNum, originalCharacter } from '../../../components/utils/utils';
import { useTranslation } from 'react-i18next';
import useAuth from '../../../hooks/useAuth';

function Login() {
    useEffect(() => {
        console.log('login effect');
        console.log('process.env.NODE_ENV: ', process.env.NODE_ENV);
        // Check if the current environment is production
        if (process.env.NODE_ENV === 'production') {
            // Get the current hostname
            const currentHostname = window.location.hostname;
            console.log(currentHostname);

            // Check if the current hostname is 'pingtex.tw'
            if (currentHostname === 'pingtex.tw') {
                // Redirect to 'https://www.pingtex.tw'
                window.location.href = 'https://www.pingtex.tw' + window.location.pathname + window.location.search;
            }

            // Check if the current hostname is 'synerter.com'
            if (currentHostname === 'synerter.com') {
                // Redirect to 'https://www.synerter.com'
                window.location.href = 'https://www.synerter.com' + window.location.pathname + window.location.search;
            }
        }
    }, []);

    const { t } = useTranslation(['login']); // i18n

    const [captchaUserInput, setCaptchaUserInput] = useState('');
    const [captcha, setCaptcha] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [loginStyle, setLoginStyle] = useState('emailID');

    const emailID = useRef();
    const countryCode = useRef();
    const phoneCode = useRef();
    const password = useRef();

    const navigate = useNavigate();
    const location = useLocation();

    const { setAuth, login } = useAuth();

    // 即時檢查帳號
    const emailReg = /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/;
    const IDReg = /^[A-Za-z0-9]{6,15}$/;
    const phoneCodeReg = /^[0-9]{6,15}$/;

    const checkUserAccount = () => {
        if (emailID.current.value) {
            if (emailID.current.value.includes('@')) {
                if (!emailReg.test(emailID.current.value)) {
                    document.querySelector('#emailID-info').textContent = t('format_error');
                    document.querySelector('#emailID').style.borderColor = '#CC3300';
                    return 1;
                } else {
                    document.querySelector('#emailID-info').textContent = '';
                    document.querySelector('#emailID').style.borderColor = '#ced4da';
                    return 0;
                }
            } else {
                if (!IDReg.test(emailID.current.value)) {
                    document.querySelector('#emailID-info').textContent = t('format_error');
                    document.querySelector('#emailID').style.borderColor = '#CC3300';
                    return 1;
                } else {
                    document.querySelector('#emailID-info').textContent = '';
                    document.querySelector('#emailID').style.borderColor = '#ced4da';
                    return 0;
                }
            }
        } else if (phoneCode.current.value) {
            if (!phoneCodeReg.test(phoneCode.current.value)) {
                document.querySelector('#phoneCode-info').textContent = t('format_error');
                document.querySelector('#phoneCode').style.borderColor = '#CC3300';
                return 1;
            } else {
                document.querySelector('#phoneCode-info').textContent = '';
                document.querySelector('#phoneCode').style.borderColor = '#ced4da';
                return 0;
            }
        }
    };

    const handleCaptchaRefresh = useCallback(() => {
        let str = '';
        for (let i = 0; i < 4; i++) {
            const temp = originalCharacter[randomNum(0, originalCharacter.length - 1)];
            str = `${str}${temp}`;
        }
        setCaptcha(str);
        setCaptchaUserInput('');
    }, []);

    useEffect(() => {
        handleCaptchaRefresh();
    }, [handleCaptchaRefresh]);

    // 圖形驗證碼-驗證圖形驗證碼
    const checkCaptcha = () => {
        if (captchaUserInput !== captcha) {
            document.querySelector('#captcha-info').textContent = t('verification_code_error');
            document.querySelector('#captcha-info').style.borderColor = '#CC3300';
            handleCaptchaRefresh();
            return 1;
        } else {
            document.querySelector('#captcha-info').textContent = '';
            document.querySelector('#captcha').style.borderColor = '#ced4da';
            return 0;
        }
    };

    // 密碼顯示切換
    function switchPassword() {
        setShowPassword(!showPassword);
    }

    // 切換登入帳號類型
    function loginChange(e) {
        e.preventDefault();

        if (loginStyle === 'emailID') {
            setLoginStyle('phone');
            emailID.current.value = '';
            document.querySelector('#phoneCode-info').textContent = '';
            document.querySelector('#phoneCode').style.borderColor = '#ced4da';
        } else if (loginStyle === 'phone') {
            setLoginStyle('emailID');
            phoneCode.current.value = '';
            document.querySelector('#emailID-info').textContent = '';
            document.querySelector('#emailID').style.borderColor = '#ced4da';
        }
    }

    // 登入事件
    const handleSubmit = async (e) => {
        e.preventDefault();

        const emailIDPost = {
            accountType: '0',
            emailOrID: emailID.current.value,
            password: password.current.value,
        };

        const phonePost = {
            accountType: '1',
            countryCode: countryCode.current.value,
            phoneCode: phoneCode.current.value,
            password: password.current.value,
        };

        try {
            //空欄位 錯誤訊息顯示
            if (emailID.current.value === '') {
                document.querySelector('#emailID-info').textContent = t('cannot_be_empty');
                document.querySelector('#emailID').style.borderColor = '#CC3300';
            } else {
                checkUserAccount();
            }

            if (phoneCode.current.value === '') {
                document.querySelector('#phoneCode-info').textContent = t('cannot_be_empty');
                document.querySelector('#phoneCode').style.borderColor = '#CC3300';
            } else {
                checkUserAccount();
            }

            if (password.current.value === '') {
                document.querySelector('#password-info').textContent = t('cannot_be_empty');
                document
                    .querySelectorAll('#password, #passwordSwitch')
                    .forEach((e) => (e.style.borderColor = '#CC3300'));

                handleCaptchaRefresh();
            } else {
                document.querySelector('#password-info').textContent = '';
                document
                    .querySelectorAll('#password, #passwordSwitch')
                    .forEach((e) => (e.style.borderColor = '#ced4da'));
            }

            if (captchaUserInput === '') {
                document.querySelector('#captcha-info').textContent = t('cannot_be_empty');
                document.querySelector('#captcha').style.borderColor = '#CC3300';
            } else {
                document.querySelector('#captcha-info').textContent = '';
                document.querySelector('#phoneCode').style.borderColor = '#ced4da';
            }

            // 登入API
            if (
                (emailID.current.value !== '' || phoneCode.current.value !== '') &&
                password.current.value !== '' &&
                captchaUserInput !== ''
            ) {
                if (checkUserAccount() === 0 && checkCaptcha() === 0) {
                    const loginData = loginStyle === 'emailID' ? emailIDPost : phonePost;
                    const { success, response } = await login(loginData);
                    const { headers, data } = response;

                    //帳號或密碼錯誤
                    if (data.status === '1') {
                        if (loginStyle === 'emailID') {
                            document.querySelector('#emailID-info').textContent = t('incorrect_account_or_password');
                            document.querySelector('#emailID').style.borderColor = '#CC3300';
                        } else if (loginStyle === 'phone') {
                            document.querySelector('#phoneCode-info').textContent = t('incorrect_account_or_password');

                            document.querySelector('#phoneCode').style.borderColor = '#CC3300';
                        }

                        handleCaptchaRefresh();
                        //帳號尚未啟用
                    } else if (data.status === '2') {
                        if (loginStyle === 'emailID') {
                            document.querySelector('#emailID-info').textContent = t('account_not_activated');
                            document.querySelector('#emailID').style.borderColor = '#CC3300';
                        } else if (loginStyle === 'phone') {
                            document.querySelector('#phoneCode-info').textContent = t('account_not_activated');
                            document.querySelector('#phoneCode').style.borderColor = '#CC3300';
                            handleCaptchaRefresh();
                        }
                        //登入成功

                        // TODO: role 需要嗎
                    } else if (data.status === '0') {
                        const accessToken = headers?.accesstoken;
                        const permissionList = data.permissionList;
                        const user = data?.user;
                        const role = data?.role;

                        setAuth((prev) => ({
                            ...prev,

                            role,
                            accessToken,
                            permissionList,
                            user,
                        }));

                        // 有要轉的地址
                        if (location.search) {
                            const to = location.search.split('=')[1];
                            navigate(`/${to}`);
                        } else if (location?.state?.from) {
                            navigate(`${location.state.from}`);
                        } else {
                            navigate(`/baseInfo`);
                        }
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className='container-fluid p-0'>
            <article
                id='container-login'
                className='py-lg-3 d-flex flex-column align-items-center justify-content-lg-center bg-background '
            >
                <div className='articleBox d-flex flex-column justify-content-start'>
                    <h2 className='pageTitle'>{t('account_login')}</h2>
                    <section id='article-login'>
                        <form className='row g-3' autoComplete='false' noValidate onSubmit={handleSubmit}>
                            {/*<!-- email/ID -->*/}
                            <div className={`col-12 ${loginStyle !== 'emailID' && 'd-none'}`}>
                                {/* <label className="form-label" htmlFor="emailID">
									<FormattedMessage
										id="auth.emailID"
										defaultMessage="电子邮箱/ID"
									/>
								</label> */}
                                <input
                                    ref={emailID}
                                    type='text'
                                    className='form-control form-control-lg'
                                    id='emailID'
                                    name='emailID'
                                    placeholder={t('email_bingled_username')}
                                    onBlur={checkUserAccount}
                                    required
                                />
                                <div className='invalid-feedback' id='emailID-info'></div>
                            </div>

                            {/*  電話  */}
                            <div className={`col-12 ${loginStyle !== 'phone' && 'd-none'}`}>
                                {/* <label
									className="form-label"
									htmlFor="phoneCode"
								>
									<FormattedMessage
										id="auth.phoneNumber"
										defaultMessage="手机号"
									/>
								</label> */}
                                <div className='row'>
                                    <div className='col-4 col-lg-4'>
                                        <select
                                            ref={countryCode}
                                            className='form-select form-select-lg'
                                            id='countryCode'
                                            name='countryCode'
                                        >
                                            <option value='886'>+886</option>
                                            <option value='852'>+852</option>
                                            <option value='86'>+86</option>
                                        </select>
                                    </div>
                                    <div className='col-8 col-lg-8'>
                                        <input
                                            ref={phoneCode}
                                            type='text'
                                            className='form-control form-control-lg'
                                            id='phoneCode'
                                            name='phoneCode'
                                            placeholder={t('phone_number')}
                                            pattern='^[0-9]*$'
                                            disabled={loginStyle === 'email'}
                                            onBlur={checkUserAccount}
                                        />
                                        <div className='invalid-feedback' id='phoneCode-info'></div>
                                    </div>
                                </div>
                            </div>

                            {/*<!-- 密碼 -->*/}
                            <div className='col-12'>
                                {/* <label
									className="form-label"
									htmlFor="password"
								>
									<FormattedMessage
										id="auth.password"
										defaultMessage="密碼"
									/>
								</label> */}
                                <div className='input-group'>
                                    <input
                                        ref={password}
                                        type={showPassword ? 'text' : 'password'}
                                        className='form-control form-control-lg'
                                        id='password'
                                        name='password'
                                        placeholder={t('password')}
                                        pattern='^[a-zA-Z0-9]*$'
                                        minLength='6'
                                        maxLength='16'
                                        required
                                        aria-describedby='passwordSwitch'
                                        autoComplete='false'
                                    />

                                    <button
                                        type='button'
                                        className='btn btn-color-outline-auxiliary'
                                        id='passwordSwitch'
                                        tabIndex='-1'
                                        onClick={switchPassword}
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </button>
                                </div>
                                <div className='invalid-feedback' id='password-info'></div>
                            </div>

                            {/*<!-- 圖形驗證碼 -->*/}
                            <div className='col-12'>
                                {/* <label className="form-label" htmlFor="captcha">
									<FormattedMessage
										id="auth.verificationCode"
										defaultMessage="驗證碼"
									/>
								</label> */}
                                <div className='d-flex align-items-center'>
                                    <input
                                        type='text'
                                        className='form-control form-control-lg '
                                        id='captcha'
                                        name='captcha'
                                        placeholder={t('verification_code')}
                                        required
                                        value={captchaUserInput}
                                        onChange={(e) => setCaptchaUserInput(e.target.value)}
                                    />

                                    <div className='px-3 captchaDiv'>
                                        <Captcha
                                            code={captcha}
                                            bgColor='#ffe'
                                            height='48'
                                            onClick={handleCaptchaRefresh}
                                        />
                                    </div>

                                    <button
                                        type='button'
                                        className='refreshBtn btn  btn-color-auxiliary d-flex justify-content-center align-items-center'
                                        onClick={handleCaptchaRefresh}
                                    >
                                        <Sync />
                                    </button>
                                </div>
                                <div className='invalid-feedback' id='captcha-info'></div>
                            </div>

                            {/*<!-- 切換登入 -->*/}
                            <div className='col-12 loginToggleDiv'>
                                <div className='text-end'>
                                    <Link
                                        to=''
                                        className={`text-decoration-none ${loginStyle !== 'emailID' && 'd-none'}`}
                                        onClick={loginChange}
                                    >
                                        {t('phone_login')}
                                    </Link>
                                    <Link
                                        to=''
                                        className={`text-decoration-none ${loginStyle !== 'phone' && 'd-none'}`}
                                        onClick={loginChange}
                                    >
                                        {t('email_id_login')}
                                    </Link>
                                </div>
                            </div>

                            {/*<!-- 登入 -->*/}
                            <div className='col-12 text-center mb-0 mb-lg-3'>
                                <button
                                    type='submit'
                                    className='w-100 btn btn-lg btn-color-main rounded-pill'
                                    id='btn-login'
                                >
                                    {t('login')}
                                </button>
                            </div>

                            {/*<!-- 切換登入 / 註冊 -->*/}
                            <div className='col-12'>
                                <div className='changeBox d-flex justify-content-center align-items-center'>
                                    <Link className='text-decoration-none pe-3 border-end' to='/register'>
                                        {t('register')}
                                    </Link>
                                    <Link className='text-decoration-none ps-3' to='/forgotPwd'>
                                        {t('forgot_password')}
                                    </Link>
                                </div>
                            </div>
                        </form>
                    </section>
                </div>
            </article>
        </div>
    );
}

export default Login;
