import './style.scss';
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import useAxios from '../../hooks/useAxios';
import { useTranslation } from 'react-i18next';

const PdCreditCard = () => {
    const { t } = useTranslation('pdCheckoutInfo'); // i18n
    const { operation } = useAxios();

    const { orderGuid } = useParams('orderGuid');
    const navigate = useNavigate();

    const [data, setData] = useState({
        lidm: '',
        purchAmt: '',
        merchantID: '',
        terminalID: '',
        merID: '',
        frontFailUrl: '',
        authResURL: '',
    });

    const getBasicInfo = async () => {
        const { success, response, error } = await operation(
            {
                url: `/payment/getCreditCardInfo/${orderGuid}`,
            },
            'getBasicInfo'
        );

        if (success) {
            // console.log(response.data);
            setData(response.data.result);
        } else if (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getBasicInfo();
    }, []);

    // console.log(data);

    return (
        <section id='section-main'>
            <div id='container-pdCreditCard'>
                <div className='articleBoxWithSideBox px-xl-5 px-lg-5 px-md-0 px-sm-0'>
                    <h2 className='pageTitle pageTitle_main mb-3'>
                        {t('order_placed_click_button_to_view_order_details')}
                    </h2>
                    <h5 className='pageTitle pageTitle_sub mb-sm-9 mb-lg-10'>
                        {t('order_number')}：{data.lidm}
                    </h5>
                    <div className='paymentInstructions py-2'>
                        <p className='text-center mb-0' style={{ fontSize: '20px' }}>
                            {t('go_to_my_orders')}
                        </p>
                    </div>
                    <div className='paymentInstructionsIn'>
                        <p className='text-start text-sm-center fontSize875Rem'>
                            {t('hello_to_ensure_order_processing')}
                        </p>
                        <div className='d-flex justify-content-center align-items-center'>
                            <button
                                className='d-flex justify-content-center align-items-center btn btn-size-l btn-color-main rounded-pill text-white mr-4 '
                                onClick={() => navigate('/orders')}
                            >
                                {t('my_orders')}
                            </button>
                        </div>
                    </div>
                    <div className='searchTableWrapper bg-white p-2 rounded-4'>
                        <div className='searchTableBottom'>
                            <section>
                                <table className='firmwareListTable table table-hover' id='table-drill'>
                                    <tfoot>
                                        <tr>
                                            <td colSpan='2' className='border-0 pb-0 fontSize125Rem text-grey3'>
                                                {t('having_problems_with_payment')}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan='2' className='border-0 pt-2'>
                                                {t('provide_order_number_to_customer_service')}
                                                ：02-8866-6008
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PdCreditCard;
