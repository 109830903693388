import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import useAxios from '../../../hooks/useAxios';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import OrderGoodsDetails from './OrderGoodsDetails';
import OrderContentDetails from './OrderContentDetails';
import OrderPaymentDetails from './OrderPaymentDetails';
import CancelOrderModal from '../Modals/CancelOrderModal';
import { ReactComponent as Icn_chevronLeft_black } from '../../../assets/images/icn_chevron-left_black.svg';
import { AccountContext } from '../../../App';

// #region styled-components
const OrderContainer = styled.article`
    max-width: 1216px;
    width: 100%;
    margin: auto;
`;

const Wrapper = styled.div`
    min-height: 752px;
    border: 1px solid #dadada;
    border-radius: 30px;

    @media only screen and (max-width: 1020px) {
        border: none;
    }

    @media only screen and (max-width: 578px) {
        background-color: #f8f8f8;
    }
`;

const PageTitle = styled.div`
    font-size: 20px;
    padding: 2rem;

    @media only screen and (max-width: 458px) {
        padding: 0 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`;

const DetailsWrapper = styled.div`
    @media only screen and (max-width: 578px) {
        background-color: #f8f8f8;
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    margin-top: 2rem;
    justify-content: ${(props) => (props.singleButton ? 'center' : 'end')};
    align-items: center;

    @media only screen and (min-width: 576px) and (max-width: 1020px) {
        flex-direction: column-reverse;
    }
`;

const OrderButton = styled.button`
    width: 146px;

    &:hover {
        color: white;
    }

    @media only screen and (min-width: 576px) and (max-width: 1020px) {
        width: 100%;
        border-radius: 28px;
    }
`;

const OrderRoundedButton = styled(OrderButton)`
    border-radius: 24px;
    width: 236px;

    @media only screen and (max-width: 1020px) {
        width: 100%;
    }
`;

const UnderlinedTextButton = styled.div`
    margin-left: 8px;
    color: var(--primary);
    text-decoration: underline;
    cursor: not-allowed;

    @media only screen and (min-width: 576px) and (max-width: 1020px) {
        width: 100%;
        margin-top: 20px;
        margin-left: 0;
        text-align: center;
    }
`;

const BackToPrevButton = styled.div`
    cursor: pointer;
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
`;
// #endregion

export default function OrderDetails() {
    const { t } = useTranslation(['order']); // i18n
    const { operation } = useAxios();

    const navigate = useNavigate();
    const { lang } = useContext(AccountContext);

    const { orderGuid } = useParams('orderGuid');
    const [isGettingData, setIsGettingData] = useState(true);
    const [order, setOrder] = useState(null);

    // modals open
    const [cancelModalOpen, setCancelModalOpen] = useState(false);

    //#region [function]
    const handleRebuyOrder = async (orderGuid) => {
        const { success, response, error } = await operation(
            {
                url: '/salSalesOrder/rebuySalesOrder',
                method: 'POST',
                data: orderGuid,
            },
            'handleRebuyOrder'
        );

        if (success) {
            console.log(response);
            return navigate(`/cart`);
        } else if (error) {
            console.log(error);
        }
    };

    const getList = useCallback(() => {
        const gettingList = async () => {
            const { success, response, error } = await operation(
                {
                    url: `/salSalesOrder/getSalesOrder/${orderGuid}`,
                },
                'gettingList'
            );
            if (success) {
                console.log(response.data.result);
                setOrder(response.data.result);
                setIsGettingData(false);
            } else if (error) {
                console.log(error);
            }
        };

        gettingList();
    }, []);

    useEffect(() => {
        getList();
        // sortTable(5)
    }, [getList]);

    const handleCancelOrder = async (orderGuid) => {
        const { success, response, error } = await operation(
            {
                url: '/salSalesOrder/cancelSalesOrder',
                method: 'POST',
                data: orderGuid,
            },
            'handleCancelOrder'
        );
        if (success) {
            console.log(response);
            alert('你已成功取消訂單');

            return navigate(-1);
        } else if (error) {
            console.log(error);
        }
    };

    const handleCreditCardPayment = async (orderGuid) => {
        // console.log(orderGuid);
        // 從環境變數中獲取值
        const {
            NODE_ENV,
            REACT_APP_MER_ID,
            REACT_APP_MERCHANT_ID,
            REACT_APP_TERMINAL_ID,
            REACT_APP_MERCHANT_NAME,
            REACT_APP_CUSTOMIZE,
            REACT_APP_AUTO_CAP,
            REACT_APP_PAY_TYPE,
            REACT_APP_PERIOD_NUM,
            REACT_APP_SUB_MERCH_ID,
            REACT_APP_EN_CODE_TYPE,
            REACT_APP_TIMEOUT_SECS,
            REACT_APP_THREE_DS_AUTH_IND,
            REACT_APP_REQ_TOKEN,
            REACT_APP_LAG_SELECT,
            REACT_APP_AUTH_RES_URL_PROD,
            REACT_APP_AUTH_RES_URL_DEV,
            REACT_APP_FRONT_FAIL_URL_PROD,
            REACT_APP_FRONT_FAIL_URL_DEV,
        } = process.env;

        const { success, response, error } = await operation(
            {
                url: `/payment/getCreditCardInfo/${orderGuid}`,
            },
            'handleCreditCardPayment'
        );
        if (success) {
            // console.log(response);
            // console.log(response.data.result);
            const orderInfo = response.data.result;

            // 填充表单数据
            const keysToFrom = {
                MerchantID: REACT_APP_MERCHANT_ID,
                MerchantName: REACT_APP_MERCHANT_NAME,
                customize: REACT_APP_CUSTOMIZE,
                AutoCap: REACT_APP_AUTO_CAP,
                TerminalID: REACT_APP_TERMINAL_ID,
                frontFailUrl: NODE_ENV === 'development' ? REACT_APP_FRONT_FAIL_URL_DEV : REACT_APP_FRONT_FAIL_URL_PROD,
                AuthResURL: NODE_ENV === 'development' ? REACT_APP_AUTH_RES_URL_DEV : REACT_APP_AUTH_RES_URL_PROD,
                merID: REACT_APP_MER_ID,
                PayType: REACT_APP_PAY_TYPE,
                PeriodNum: REACT_APP_PERIOD_NUM,
                subMerchID: REACT_APP_SUB_MERCH_ID,
                enCodeType: REACT_APP_EN_CODE_TYPE,
                timeoutSecs: REACT_APP_TIMEOUT_SECS,
                lagSelect: lang === 'zh-TW' ? 0 : lang === 'zh-CN' ? 1 : lang === 'en' ? 2 : 0,
                reqToken: REACT_APP_REQ_TOKEN,
                threeDSAuthInd: REACT_APP_THREE_DS_AUTH_IND,

                lidm: orderInfo.lidm,
                purchAmt: orderInfo.purchAmt,
                LocalDate: orderInfo.localDate,
                LocalTime: orderInfo.localTime,
                timeoutDate: orderInfo.timeoutDate,
                timeoutTime: orderInfo.timeoutTime,
                mobileNbr: orderInfo.mobileNbr,
                walletVerifyCode: orderInfo.walletVerifyCode,
            };

            // 构建查询参数
            const queryParams = new URLSearchParams(keysToFrom).toString();

            // 重定向到目标页面
            window.location.href = `https://www.focas.fisc.com.tw/FOCAS_WEBPOS/online/?${queryParams}`;
        } else if (error) {
            console.log(error);
        }
    };

    //#endregion

    return (
        <>
            <OrderContainer>
                <Wrapper>
                    <section>
                        <PageTitle className='d-flex align-items-center justify-content-start'>
                            <BackToPrevButton onClick={() => navigate(-1)}>
                                <Icn_chevronLeft_black className='mt-3 mt-md-0' />
                            </BackToPrevButton>
                            <div className='mt-3 mt-md-0'>{t('back_to_previous_page')}</div>
                        </PageTitle>
                        <DetailsWrapper className='px-xl-6 px-lg-6 px-md-4 px-2 pt-4 pb-4'>
                            {order && (
                                <>
                                    <OrderPaymentDetails order={order} t={t} />
                                    <OrderContentDetails order={order} t={t} />
                                    <OrderGoodsDetails order={order} t={t} />
                                    <ButtonWrapper singleButton={order.status === '5' || order.status === '6'}>
                                        {/* 待付款, 待理貨, 待出貨 */}
                                        {/* {(order.status === '0' || order.status === '1' || order.status === '2') && (
                                            <UnderlinedTextButton
                                                status={order.status}
                                                // onClick={() => setCancelModalOpen(true)}
                                            >
                                                {t('cancel_order')}
                                            </UnderlinedTextButton>
                                        )} */}

                                        {/* 訂單完成 */}
                                        {/* {order.status === '4' && (
                                            <UnderlinedTextButton status={order.status}>
                                                {t('return_exchange_request')}
                                            </UnderlinedTextButton>
                                        )} */}

                                        {/* 待付款, status === "0" */}
                                        {order.status === '0' && (
                                            <OrderButton
                                                className='btn btn-primary text-white ms-4 ms-xl-4 ms-lg-0 ms-md-0 py-2'
                                                onClick={() =>
                                                    // navigate(
                                                    //   `${
                                                    //     order?.paymentMethod === "0"
                                                    //       ? `/PdCreditCard/${order.orderGuid}`
                                                    //       : `/PdUnpaid/${order.orderGuid}`
                                                    //   }`
                                                    // )
                                                    handleCreditCardPayment(order.orderGuid)
                                                }
                                            >
                                                {t('go_to_payment')}
                                            </OrderButton>
                                        )}

                                        {/* 訂單完成 or 訂單不成立 */}
                                        {(order.status === '4' || order.status === '9') && (
                                            <OrderButton
                                                className='btn btn-primary text-white ms-4 ms-xl-4 ms-lg-0 ms-md-0 py-2'
                                                onClick={() => handleRebuyOrder(order?.orderGuid)}
                                            >
                                                {t('buy_again')}
                                            </OrderButton>
                                        )}

                                        {/* 待付款, status === "0" */}
                                        {(order.status === '3' || order.status === '5' || order.status === '6') && (
                                            <OrderRoundedButton
                                                className='btn btn-outline-primary ms-xl-4 ms-lg-0 ms-md-0 py-2'
                                                onClick={() => navigate(-1)}
                                            >
                                                {t('back_to_previous_page')}
                                            </OrderRoundedButton>
                                        )}
                                    </ButtonWrapper>
                                </>
                            )}
                        </DetailsWrapper>
                    </section>
                </Wrapper>
            </OrderContainer>
            {cancelModalOpen && (
                <CancelOrderModal
                    isOpen={cancelModalOpen}
                    orderNo={order.orderNo}
                    orderGuid={order?.orderGuid}
                    handleClose={() => setCancelModalOpen(false)}
                    handleCancelOrder={handleCancelOrder}
                    t={t}
                />
            )}
        </>
    );
}
