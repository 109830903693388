import './OTAMainUpdateDetail.scss';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import * as dayjs from 'dayjs';
import Swal from 'sweetalert2';
import useAxios from '../../../hooks/useAxios';

export default function OTAMainUpdateDetail() {
    const paramsCode = useParams('otaGuid');
    const navigate = useNavigate();
    const [rowStamp, setRowStamp] = useState('');
    const { responses, loadings, errors, operation, isAnyLoading, hasAnyError } = useAxios();

    const [data, setData] = useState({
        otaGuid: '',
        otaCode: '',
        name: '',
        note: '',
        updateTime: '',
        appGuid: '',
    });
    const [appList, setAppList] = useState([]);

    //#region function
    const checkotaCode = async (otaCode) => {
        const { success, response, error } = await operation(
            {
                url: `/sys/checkotacodevalid?otacode=${otaCode}`,
            },
            'checkotaCode'
        );

        if (success) {
            if (response.data === false) {
                Swal.fire({
                    icon: 'error',
                    title: `OTA代码重複，請重新輸入`,
                });
                setData({ ...data, otaCode: '' });
            }
        }

        if (error) {
            console.log(error);
        }
    };

    const handleDeleteItem = async () => {
        const { success, response, error } = await operation(
            {
                url: `/sys/ota/${paramsCode.otaGuid}`,
                method: 'DELETE',
            },
            'handleDeleteItem'
        );

        if (success) {
            navigate('/otaMainUpdate');
        }

        if (error) {
            console.log(error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const form = new FormData(e.target);

        if (Object.keys(paramsCode).length) {
            // 修改
            const { success, response, error } = await operation(
                {
                    url: `/sys/ota/${paramsCode.otaGuid}`,
                    method: 'PUT',
                    data: form,
                },
                'handleSubmit'
            );

            if (success) {
                navigate('/otaMainUpdate');
            }

            if (error) {
                console.log(error);
            }
        } else {
            // 新增
            const { success, response, error } = await operation(
                {
                    url: `/sys/ota`,
                    method: 'POST',
                    data: form,
                },
                'handleSubmit'
            );

            if (success) {
                navigate('/otaMainUpdate');
            }
        }
    };

    const getOtaDetails = async () => {
        const { success, response, error } = await operation(
            {
                url: `/sys/ota/${paramsCode.otaGuid}`,
            },
            'getOtaDetails'
        );

        if (success) {
            const data = response.data.result;
            const { appGuid, otaCode, otaGuid, name, note, updateTime, rowStamp } = data;

            setRowStamp(rowStamp);
            setData({
                ...data,
                appGuid,
                otaCode,
                otaGuid,
                name,
                note,
                updateTime: updateTime && dayjs(updateTime).format('YYYY-MM-DD HH:mm:ss'),
            });
        }

        if (error) {
            console.log(error);
        }
    };

    const fetchAppList = async () => {
        const { success, response, error } = await operation(
            {
                url: '/sys/app',
            },
            'getAppMainList'
        );

        if (success) {
            const data = response.data.result;
            setAppList(data);
        }

        if (error) {
            console.log(error);
        }
    };
    //#endregion

    //#region useEffect
    useEffect(() => {
        if (Object.keys(paramsCode).length) {
            getOtaDetails();
        }
        fetchAppList();
    }, []);
    //#endregion

    return (
        <>
            <section id='section-main'>
                <form id='container-otaMainUpdateDetail' onSubmit={handleSubmit}>
                    <div className='d-flex'>
                        <h5 className='fw-bold mb-4'>OTA基本设定</h5>
                        <button
                            className='btnDelete btn btn-outline-danger px-4 ms-auto me-2'
                            onClick={(e) => {
                                e.preventDefault();
                                handleDeleteItem();
                            }}
                        >
                            刪除
                        </button>
                        <button
                            className='btnCancel btn btn-outline-primary px-4 me-2'
                            onClick={(e) => {
                                e.preventDefault();
                                navigate('/otaMainUpdate');
                            }}
                        >
                            取消
                        </button>
                        <button className='btn btn-primary text-white px-4'>储存</button>
                    </div>
                    {/* <div className="d-flex"></div> */}
                    <div className='manageFileWrotaer bg-white p-4 rounded-4 mb-3'>
                        <input id='rowStamp' name='rowStamp' className='d-none' defaultValue={rowStamp} />
                        <div className='d-flex'>
                            <div className='col-6'>
                                <h6 className='fw-bold mb-3'>资料管理</h6>
                                <h6 className='fontSize75Rem mb-2'>OTA代码（创建后不能修改）</h6>
                                <input
                                    id='otaCode'
                                    name='otaCode'
                                    className='form-control mb-3 w-100'
                                    required
                                    value={data.otaCode || ''}
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            otaCode: e.target.value.toUpperCase(),
                                        })
                                    }
                                    maxLength='2'
                                    disabled={Object.keys(paramsCode).length !== 0}
                                    onBlur={(e) => checkotaCode(e.target.value)}
                                />
                                <h6 className='fontSize75Rem mb-2'>OTA名称</h6>
                                <input
                                    id='name'
                                    name='name'
                                    className='form-control mb-3 w-100'
                                    required
                                    value={data.name || ''}
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            name: e.target.value,
                                        })
                                    }
                                />
                                <h6 className='fontSize75Rem mb-2'>注记</h6>
                                <input
                                    id='note'
                                    name='note'
                                    className='form-control mb-3 w-100'
                                    required
                                    value={data.note || ''}
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            note: e.target.value,
                                        })
                                    }
                                />
                                <h6 className='fontSize75Rem mb-2'>搭配的APP</h6>
                                <select
                                    className='form-control mb-3'
                                    name='appGuid'
                                    id='appGuid'
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            appGuid: e.target.value,
                                        })
                                    }
                                    value={data.appGuid || ''}
                                    required
                                >
                                    {appList?.map((el) => (
                                        <option id={el.appGuid} key={el.appGuid} value={el.appGuid}>
                                            {el.name}
                                        </option>
                                    ))}
                                </select>
                                {/* <input
                                    id="note"
                                    name="note"
                                    className="form-control mb-3 w-100"
                                    required
                                    value={data.note || ''}
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            note: e.target.value,
                                        })
                                    }
                                /> */}
                                {Object.keys(paramsCode).length !== 0 && (
                                    <React.Fragment>
                                        <h6 className='fontSize75Rem mb-2'>创建/修改时间</h6>
                                        <input
                                            type='datetime-local'
                                            id='updateTime '
                                            name='updateTime'
                                            className='form-control mb-3 w-100'
                                            value={data.updateTime || ''}
                                            onChange={(e) =>
                                                setData({
                                                    ...data,
                                                    updateTime: e.target.value,
                                                })
                                            }
                                            disabled
                                        />
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                    </div>
                </form>
            </section>
        </>
    );
}
