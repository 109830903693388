import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

const Textarea = styled.textarea`
    width: 100%;
    height: 300px;
    resize: none;
    white-space: pre-wrap || pre-line;
`;

// 商品描述編輯的 Modal 組件 SalesKitDescriptionModal

// 接受的 props:
// - `note`: 商品描述的原始內容
// - `data`: 整個頁面的狀態數據
// - `setData`: 用於更新頁面狀態的函數
// - `modalOpen`: 控制 Modal 是否顯示的狀態
// - `setModalOpen`: 用於更新 Modal 顯示狀態的函數

// 在組件內部，使用 `useState` 定義一個狀態 `copyNote`，初始值為 `note` 的內容經過轉換換行符號 `<br />` 的轉換。
// 定義 `replaceWithBr` 函數，用於將 `<br />` 換行符號轉換為實際的換行符號 `\n`。
// 定義 `handleUpdateNote` 函數，將 `copyNote` 中的換行符號 `\n` 轉換為 `<br />`，然後更新 `data` 中 `salesKit` 的 `note` 屬性，最後關閉 Modal。
// Modal 包含一個標題、一個文本區域（Textarea）用於編輯商品描述，以及確定和取消兩個按鈕。
// 使用 `Textarea` 組件來輸入商品描述，並使用 `onChange` 事件監聽器來實時更新 `copyNote` 的狀態。

// 注意：具體的樣式和 Modal 結構實現未提供，需要參考具體的項目需求進行實現。

export default function SalesKitDescriptionModal({ note, data, setData, modalOpen, setModalOpen }) {
    const [copyNote, setCopyNote] = useState(replaceWithBr(note));
    function replaceWithBr(string) {
        return string.replace(/<br \/>/g, '\n');
    }

    const handleUpdateNote = () => {
        const newValue = copyNote.replace(/\n/g, '<br />');

        setData({ ...data, salesKit: { ...data.salesKit, note: newValue } });
        setModalOpen(false);
    };

    return (
        <Modal className='editDrillItemModal modal' show={modalOpen} onHide={() => setModalOpen(false)} centered>
            <Modal.Header closeButton className='border-bottom'>
                <Modal.Title>修改商品描述</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Textarea
                    name='description'
                    value={copyNote}
                    onChange={(e) => {
                        setCopyNote(e.target.value);

                        const newlineCount = (e.target.value.match(/\n/g) || []).length;
                        console.log('换行符数量：', newlineCount);
                    }}
                />
            </Modal.Body>
            <Modal.Footer className='justify-content-center mx-4'>
                <button
                    type='button'
                    className='btn btn-primary text-white px-4 me-2'
                    // onClick={() => {
                    //     checkVerticalValue(editDrillItemData.vertical) &&
                    //         checkHorizontalValue(
                    //             editDrillItemData.horizontal
                    //         ) &&
                    //         checkHighSpeedValue(editDrillItemData.highSpeed) &&
                    //         handleEditDrillItemSubmit();
                    // }}
                    onClick={handleUpdateNote}
                >
                    確定
                </button>
                <button
                    type='button'
                    className='btnCancel btn btn-outline-primary px-4'
                    onClick={() => setModalOpen(false)}
                >
                    取消
                </button>
            </Modal.Footer>
        </Modal>
    );
}
