import './physicalStoreUpdate.scss';
import RenderIndexList from '../../../RenderIndexList';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import useAxios from '../../../hooks/useAxios';

const PhysicalStoreUpdate = () => {
    const { operation } = useAxios();
    //#region [States initialization]

    const [isGetPhysicalStoreList, setIsGetPhysicalStoreList] = useState(false);
    const [physicalStoreList, setPhysicalStoreList] = useState('');
    const [filterList, setFilterList] = useState('');
    const [filterItemsObjectList, setFilterItemsObjectList] = useState([]);
    const searchTextRef = useRef('');

    //#endregion

    //#region [code to name]

    // TODO : put all temps into utils.js ?
    const pageTitle = '銷售網點';
    const deleteApiURL = '/backstage/physicalstore';
    const createURL = '/physicalstoreupdate/create';
    const titleList = ['顯示順序', '型態', '國家', '省市', '銷售網點名稱', '狀態', ''];

    const categoryCodeToName = {
        0: '直營',
        1: '合約俱樂部',
    };

    const countryCodeToName = {
        CN: '中國',
        TW: '台灣',
        HK: '香港',
    };

    const languageCodeToName = {
        ZH: '簡体中文',
        ZF: '繁体中文',
        EN: '英文',
    };

    const statusCodeToName = {
        0: '停用',
        1: '啟用',
    };

    //#endregion

    //#region [function]

    const getPhysicalStoreList = useCallback(() => {
        setIsGetPhysicalStoreList(false);

        const gettingPhysicalStoreList = async () => {
            const { success, response, error } = await operation(
                {
                    url: '/backstage/physicalstore',
                },
                'gettingPhysicalStoreList'
            );
            if (success) {
                // console.log(res.data.result);
                const data = response.data.result;
                let list = [];

                // TODO : app display function with undefined
                data.forEach((el) => {
                    el.guid = el.storeGuid;
                    el.detailUrl = `/physicalstoreupdate/edit/${el.guid}`;

                    el.dataList = [
                        el.displayOrder,
                        `${categoryCodeToName[el.category]}`,
                        `${countryCodeToName[el.countryCode]}`,
                        el.province,
                        el.name,
                        `${statusCodeToName[el.status]}`,
                    ];

                    const filterItemsObject = new Object();
                    filterItemsObject.guid = el.storeGuid;
                    filterItemsObject.displayOrder = el.displayOrder.toString();
                    filterItemsObject.categoryName = `${categoryCodeToName[el.category]}`;
                    filterItemsObject.countryName = `${countryCodeToName[el.countryCode]}`;
                    filterItemsObject.province = el.province;
                    filterItemsObject.name = el.name;
                    filterItemsObject.status = `${statusCodeToName[el.status]}`;

                    list = [filterItemsObject, ...list];
                });
                // console.log(fetchedVideoList);

                setFilterItemsObjectList(list);
                setPhysicalStoreList(data);
                setFilterList(data);
                setIsGetPhysicalStoreList(true);
            } else if (error) {
                console.log(error);
            }
        };

        gettingPhysicalStoreList();
    }, []);

    //#endregion

    //#region [useEffect]

    useEffect(() => {
        getPhysicalStoreList();
    }, [getPhysicalStoreList]);

    //#endregion

    return (
        <section id='section-main'>
            <div id='container-physicalStoreUpdate'>
                <RenderIndexList
                    titleList={titleList}
                    contentList={physicalStoreList}
                    setList={setPhysicalStoreList}
                    searchTextRef={searchTextRef}
                    isGetList={isGetPhysicalStoreList}
                    setIsGetList={setIsGetPhysicalStoreList}
                    getList={getPhysicalStoreList}
                    filterList={filterList}
                    setFilterList={setFilterList}
                    pageTitle={pageTitle}
                    filterItemsObjectList={filterItemsObjectList}
                    deleteApiURL={deleteApiURL}
                    createURL={createURL}
                />
            </div>
        </section>
    );
};

export default PhysicalStoreUpdate;
