import { useEffect } from 'react';
import SearchInput from './SearchInput';
import OrderPreviewCard from '../OrderPreviewCard';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import Pagination from '../../../components/Pagination/Pagination';

// #region styled-components

const Wrapper = styled.div`
    @media only screen and (max-width: 578px) {
        background-color: #f8f8f8;
    }
`;

const NoOrderWrapper = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 150px 0;
    border: 1px solid #dee2e6;

    @media only screen and (max-width: 1020px) {
        border: none;
    }
`;

const NoOrderText = styled.div`
    font-size: 18px;
`;

const ReturnToShop = styled.a`
    font-size: 14px;
    margin-top: 16px;
    text-decoration: underline;
    cursor: pointer;
`;

// #endregion

export default function TabContent({
    list,
    orderStatus,
    handleSearch,
    deviceType,
    paginationData,
    advancedSearchDataList,
    getList,
    sortBy,
    t,
    setPaginationData,
}) {
    console.log(list);
    const navigate = useNavigate();
    // console.log(list.length);
    // Use object destructuring, so you don't need to remember the exact order
    const { ref, inView, entry } = useInView({
        /* Optional options */
        threshold: 1,
    });

    useEffect(() => {
        const { numberOfData, pageNumber, totalPage } = paginationData;
        const isLastPage = !(totalPage - pageNumber);

        // console.log(inView, deviceType, paginationData, isLastPage);

        if (inView && !isLastPage)
            // sort,
            // pageSize,
            // page: pageNumber,
            // advancedSearchDataList,

            getList(advancedSearchDataList.length ? advancedSearchDataList : null, 2, sortBy, pageNumber + 1);
    }, [inView]);
    // useEffect(() => {
    //     setPaginationData({
    //         ...paginationData,
    //         pageNumber: 1,
    //     });
    // }, [orderStatus]);
    if (!list.length)
        return (
            <Wrapper className='px-xl-6 px-lg-6 px-md-4 px-3 pb-4 pt-2 pt-xl-4 pt-lg-4 pt-md-4 pt-sm-4'>
                <div className='mt-4'>
                    <NoOrderWrapper className='border-sm-0'>
                        <NoOrderText>{t('no_order_history')}</NoOrderText>
                        <ReturnToShop onClick={() => navigate('/onlineStore')}>{t('go_shopping')}</ReturnToShop>
                    </NoOrderWrapper>
                </div>
            </Wrapper>
        );

    return (
        <Wrapper className='px-xl-6 px-lg-6 px-md-4 px-3 pb-4 pt-2 pt-xl-4 pt-lg-4 pt-md-4 pt-sm-4'>
            <SearchInput handleSearch={handleSearch} t={t} />
            {list?.map((order, index) => (
                <OrderPreviewCard key={order?.orderGuid} order={order} orderStatus={orderStatus} t={t} />
            ))}
            {deviceType !== 'pc' && <br className='' ref={ref}></br>}
            {/* {deviceType === 'pc' && (
                <Pagination
                    t={t}
                    paginationData={paginationData}
                    setPaginationData={setPaginationData}
                    fetchData={getList}
                    sortBy={sortBy}
                    listLength={list.length}
                    advancedSearchDataList={advancedSearchDataList}
                />
            )} */}
        </Wrapper>
    );
}
