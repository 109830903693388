import React, { useCallback, useEffect, useState } from 'react';
import useInitialLocation from './useInitialLocation';
import { useTranslation } from 'react-i18next';
import Router from './router/router';
import useRefreshToken from './hooks/useRefreshToken';
import useAuth from './hooks/useAuth';

// 建立登入共用資料context
export const AccountContext = React.createContext();

function App() {
    const { auth } = useAuth();
    const refresh = useRefreshToken();
    const [loading, setLoading] = useState(true);

    const [homePageIndex, setHomePageIndex] = useState(0);
    const initialLocation = useInitialLocation();

    // if (process.env.NODE_ENV === 'production') console.log = function () {};

    // useEffect(() => {
    //     console.log('app effect');
    //     console.log('process.env.NODE_ENV: ', process.env.NODE_ENV);
    //     // Check if the current environment is production
    //     if (process.env.NODE_ENV === 'production') {
    //         // Get the current hostname
    //         const currentHostname = window.location.hostname;
    //         console.log(currentHostname);

    //         // Check if the current hostname is 'pingtex.tw'
    //         if (currentHostname === 'pingtex.tw') {
    //             // Redirect to 'https://www.pingtex.tw'
    //             window.location.href = 'https://www.pingtex.tw' + window.location.pathname + window.location.search;
    //         }

    //         // Check if the current hostname is 'synerter.com'
    //         if (currentHostname === 'synerter.com') {
    //             // Redirect to 'https://www.synerter.com'
    //             window.location.href = 'https://www.synerter.com' + window.location.pathname + window.location.search;
    //         }
    //     }

    //     // Get previous URL
    //     const prevUrl = localStorage.getItem('prevUrl');

    //     // Set new previous URL
    //     localStorage.setItem('prevUrl', window.location.href);

    //     // Scroll to initial location based on the path
    //     const initialLocation = 0; // Change this value as needed
    //     if (window.location.pathname === '/') {
    //         if (prevUrl !== window.location.href) {
    //             window.scrollTo(0, initialLocation);
    //         }
    //     } else {
    //         window.scrollTo(0, initialLocation);
    //     }

    //     // Check if the prevUrl does not contain 'www' and redirect if necessary
    //     if (prevUrl && prevUrl.includes('synerter.com')) {
    //         window.location.href = 'https://www.synerter.com' + window.location.pathname + window.location.search;
    //     }

    //     if (prevUrl && prevUrl.includes('pingtex.tw')) {
    //         window.location.href = 'https://www.pingtex.tw' + window.location.pathname + window.location.search;
    //     }
    // }, []);

    // 多國語言
    const [lang, setLang] = useState('zh-TW');
    const { i18n } = useTranslation();

    // 在事件方法中使用i18n.changeLanguage()方法
    const toggleI18n = () => {
        // localStorageLocale = 'en'; // 暫時強制為正體中文
        let locale = 'zh';

        if (lang === 'zh-CN') {
            locale = 'zh-CN';
        } else if (lang === 'zh-TW') {
            locale = 'zh';
        } else {
            locale = 'en';
        }

        i18n.changeLanguage(locale);
    };

    useEffect(() => {
        toggleI18n();
    }, [lang]);

    useEffect(() => {
        const localStorageLocale =
            localStorage.getItem('i18n') || navigator.language || navigator.browserLanguage || 'zh';

        setLang(localStorageLocale);

        refresh();
        setLoading(false);
    }, []);

    return (
        <AccountContext.Provider
            value={{
                homePageIndex,
                setHomePageIndex,
                lang,
                setLang,
            }}
        >
            {!loading && <Router auth={auth} />}
        </AccountContext.Provider>
    );
}

export default App;
