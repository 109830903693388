import './supportTop.scss';
import React, { useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ScrollToTopOnRouteChange from '../../components/scrollToTopOnRouteChange/ScrollToTopOnRouteChange';
import { useTranslation } from 'react-i18next';
import useAuth from '../../hooks/useAuth';

function SupportTop({ getNewFaq }) {
    const { t } = useTranslation(['supportQuestion']); // i18n
    const { auth, logout } = useAuth();

    const navigate = useNavigate();
    const searchInput = useRef();
    const location = useLocation();

    // 跨頁維持input搜尋文字
    useEffect(() => {
        if (searchInput.current) {
            // 假使一開始localStorage無searchText
            if (localStorage.getItem('searchText') === null) {
                localStorage.setItem('searchText', '');
            } else {
                searchInput.current.value = localStorage.getItem('searchText');
            }
        }
    }, []);

    return (
        <section className='supportTop-section w-100 row'>
            <ScrollToTopOnRouteChange height={location.pathname === '/support/question' ? 200 : 580} />

            <div className='col position-relative p-0 text-center text-lg-start'>
                <h1 className='text-center'>{t('support_services')}</h1>
                <p className='text-center mb-lg-3'>{t('help_needed')}</p>

                <div className='w-100 mb-11'>
                    {window.location.pathname.includes('/support/question') ? (
                        // 常見問題頁面 以外 服務支持頁面
                        <div className='searchWrap position-relative mx-auto'>
                            <input
                                type='text'
                                className='rounded-pill w-100 bg-white border-0'
                                placeholder={t('enter_information')}
                                ref={searchInput}
                                onChange={(e) => {
                                    localStorage.setItem('searchText', e.target.value);

                                    // delete清空搜尋內容時
                                    if (e.target.value === '') {
                                        getNewFaq();
                                    }

                                    // 清除被標記文字的bg color
                                    document.querySelectorAll('span.textActive').forEach((item) => {
                                        item.classList.remove('textActive');
                                    });
                                }}
                                onKeyPress={(e) => e.key === 'Enter' && getNewFaq()}
                            />
                            <img
                                className='position-absolute'
                                src='./images/support/icnSearch.png'
                                alt='icnSearch'
                                onClick={() => {
                                    getNewFaq();
                                }}
                            />
                        </div>
                    ) : (
                        // 常見問題頁面
                        <div className='searchWrap position-relative mx-auto'>
                            <input
                                type='text'
                                className='rounded-pill w-100 bg-white border-0'
                                placeholder={t('enter_information')}
                                ref={searchInput}
                                onChange={(e) => {
                                    localStorage.setItem('searchText', e.target.value);
                                }}
                                onKeyPress={(e) => e.key === 'Enter' && navigate('/support/question')}
                            />
                            <img
                                className='position-absolute'
                                src='./images/support/icnSearch.png'
                                alt='icnSearch'
                                onClick={() => {
                                    navigate('/support/question');
                                }}
                            />
                        </div>
                    )}
                </div>
                <div className='position-absolute pageBtnWrapper bg-white w-100'>
                    <div className='textWrap d-flex flex-wrap justify-content-around justify-content-lg-between mx-auto px-lg-12 py-lg-7'>
                        <Link
                            to={{
                                pathname: '/support/question',
                                state: {
                                    initialLocation: '.section2',
                                },
                            }}
                        >
                            <h2 className='mb-2 text-primary text-center'>
                                <img
                                    className='icnQuestion h-100 mx-0'
                                    src='./images/support/icnQuestion.png'
                                    alt='icnQuestion'
                                />
                            </h2>
                            <h2 className='mb-0'>{t('frequently_asked_questions')}</h2>
                        </Link>
                        <div className='border-end border-grey1'></div>
                        <Link
                            to={{
                                pathname: '/support/downloadcenter',
                                state: {
                                    initialLocation: '.section2',
                                },
                            }}
                        >
                            <h2 className='mb-2 text-primary text-center'>
                                <img
                                    className='icnDownloadCenter h-100 mx-0'
                                    src='./images/support/icnDownloadCenter.png'
                                    alt='icnDownloadCenter'
                                />
                            </h2>
                            <h2 className='mb-0'>{t('download_center')}</h2>
                        </Link>
                        <div className='border-end border-grey1 wrapDiv'></div>
                        <div
                            className='productRegisterBtn'
                            onClick={() => {
                                if (auth?.accessToken) {
                                    navigate('/product');
                                } else {
                                    logout('search=product');
                                }
                            }}
                        >
                            <h2 className='mb-2 text-primary text-center'>
                                <img
                                    className='icnProductRegister h-100 mx-0'
                                    src='./images/support/icnProductRegister.png'
                                    alt='icnProductRegister'
                                />
                            </h2>
                            <h2 className='mb-0'>{t('product_registration')}</h2>
                        </div>
                        <div className='border-end border-grey1'></div>
                        <Link
                            to={{
                                pathname: '/support/contactus',
                                state: {
                                    initialLocation: '.section2',
                                },
                            }}
                        >
                            <h2 className='mb-2 text-primary text-center'>
                                <img
                                    className='icnContactUs h-100 mx-0'
                                    src='./images/support/icnContactUs.png'
                                    alt='icnContactUs'
                                />
                            </h2>
                            <h2 className='mb-0'>{t('contact_us')}</h2>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SupportTop;
