import './factoryRecord.scss';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import useAxios from '../../../../hooks/useAxios';
import { Link, useNavigate } from 'react-router-dom';
import { sortTable } from '../../../../components/utils/utils';
import Pagination from '../../../../components/Pagination/Pagination';
import { ReactComponent as Icn_Create } from '../../../../assets/images/icn_Create.svg';
import { ReactComponent as Icn_Reload } from '../../../../assets/images/icn_Reload.svg';
import { ReactComponent as Icn_Gear } from '../../../../assets/images/icn_Gear.svg';

const FactoryRecord = () => {
    const { operation } = useAxios();
    const [isGetList, setIsGetList] = useState(false);
    const [contentList, setList] = useState('');
    const [filterList, setFilterList] = useState('');
    const [filterItemsObjectList, setFilterItemsObjectList] = useState([]);
    const searchTextRef = useRef('');

    // pagination
    const [paginationData, setPaginationData] = useState({});
    const sortBy = ['factoryDate desc'];
    const [advancedSearchDataList, setAdvancedSearchDataList] = useState([]);

    //#region [code to name]

    const pageTitle = '发球机出厂记录';
    const createURL = '/factoryRecord/create';

    const titleList = ['#', '机码', '主板型号/序号', '制造日期', '品管人员', '出厂日期 '];

    //#endregion

    //#region [function]

    const getList = useCallback((advancedSearchDataList = null, pageSize = 20, sort = sortBy, pageNumber = 1) => {
        setIsGetList(false);

        const gettingList = async () => {
            const { success, response, error } = await operation(
                {
                    url: '/product/factoryRecordsList',
                    method: 'POST',
                    data: {
                        sort,
                        pageSize,
                        page: pageNumber,
                        // advancedSearchDataList,
                    },
                },
                'gettingList'
            );
            if (success) {
                // console.log(response.data);

                // pagination
                const { numberOfData, totalPage } = response.data;
                setPaginationData({
                    ...paginationData,
                    numberOfData: numberOfData,
                    totalPage: totalPage,
                    pageNumber: pageNumber,
                });

                const data = response.data.result;
                let list = [];

                // TODO : app display function with undefined
                data.forEach((el) => {
                    el.guid = el.productGuid;
                    el.detailUrl = `/factoryRecord/edit/${el.guid}`;

                    el.dataList = [
                        el.displayOrder,
                        `${el.modelID}-${el.serialNo}`,
                        `${el.mbModelID}-${el.mbSerialNo}`,
                        el.qcTime.split('T')[0],
                        el.qcStaffName,
                        el.factoryDate?.split('T')[0],
                    ];

                    const filterItemsObject = new Object();
                    filterItemsObject.guid = el.productGuid;
                    filterItemsObject.displayOrder = el.displayOrder.toString();
                    filterItemsObject.modelName = `${el.modelID}-${el.serialNo}`.replace(/\s+/g, '');
                    filterItemsObject.mbModelName = `${el.mbModelID}-${el.mbSerialNo}`.replace(/\s+/g, '');
                    filterItemsObject.qcTime = el.qcTime;
                    filterItemsObject.qcStaffName = el.qcStaffName;
                    filterItemsObject.factoryDate = el.factoryDate;

                    list = [filterItemsObject, ...list];
                });

                setFilterItemsObjectList(list);
                setList(data);
                setFilterList(data);
                setIsGetList(true);
            } else if (error) {
                console.log(error);
            }
        };

        gettingList();
    }, []);

    //#endregion

    useEffect(() => {
        getList();
        sortTable(5);
    }, [getList]);

    // ****
    const navigate = useNavigate();

    // console.log('list', filterItemsObjectList);
    // console.log('data', contentList);

    useEffect(() => {
        getList();
    }, [getList]);

    //#region [function]

    //過濾搜尋
    const filter = async () => {
        let guidList = [];
        filterItemsObjectList.forEach((item) => {
            let isInclude = false;
            let appInfoArray = Object.values(item);
            console.log(appInfoArray, searchTextRef.current);

            //  Start from i=1 because the appInfoArray[0] is guid, which is not going to be scanned.
            for (let i = 1; i < appInfoArray.length; i++) {
                if (appInfoArray[i]?.toLowerCase().includes(searchTextRef.current.toLowerCase())) {
                    isInclude = true;
                }
            }

            if (isInclude) return guidList.push(appInfoArray[0]);
        });

        let result = [];
        contentList.forEach((el) => {
            guidList.forEach((guid) => {
                if (el.guid === guid) result.push(el);
            });
        });

        setFilterList(result);
    };

    //過濾搜尋
    const resetFilter = () => {
        document.querySelector('.searchInput').value = '';
        searchTextRef.current = '';
        getList();
    };

    return (
        <section id='section-main'>
            <div id='container-factoryRecord'>
                <>
                    <h5 className='fw-bold mb-4'>{pageTitle}</h5>
                    <div className='searchWrapper bg-white p-4 rounded-4 mb-3'>
                        <div className='searchBox d-flex'>
                            <input
                                className='searchInput form-control me-2 w-50'
                                placeholder='搜尋條件'
                                onChange={(e) => (searchTextRef.current = e.target.value)}
                                onKeyDown={(e) => e.key === 'Enter' && filter()}
                            />
                            <button className='btn btn-outline-primary btn-size-s me-2' onClick={resetFilter}>
                                重置
                            </button>
                            <button className='btn btn-primary btn-size-s text-white' onClick={filter}>
                                查詢
                            </button>
                        </div>
                    </div>

                    <div className='searchTableWrapper bg-white p-4 rounded-4'>
                        <div className='searchTableTop d-flex align-items-center mb-3 '>
                            <h6 className='fw-bold'>資料查詢</h6>
                            <Link className='ms-auto' to={createURL}>
                                <button className='btn btn-primary text-white'>
                                    <Icn_Create />
                                    <span>新增資料</span>
                                </button>
                            </Link>

                            <span className='reloadAppList ms-3 me-2' onClick={getList}>
                                <Icn_Reload />
                            </span>
                            <span>
                                <Icn_Gear />
                            </span>
                        </div>
                        <div className='renderIndexListSearchTableBottom'>
                            <section>
                                <table className='appListTable table table-hover text-center' id='table-drill'>
                                    <thead>
                                        <tr className='bg-background'>
                                            {titleList.map((el) => (
                                                <th
                                                    key={titleList.indexOf(el)}
                                                    scope='col'
                                                    className='text-grey4 border-0'
                                                    onClick={() => sortTable(titleList.indexOf(el))}
                                                >
                                                    {el}
                                                    <span></span>
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody className='border-top-0'>
                                        {isGetList &&
                                            (filterList.length !== 0 ? (
                                                filterList.map((el) => {
                                                    return (
                                                        <tr
                                                            key={el.guid}
                                                            onClick={(e) => {
                                                                navigate(el.detailUrl);
                                                            }}
                                                        >
                                                            {el.dataList.map((el) => (
                                                                <td key={Math.random()}>{el}</td>
                                                            ))}
                                                        </tr>
                                                    );
                                                })
                                            ) : (
                                                <tr>
                                                    <td colSpan='7'>無版本紀錄</td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>

                                {/*讀取畫面*/}
                                <div className={`text-center loadingBox ${isGetList && 'd-none'}`} id='loadingBox'>
                                    <h6 className='sr-only'>Loading...</h6>

                                    <div className='spinner-border text-primary' role='status'></div>
                                </div>
                            </section>

                            <Pagination
                                paginationData={paginationData}
                                setPaginationData={setPaginationData}
                                fetchData={getList}
                                sortBy={sortBy}
                                advancedSearchDataList={advancedSearchDataList}
                            />
                        </div>
                    </div>
                </>
            </div>
        </section>
    );
};

export default FactoryRecord;
