import './downloadCenterUpdate.scss';
import RenderIndexList from '../../../RenderIndexList';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import useAxios from '../../../hooks/useAxios';

const DownloadCenterUpdate = () => {
    const [isGetOnlineVideoList, setIsGetOnlineVideoList] = useState(false);
    const [onlineVideoList, setOnlineVideoList] = useState('');
    const [filterList, setFilterList] = useState('');
    const [filterItemsObjectList, setFilterItemsObjectList] = useState([]);
    const searchTextRef = useRef('');

    const { operation } = useAxios();
    //#region [code to name]

    // TODO : put all temps into utils.js ?
    const pageTitle = '產品說明書';
    const deleteApiURL = '/backstage/manual';
    const createURL = '/downloadcenterupdate/create';

    const titleList = ['順序', '型態', '說明書名稱', '發佈時間', '結束時間', '狀態', ''];

    const categoryCodeToName = {
        0: '產品',
        1: '教學',
        2: '活動',
    };

    const statusCodeToName = {
        0: '停用',
        1: '啟用',
    };

    //#endregion

    //#region [function]

    const getOnlineVideoList = useCallback(() => {
        setIsGetOnlineVideoList(false);

        const gettingOnlineVideoList = async () => {
            const { success, response, error } = await operation(
                {
                    url: '/backstage/manual',
                },
                'gettingOnlineVideoList'
            );
            if (success) {
                console.log(response.data.result);
                const data = response.data.result;
                let list = [];

                // TODO : app display function with undefined
                data.forEach((el) => {
                    el.guid = el.manualGuid;
                    el.detailUrl = `/downloadcenterupdate/edit/${el.guid}`;

                    el.dataList = [
                        el.displayOrder,
                        `${categoryCodeToName[el.category]}`,
                        el.title,
                        el.releaseTime.split('T')[0],
                        el.endTime.split('T')[0],
                        `${statusCodeToName[el.status]}`,
                    ];

                    const filterItemsObject = new Object();
                    filterItemsObject.guid = el.manualGuid;
                    filterItemsObject.displayOrder = el.displayOrder.toString();
                    filterItemsObject.categoryName = `${categoryCodeToName[el.category]}`;
                    filterItemsObject.title = el.title;
                    filterItemsObject.releaseTime = el.releaseTime;
                    filterItemsObject.endTime = el.endTime;
                    filterItemsObject.status = `${statusCodeToName[el.status]}`;

                    list = [filterItemsObject, ...list];
                });

                setFilterItemsObjectList(list);
                setOnlineVideoList(data);
                setFilterList(data);
                setIsGetOnlineVideoList(true);
            } else if (error) {
                console.log(error);
            }
        };

        gettingOnlineVideoList();
    }, []);

    //#endregion

    useEffect(() => {
        getOnlineVideoList();
    }, [getOnlineVideoList]);

    return (
        <section id='section-main'>
            <div id='container-downloadCenterUpdate'>
                <RenderIndexList
                    titleList={titleList}
                    contentList={onlineVideoList}
                    setList={setOnlineVideoList}
                    searchTextRef={searchTextRef}
                    isGetList={isGetOnlineVideoList}
                    setIsGetList={setIsGetOnlineVideoList}
                    getList={getOnlineVideoList}
                    filterList={filterList}
                    setFilterList={setFilterList}
                    pageTitle={pageTitle}
                    filterItemsObjectList={filterItemsObjectList}
                    deleteApiURL={deleteApiURL}
                    createURL={createURL}
                />
            </div>
        </section>
    );
};

export default DownloadCenterUpdate;
