// import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Appendix from './Appendix';
import useAxios from '../../../hooks/useAxios';

// 商品分類編輯和更新組件 SalesKitClassUpdate

// 組件狀態管理
// - `data`: 保存商品分類詳細信息的狀態。
// - `isCopyingErpAppendix`: 控制是否正在複製ERP的附件的狀態。
// - `navigate` 和 `location`: 使用 react-router-dom 提供的 `useNavigate` 和 `useLocation` 來進行路由導航和獲取當前路由信息。
// - `salesKitGuid`: 通過 `useParams` 從路由參數中獲取的商品分類唯一標識。

// 商品分類附件更新函數
// - `handleUpdateSalesKitAppendixList`: 用於更新商品分類附件的函數，發送請求更新後返回上一頁。

// 商品分類附件複製函數
// - `handleCopyERPSalesKitAppendix`: 用於複製ERP的商品分類附件的函數，通過發送請求後更新組件的狀態。

// 商品分類信息獲取
// - `useEffect`: 使用 useEffect 在組件初次渲染時獲取商品分類的詳細信息，包括商品分類附件列表。

// 組件渲染
// - 顯示商品分類名稱和操作按鈕，包括複製ERP照片按鈕、取消按鈕和儲存按鈕。
// - 顯示商品分類的詳細信息，包括商品分類名稱和相關附件信息。
// - 使用 `Appendix` 組件進行商品分類附件的管理和展示。

// 防止多次點擊
// - 在執行複製ERP的商品分類附件時，禁用相關按鈕，顯示正在處理的狀態。

// 按鈕和操作
// - 點擊取消按鈕可以返回上一頁。
// - 點擊儲存按鈕可以觸發 `handleUpdateSalesKitAppendixList` 函數，更新商品分類信息。

// 注意：組件中的 `Appendix` 組件的詳細信息未提供，需要參考 `Appendix` 組件的具體實現。

const SalesKitClassUpdate = () => {
    const [data, setData] = useState(null);
    const [isCopyingErpAppendix, setIsCopyingErpAppendix] = useState(false);
    const navigate = useNavigate();
    // const location = useLocation();
    const { salesKitGuid } = useParams('salesKitGuid');
    const { operation } = useAxios();

    // 編輯商品分類 SalesKitAppendixStoreList
    const handleUpdateSalesKitAppendixList = async () => {
        const salesKitAppendixStoreList = data?.appendixList;
        console.log(salesKitAppendixStoreList);

        const formData = new FormData();

        salesKitAppendixStoreList.map((el, idx) => {
            Object.keys(el).map((key) => {
                formData.append(`SalesKitAppendixStoreList[${idx}].${key}`, el[key]);
            });
        });

        formData.append('SalesKitGuid', salesKitGuid);

        for (const value of formData.values()) {
            console.log(value);
        }

        // axios
        //     .put(`web/SalSalesKitAppendix/updateSalesKitAppendix`, formData, {
        //         headers: {
        //             'Content-Type': 'multipart/form-data',
        //         },
        //     })
        //     // .then((res) => console.log(res))
        //     .then((res) => {
        //         console.log(res);
        //         navigate(-1);
        //     })
        //     .catch((err) => console.log(err));
        const { success, response, error } = await operation(
            {
                url: '/SalSalesKitAppendix/updateSalesKitAppendix',
                method: 'PUT',
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            },
            'handleUpdateSalesKitAppendixList'
        );
        if (success) {
            console.log(response);
            navigate(-1);
        } else if (error) {
            console.log(error);
        }
    };

    // edit getInfo
    useEffect(() => {
        const getInfo = async () => {
            const { success, response, error } = await operation(
                {
                    url: `/SalSalesKitAppendix/getSalesKitAppendixList?guid=${salesKitGuid}`,
                },
                'getInfo'
            );
            if (success) {
                const appendixList = response.data.data.map((el) => ({
                    ...el,
                    action: 'origin',
                }));
                // console.log(response);
                setData({ ...data, appendixList, salesKit: response.data.salesKit });
            } else if (error) {
                console.log(error);
            }
        };
        getInfo();
    }, []);

    const getSalesKitAppendixList = async () => {
        const { success, response, error } = await operation(
            {
                url: `/SalSalesKitAppendix/getSalesKitAppendixList?guid=${salesKitGuid}`,
            },
            'getSalesKitAppendixList'
        );
        if (success) {
            const appendixList = response.data.data.map((el) => ({
                ...el,
                action: 'origin',
            }));
            console.log(response);
            setData({
                ...data,
                appendixList,
                salesKit: response.data.salesKit,
            });
        } else if (error) {
            console.log(error);
        }
    };

    const handleCopyERPSalesKitAppendix = async () => {
        setIsCopyingErpAppendix(true);
        // axios
        //     .post(`/web/SalSalesKitAppendix/copyERPSalesKitAppendix`, {
        //         salesKitGuid,
        //     })
        //     .then((res) => {
        //         console.log(res);
        //         setIsCopyingErpAppendix(false);
        //         getSalesKitAppendixList();
        //     });
        const { success, response, error } = await operation({
            url: '/SalSalesKitAppendix/copyERPSalesKitAppendi',
            method: 'POST',
            data: {
                salesKitGuid,
            },
        });
        if (success) {
            console.log(response);
            setIsCopyingErpAppendix(false);
            getSalesKitAppendixList();
        } else if (error) {
            console.log(error);
        }
    };

    return (
        <>
            <section id='section-main'>
                <div id='container-aaaaa'>
                    <div className='d-flex'>
                        <h5 className='fw-bold mb-4'>商品圖片倉庫 - {data?.salesKit?.name_01}</h5>
                        <div
                            className='ms-auto me-2'
                            style={{
                                height: '32px',
                                borderLeft: '1px solid #DADADA',
                            }}
                        >
                            {isCopyingErpAppendix ? (
                                <button
                                    className='btn btn-primary text-white px-4 d-flex align-items-center'
                                    type='button'
                                    disabled
                                >
                                    <span class='spinner-grow spinner-grow-sm' role='status' aria-hidden='true'></span>
                                    <div className='ms-2'>修但幾勒...</div>
                                </button>
                            ) : (
                                <button
                                    type='button'
                                    className='btn btn-primary text-white px-4'
                                    style={{
                                        height: '32px',
                                        paddingBlock: 0,
                                        fontSize: '14px',
                                    }}
                                    onClick={handleCopyERPSalesKitAppendix}
                                >
                                    複製ERP的照片
                                </button>
                            )}
                        </div>
                        <div
                            className='me-2'
                            style={{
                                height: '32px',
                                borderLeft: '1px solid #DADADA',
                            }}
                        ></div>
                        <button
                            className='btnCancel btn btn-outline-primary px-4 me-2'
                            style={{
                                height: '32px',
                                paddingBlock: 0,
                                fontSize: '14px',
                            }}
                            onClick={(e) => {
                                e.preventDefault();
                                navigate(-1);
                            }}
                            disabled={isCopyingErpAppendix}
                        >
                            取消
                        </button>
                        <button
                            type='button'
                            className='btn btn-primary text-white px-4'
                            style={{
                                height: '32px',
                                paddingBlock: 0,
                                fontSize: '14px',
                            }}
                            onClick={handleUpdateSalesKitAppendixList}
                            disabled={isCopyingErpAppendix}
                        >
                            儲存
                        </button>
                    </div>

                    <div className='infoWrapper bg-white p-4 rounded-4 mb-3'>
                        <div className='infoTop d-flex align-items-center mb-3 '>
                            <h6 className='fw-bold'>{data?.salesKit?.name_01}</h6>
                        </div>
                        <div>
                            <Appendix data={data} setData={setData} />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default SalesKitClassUpdate;
